import React from "react";
import { number, string } from "prop-types";
import { api } from "@/api";
import { Loader } from "@/shared/Loader";
import { useGetApi } from "@/shared/useGetApi";
import { formatPhoneNumber } from "@/utils/numberFormatter";

export const OwnerInfo = ({ ownerId }) => {
  const [data, loading] = useGetApi({
    get: api.owners.get,
    id: ownerId,
  });

  if (loading) {
    return <Loader />;
  }

  const {
    address,
    city,
    email,
    name,
    phone,
    state,
    zip,
  } = data?.data[0]?.attributes;

  return (
    <div>
      <h2>Owners</h2>
      <h3>
        <a href={`/owners/${ownerId}`}>
          {name?.split(" ").reverse().join(", ")}
        </a>
      </h3>
      <p>{address}</p>
      <p>
        {city} {state} {zip}
      </p>
      <p>{formatPhoneNumber(phone)}</p>
      <p>{email}</p>
    </div>
  );
};

OwnerInfo.defaultProps = {
  address: "",
  email: "",
  name: "",
  phone: "",
};

OwnerInfo.propTypes = {
  address: string,
  email: string,
  name: string,
  phone: string,
  ownerId: number.isRequired,
};
