import { renderHook } from "@testing-library/react-hooks";
import { useElementStyles } from "./";

describe("useElementStyles", () => {
  const elementId = "the-element";
  const elementSelector = "#the-element";

  describe("without existing styles", () => {
    beforeEach(() => {
      document.body.innerHTML = `<div id="${elementId}" />`;
    });

    test("it updates the styles", () => {
      renderHook(() => useElementStyles(elementSelector, { color: "red" }));

      expect(document.getElementById(elementId).style.color).toBe("red");
    });
  });

  describe("with existing styles", () => {
    beforeEach(() => {
      document.body.innerHTML = `<div style="background-color: black" id="${elementId}" />`;
    });

    test("it does not remove existing styles", () => {
      renderHook(() => useElementStyles(elementSelector, { color: "red" }));

      expect(document.getElementById(elementId).style.color).toBe("red");
      expect(document.getElementById(elementId).style.backgroundColor).toBe(
        "black"
      );
    });

    test("it overwrites existing style that match", () => {
      renderHook(() =>
        useElementStyles(elementSelector, { backgroundColor: "red" })
      );

      expect(document.getElementById(elementId).style.backgroundColor).toBe(
        "red"
      );
    });
  });
});
