import React from "react";
import { render } from "@testing-library/react";
import { Button } from "./";

describe("Button", () => {
  const defaultProps = {
    children: <div data-testid="CHILD" />,
    onClick: jest.fn(),
  };

  const renderComponent = (props) =>
    render(<Button {...defaultProps} {...props} />);

  test("it renders children", async () => {
    const { queryByTestId } = renderComponent();

    expect(await queryByTestId("CHILD")).toBeTruthy();
  });
});
