import React from "react";
import { render } from "@testing-library/react";
import * as api from "../../../shared/useGetApi";
import TabContainer from "./";

jest.mock("./configurations", () => {
  return {
    tabConfigurations: [
      {
        id: "permitted-component",
        alwaysShow: false,
        component: () => {
          return <div data-testid="PERMITTED" />;
        },
        permissionAttribute: "canShowPermitted",
        title: "Permitted Component"
      },      
      {
        id: "forbidden-component",
        alwaysShow: false,
        component: () => {
          return <div data-testid="FORBIDDEN" />;
        },
        permissionAttribute: "canShowForbidden",
        title: "Forbidden Component"
      },
      {
        id: "always-show-component",
        alwaysShow: true,
        component: () => {
          return <div data-testid="ALWAYS-SHOW" />;
        },
        title: "Always Shown Component"
      }
    ]
  }
});

describe("TabContainer", () => {
  const noteTypes = ["Owner", "Pet"];
  const ownerId = 1;

  const defaultProps = {
    noteTypes,
    ownerId,
  };

  const renderComponent = (props) =>
    render(<TabContainer {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("permissions", () => {
    const permissions = {
      meta: {
        permissions: {
          canShowPermitted: true,
          canShowForbidden: false,
        }
      }
    }
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [permissions, false]);
    });

    test("renders the permitted tabs", async () => {
      const { queryByText } = renderComponent();

      expect(await queryByText("Permitted Component")).toBeTruthy();
    });

    test("does not render forbidden tabs", async () => {
      const { queryByText } = renderComponent();

      expect(await queryByText("Forbidden Component")).toBeFalsy();
    });

    test("renders the always shown tabs", async () => {
      const { queryByText } = renderComponent();

      expect(await queryByText("Always Shown Component")).toBeTruthy();
    });
  });
});