import cx from "classnames";
import { func, object } from "prop-types";
import React, { Fragment } from "react";
import Modal from "../../../../../shared/Modal";
import { RenderIf } from "../../../../../shared/RenderIf";
import { Table } from "../../../../../shared/Table" ;
import { prescriptionOrderStatus } from "../utils";

const ViewDetails = ({ prescription, closeModal }) => {
  const prescriptionOrder = prescription.orderItem && prescription.orderItem.order ? prescription.orderItem.order : {};

  return (
    <Fragment>
      <Modal.Header closeModal={closeModal}>
        <h3>{`Prescription for ${prescription.pet.name} (${prescription.owner.name})`}</h3>
      </Modal.Header>

      <Modal.Body>
        <Table isNested={true} isBordered={false} isStriped={false} isCondensed={false}>
          <tbody className="no-hover">
            <tr>
              <td className="no-border">
                <strong>Status:</strong>&nbsp;
                <span className={cx("label", `label-${prescription.statusObject.labelClass}`)}>
                  {prescription.statusObject.status}
                </span>
              </td>
              <td className="no-border">
                <RenderIf isTrue={!prescription.fulfilledAtClinic}>
                  <strong>Pharmacy Status:</strong>

                  {prescription.statusMessages.map((statusMessage) => {
                    <span className={statusMessage.labelClass}>
                      <i>{statusMessage.text}</i>
                    </span>
                  })}
                </RenderIf>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <strong>Product:</strong> {prescription.product.fullName}<br />
              </td>
            </tr>
            <RenderIf isTrue={Boolean(prescription.prescriptionVaccert.clinic)}>
              <tr>
                <td>
                  <strong>Clinic:</strong> {prescription.prescriptionVaccert.clinic.clinicLocation.facilityInternalWithExternalId}
                </td>
                <td>
                  <strong>Clinic Date:</strong> {prescription.prescriptionVaccert.clinic.clinicOn}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Vet:</strong> {prescription.vetFullName}
                </td>
                <td>
                  <strong>Vaccert:</strong> {prescription.prescriptionVaccert.vaccertNumber}
                </td>
              </tr>
            </RenderIf>

            <tr>
              <td>
                <strong>Effective On:</strong> {prescription.effectiveOn}
              </td>
              <td>
                <strong>Expires On:</strong> {prescription.expiresOn}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Printed On:</strong>&nbsp;
                <RenderIf isTrue={Boolean(prescription.printedAt)}>
                  {prescription.printedAt}
                  <br />
                  <strong>Printed By:</strong> {prescription.printedBy}
                </RenderIf>
              </td>
              <td>
                <strong>Fulfilled On:</strong>&nbsp;
                <RenderIf isTrue={Boolean(prescription.fulfilledAt)}>
                  {prescription.fulfilledAt}
                  <br />
                  <strong>Fulfilled By:</strong> {prescription.fulfilledBy}
                </RenderIf>
              </td>
            </tr>

            <tr className="border-top">
              <td>
                <strong>Prescription Doses:</strong> {prescription.product.containerCount}
              </td>
              <td>
                <strong>Refill Doses Available:</strong> {prescription.refillDosesAvailable}
              </td>
            </tr>

            <RenderIf isTrue={Boolean(prescription.fulfillmentNote)}>
              <tr className="border-top">
                <td colSpan="2">
                  <strong>Fulfillment Note:</strong>&nbsp;
                  {prescription.fulfillmentNote}
                </td>
              </tr>
            </RenderIf>

            <RenderIf isTrue={Boolean(prescription.dosageInstructions)}>
              <tr className="border-top">
                <td colSpan="2">
                  <strong>Dosing Instructions:</strong>&nbsp;
                  {prescription.dosageInstructions}
                </td>
              </tr>
            </RenderIf>

            <RenderIf isTrue={Boolean(prescriptionOrder.id)}>
              <tr className="border-top">
                <td>
                  <strong>Order:</strong> {prescriptionOrder.id}<br/>
                  <strong>Created by:</strong> {Boolean(prescriptionOrder.id) && prescriptionOrder.creator.userName}<br/>
                  <strong>At:</strong> {prescriptionOrder.createdAt}
                </td>
                <td>
                  <strong>Order Status:</strong><br/>
                  {prescriptionOrderStatus(prescriptionOrder)}
                  <RenderIf isTrue={Boolean(prescriptionOrder.orderCurrentShippingStatus)}>
                    <br/>{prescriptionOrder.orderCurrentShippingStatus}
                  </RenderIf>
                </td>
              </tr>
            </RenderIf>

            <tr>
              <td colSpan="2" className="muted">
                <i>
                  Updated at {prescription.updatedAt}<br />
                  ID: {prescription.identifier}

                  <RenderIf isTrue={Boolean(prescription.vendorExternalId)}>
                    {`/ Pharmacy Id: ${prescription.vendorExternalId}`}
                  </RenderIf>
                </i>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer closeModal={closeModal} />
    </Fragment>
  );
};

ViewDetails.propTypes = {
  prescription: object.isRequired,
  closeModal: func.isRequired,
};

export default ViewDetails;
