import { toLabelValuePairs } from "./toLabelValuePairs";

describe("toLabelValuePairs", () => {
  test("creates object with labels and values", () => {
    const labels = ["Apple", "Banana", "Carrot"];
    const values = [1, 2, 3];
    expect(toLabelValuePairs(labels, values)).toEqual([
      {
        label: "Apple",
        value: 1,
      },
      {
        label: "Banana",
        value: 2,
      },
      {
        label: "Carrot",
        value: 3,
      },
    ]);
  });
});
