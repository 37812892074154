import { makeUrl, post } from "../";

const orderBaseUrl = "/orders";

export const api = {
  orders: {
    createOrderForPrescriptions: (_orderId, body) =>
      post(
        makeUrl([orderBaseUrl, "/create_order_for_prescriptions"]),
        body,
      ),
  }
};
