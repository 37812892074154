import React from "react";
import { render } from "@testing-library/react";
import { Pagination } from "./";

describe("Pagination", () => {
  const defaultProps = {
    label: "ITEMS",
    recordCount: 30,
    perPage: 30,
  };

  const leftArrow = "\u2190";
  const rightArrow = "\u2192";

  const renderComponent = (props) =>
    render(<Pagination {...defaultProps} {...props} />);

  describe("rendering", () => {
    test("it renders nothing if no records", async () => {
      const { queryByText, queryAllByRole } = renderComponent({
        recordCount: 0,
      });
      const label = await queryByText(/ITEMS/);
      const buttons = await queryAllByRole("pagination-button");

      expect(label).toBeNull;
      expect(buttons).toBeNull;
    });

    describe("label", () => {
      test("it has the correct label", async () => {
        const { findByText } = renderComponent();
        const label = await findByText(/ITEMS/);

        expect(label).toBeTruthy;
      });

      describe("all records", () => {
        test("it shows the amount of records", async () => {
          const { findByText } = renderComponent({ recordCount: 30 });
          const intervalElement = await findByText(/30/);
          const intervalContainer = intervalElement.parentElement;

          expect(intervalContainer.textContent).toBe("All 30 ITEMS");
        });
      });

      describe("paginated records", () => {
        test("it shows the correct interval first page", async () => {
          const { findByText } = renderComponent({ recordCount: 60 });
          const intervalElement = await findByText("1 - 30");
          const intervalContainer = intervalElement.parentElement;

          expect(intervalContainer.textContent).toBe("ITEMS 1 - 30 of 60");
        });

        test("it shows the correct interval other pages", async () => {
          const { findByText } = renderComponent({
            startPage: 2,
            recordCount: 60,
          });
          const intervalElement = await findByText("31 - 60");
          const intervalContainer = intervalElement.parentElement;

          expect(intervalContainer.textContent).toBe("ITEMS 31 - 60 of 60");
        });
      });
    });

    describe("buttons", () => {
      describe("it renders correct number of buttons and arrow buttons", () => {
        test("for one page", async () => {
          const { queryAllByRole } = renderComponent({ recordCount: 30 });
          const buttons = await queryAllByRole("pagination-button");

          expect(buttons).toBeNull;
        });

        test("for two pages", async () => {
          const { findAllByRole } = renderComponent({ recordCount: 60 });
          const buttons = await findAllByRole("pagination-button");
          const validButtons = [leftArrow, "1", "2", rightArrow];
          const actualButtons = buttons.map((button) => button.textContent);

          expect(buttons.length).toBe(4);
          expect(actualButtons).toStrictEqual(validButtons);
        });

        test("for no pages", async () => {
          const { queryByRole } = renderComponent({ recordCount: 0 });
          const buttons = await queryByRole("pagination-button");

          expect(buttons).toBe(null);
        });
      });

      describe("it renders maximum 3 numeric buttons and 2 arrow buttons", () => {
        test("when current page is 1", async () => {
          const { findAllByRole } = renderComponent({ recordCount: 150 });
          const buttons = await findAllByRole("pagination-button");
          const validButtons = [leftArrow, "1", "2", "3", rightArrow];
          const actualButtons = buttons.map((button) => button.textContent);

          expect(buttons.length).toBe(5);
          expect(actualButtons).toStrictEqual(validButtons);
        });

        test("when 1 < current page < total pages", async () => {
          const { findAllByRole } = renderComponent({
            startPage: 3,
            recordCount: 150,
          });
          const buttons = await findAllByRole("pagination-button");
          const validButtons = [leftArrow, "2", "3", "4", rightArrow];
          const actualButtons = buttons.map((button) => button.textContent);

          expect(buttons.length).toBe(5);
          expect(actualButtons).toStrictEqual(validButtons);
        });

        test("when current page is equal to total pages", async () => {
          const { findAllByRole } = renderComponent({
            startPage: 5,
            recordCount: 150,
          });
          const buttons = await findAllByRole("pagination-button");
          const validButtons = [leftArrow, "3", "4", "5", rightArrow];
          const actualButtons = buttons.map((button) => button.textContent);

          expect(buttons.length).toBe(5);
          expect(actualButtons).toStrictEqual(validButtons);
        });
      });
    });
  });

  describe("navigation", () => {
    describe("enabled buttons", () => {
      test("it disables current page button", async () => {
        const { findByText } = renderComponent({ recordCount: 60 });
        const currentButton = await findByText("1");

        expect(currentButton.disabled).toBeTruthy;
      });

      test("it disables prev if current page is 1", async () => {
        const { findByText } = renderComponent({ recordCount: 60 });
        const prevButton = await findByText(leftArrow);

        expect(prevButton.disabled).toBeTruthy;
      });

      test("it disables next if current page is max", async () => {
        const { findByText } = renderComponent({ recordCount: 60 });
        const nextButton = await findByText(rightArrow);

        expect(nextButton.disabled).toBeTruthy;
      });

      test("other buttons are enabled", async () => {
        const { findAllByRole } = renderComponent({
          startPage: 2,
          recordCount: 150,
        });
        const buttons = await findAllByRole("pagination-button");
        const enabledButtons = [leftArrow, "1", "3", rightArrow];

        buttons.forEach((button) => {
          const buttonText = button.textContent;

          if (enabledButtons.includes(buttonText)) {
            expect(button.disabled).toBeFalsey;
          } else {
            expect(button.disabled).toBeTruthy;
          }
        });
      });
    });
  });
});
