import React from "react";
import { render } from "@testing-library/react";
import { Flex } from "./";

describe("Flex", () => {
  const defaultProps = {
    children: <div data-testid="CHILD" />,
  };

  const renderComponent = (props) =>
    render(<Flex {...defaultProps} {...props} />);

  test("it renders the children", async () => {
    const { queryByTestId } = renderComponent();

    expect(await queryByTestId("CHILD")).toBeTruthy();
  });
});
