import React, { Fragment } from "react";

export const TableHeaderLabels = () => {
  return (
    <Fragment>
      <th className="span1 aligncenter">Actions</th>
      <th className="span1 aligncenter">Status</th>
      <th className="aligncenter">Pet</th>
      <th className="span3 aligncenter">Product</th>
      <th className="span1 aligncenter">Doses<p className="mb-0">Distributed</p></th>
      <th className="span1 aligncenter">Doses<p className="mb-0">Available</p></th>
      <th className="span3 aligncenter">Details</th>
      <th className="aligncenter">Vet</th>
      <th className="span2 aligncenter">Date</th>
    </Fragment>
  )
};
