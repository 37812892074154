import { arrayOf, bool, shape, string } from "prop-types";
import React from "react";

const ProductVaccert = ({ productVaccert }) => {

  const resultIdentifier = (result) => (
    result.isTestPositive ? "POSITIVE" : "NEGATIVE"
  );

  const {
    petName,
    text1,
    product,
  } = productVaccert;

  return(
    <div key={`${productVaccert.id}-${product.productType.id}`}>
      <h3>
        {petName} - {text1} { product.productType.name }
      </h3>
      <div className="pending-treatments-test-results">
        {productVaccert.profileTests.map((labTest) => (
          labTest.profileTestResults.map((result) => (
            <div key={`result-${result.id}`}>
              <span className="pending-treatments-test-name">{result.labTestCodeName}: </span>
              <span className="pending-treatments-test-results-result">{resultIdentifier(result)} </span>
              <span className="pending-treatments-test-results-notes muted">{result.testNotes}</span>
              <br />
            </div>
          ))
        ))}
      </div>
    </div>
  );
};

ProductVaccert.propTypes = {
  productVaccert: shape({
    petName: string.isRequired,
    product: shape({
      productType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }),
    }).isRequired,
    profileTests: arrayOf(
      shape({
        id: string,
        labTestCodeName: string,
        isTestPositive: bool,
        testNotes: string,
      })
    ).isRequired,
    text1: string.isRequired,
  }).isRequired
};

export default ProductVaccert;
