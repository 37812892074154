const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const simpleFormatter = new Intl.NumberFormat("en-US");

const toNegativeFormat = (input) => {
  return `(${String(input).replace("-", "")})`;
};

export const toMoney = (input) => {
  const formatted = currencyFormatter.format(input);

  return input < 0 ? toNegativeFormat(formatted) : formatted;
};

export const toNumber = (input) => {
  return simpleFormatter.format(input);
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
