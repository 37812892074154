import axios from "axios";
import { api } from "./";

jest.mock("axios", () => ({
  defaults: {
    headers: {
      common: {},
    },
  },
  get: jest.fn().mockName("get").mockResolvedValue({
    data: [],
  }),
  post: jest.fn().mockName("post").mockResolvedValue({
    data: {},
  }),
  put: jest.fn().mockName("put").mockResolvedValue({
    data: {},
  }),
}));

describe("Rabies Lot API", () => {
  const rigCodeId = "RIG_CODE_ID";
  const rabiesLotId = "RABIES_LOT_ID"
  const productId = "PRODUCT_ID"

  describe("officeRabiesLots", () => {
    const params = { product_id: productId}
    test("it can get data", async () => {
      await api.officeRabiesLots.get(rigCodeId, params);

      expect(axios.get).toBeCalledWith(
        `/rig_codes/${rigCodeId}/office_rabies_lots`,
        {
          params: params,
          transformResponse: expect.any(Function)
        }
      );
    });
  })
  describe("rabiesLot", () => {
    test("it can post data", async () => {
      await api.rabiesLot.create(
        { test: "value" }
      );

      expect(axios.post).toBeCalledWith(
        "/rabies_lots",
        {"rabies_lot": {"test": "value"}},
        {}
      );
    });

    test("it can get products", async () => {
      await api.rabiesLot.products();

      expect(axios.get).toBeCalledWith(
        `/rabies_lots/products`,
        { transformResponse: expect.any(Function) }
      );
    });

    test("it can put data", async () => {
      await api.rabiesLot.update(
        rabiesLotId,
        { test: "value" }
      );

      expect(axios.put).toBeCalledWith(
        `/rabies_lots/${rabiesLotId}`,
        {
          rabies_lot: {
            test: "value",
          },
        },
        {}
      );
    });

    test("it can get rig codes", async () => {
      await api.rabiesLot.rigCodes();

      expect(axios.get).toBeCalledWith(
        `/rabies_lots/rig_codes`,
        { transformResponse: expect.any(Function) }
      );
    });
  })
})
