import { renderHook } from "@testing-library/react-hooks";
import { usePutApi } from "./";

describe("usePutApi", () => {
  const put = jest
    .fn()
    .mockName("put")
    .mockResolvedValue([
      {
        id: "ID",
      },
    ]);
  const id = "ID";
  const body = null;
  const params = {};

  describe("shouldExecute is false", () => {
    test("does not call the api", () => {
      const hook = renderHook(() =>
        usePutApi({
          put,
          id,
          body,
          params,
          shouldExecute: false,
        })
      );
  
      const [_data, loading] = hook.result.current;
  
      expect(loading).toBe(false);
      expect(put).not.toBeCalled();
    });
  });

  describe("shouldExecute is true", () => {
    test("calls the provided api", () => {
      const hook = renderHook(() =>
        usePutApi({
          put,
          id,
          body,
          params,
          shouldExecute: true,
        })
      );
  
      const [_data, loading] = hook.result.current;

      expect(loading).toBe(true);
      expect(put).toBeCalledWith(id, body, params);
  
      hook.waitForNextUpdate();
    });
  
    test("puts data", (done) => {
      const hook = renderHook(() =>
        usePutApi({
          put,
          id,
          body,
          params,
          shouldExecute: true,
        })
      );
  
      hook.waitForNextUpdate().then(() => {
        const [data, loading] = hook.result.current;
  
        expect(loading).toBe(false);
        expect(data).toEqual([
          {
            id: "ID",
          },
        ]);
        done();
      });
  
      hook.rerender({
        put,
        id,
        body,
        params,
        shouldExecute: true,
      });
    });
  });
});
