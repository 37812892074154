import React from "react";
import { render } from "@testing-library/react";
import { Form } from "./";

describe("Form", () => {
  const defaultProps = {
    action: "/test",
    children: <div data-testid="CHILD" />,
    className: "test-form",
    formId: "test-form-id",
    method: "post",
  };

  const renderComponent = (props) =>
    render(<Form {...defaultProps} {...props} />);

  beforeAll(() => {
    const mockQuerySelector = jest.fn().mockReturnValue({ content: "TOKEN" });
    // Prevent eslint from assuming global is undefined
    // eslint-disable-next-line no-undef
    jest.spyOn(global.document, "querySelector").mockImplementation(() => mockQuerySelector());
  });

  test("it renders the children", async () => {
    const { queryByTestId } = renderComponent();

    expect(await queryByTestId("CHILD")).toBeTruthy();
  });

  test("it has the correct CSRF token hidden input", async () => {
    const { container } = renderComponent();
    const input = await container.querySelector("input[type=hidden")
    
    expect(input.name).toBe("authenticity_token");
    expect(input.type).toBe("hidden");
    expect(input.value).toBe("TOKEN")
  });
});