import ProductVaccert from "../ProductVaccert";
import { array, bool, func, number } from "prop-types";
import React, { useState } from "react";
import { Button, ButtonContexts } from "../../../../shared/Button";
import { Loader } from "../../../../shared/Loader";
import { RenderIf } from "../../../../shared/RenderIf";
import TreatmentsGroup from "./TreatmentsGroup";
import { usePrescriptionCreate } from "./usePrescriptionCreate";
import {
  groupBySymptoms,
  treatmentForSymptom,
  sortByProductName,
  checkedIds,
} from "../utils";

const Treatments = ({
  treatments,
  ownerId,
  setReloadCurrentPendingTreatments,
  reloadCurrentPendingTreatments,
  setReloadPrescriptions,
  reloadPrescriptions
}) => {
  const [pendingTreatments, setPendingTreatments] = useState(treatments);
  const productVaccert = pendingTreatments[0].purchasedProductVaccert;
  const prescribableSymptomsAndTreatmentProducts = productVaccert.prescribableSymptomsAndTreatmentProducts;
  const treatmentsGroupedBySymptoms = groupBySymptoms(pendingTreatments);

  const { setExecutePrescriptionCreate, loadingPrescriptionCreate } = usePrescriptionCreate({
    ownerId,
    productVaccertId: productVaccert.id,
    selectedTreatmentIds: checkedIds(pendingTreatments),
    setReloadCurrentPendingTreatments,
    reloadCurrentPendingTreatments,
    setReloadPrescriptions,
    reloadPrescriptions,
  });

  const cancelAlternateTreatment = () => {
    if (!window.confirm("Cancel alternate treatment selection?")) return;

    setPendingTreatments(
      pendingTreatments.map((treatment) => (
        {...treatment, checked : false}
      ))
    )
    setExecutePrescriptionCreate(true);
  };

  const confirmThenExecutePrescriptionCreate = () => {
    if (!window.confirm("Please review your selections. Clicking 'OK' will create prescriptions for the selected treatment products.")) return;

    setExecutePrescriptionCreate(true)
  }

  if (loadingPrescriptionCreate) {
    return <Loader />;
  };

  return (
    <div key={productVaccert.text1} className="well">
      <ProductVaccert productVaccert={productVaccert} />
      {Object.entries(treatmentsGroupedBySymptoms).map((treatmentsBySymptomGroup, index) => {
        const symptomId = treatmentsBySymptomGroup[0];
        const symptomTreatments = treatmentsBySymptomGroup[1];
        const symptomTreatment = treatmentForSymptom(prescribableSymptomsAndTreatmentProducts, symptomId);

        return (
          <div key={`${symptomId}-${index}`}>
            <hr className="divider" />
            <div className="row">
              <div className="span4">
                <dl className="dl-horizontal">
                  <dt>Symptom:</dt>
                  <dd>
                    {symptomId}
                  </dd>
                </dl>
              </div>
              <div className="span4">
                <dl className="pending-treatments-dl">
                  <dt>Treatments:</dt>
                  <RenderIf isTrue={symptomTreatment == null}>
                    <dd><i>(Treatment product was provided on the vaccert)</i></dd>
                  </RenderIf>
                  <RenderIf isTrue={symptomTreatment != null}>
                    <dd>
                      <ul className="pending-treatments-selection-list">
                        <TreatmentsGroup
                          treatmentsGroup={sortByProductName(symptomTreatments)}
                          pendingTreatments={pendingTreatments}
                          setPendingTreatments={setPendingTreatments}
                        />
                      </ul>
                    </dd>
                  </RenderIf>
                </dl>
              </div>
            </div>
          </div>
        )
      })}
      <hr className="divider" />
      <RenderIf isTrue={productVaccert.existingPrescriptions}>
        <Button
          context={ButtonContexts.DANGER}
          onClick={cancelAlternateTreatment}
        >
          Cancel Alternate Treatment Selection
        </Button>
      </RenderIf>
      <Button
        context={ButtonContexts.PRIMARY}
        onClick={() => confirmThenExecutePrescriptionCreate()}
        className="pull-right"
      >
        {`Create Prescriptions for ${productVaccert.text1}`}
      </Button>
      <p>&nbsp;</p>
    </div>
  );
};

Treatments.propTypes = {
  treatments: array.isRequired,
  ownerId: number.isRequired,
  setReloadCurrentPendingTreatments: func.isRequired,
  reloadCurrentPendingTreatments: bool.isRequired,
  setReloadPrescriptions: func.isRequired,
  reloadPrescriptions: bool.isRequired,
};

export default Treatments;
