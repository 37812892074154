import { api } from "@/api";
import { Formik, Form } from "formik";
import { array, func, number, object } from "prop-types";
import * as Yup from "yup";
import React, { useEffect } from "react";
import styled from "styled-components";
import { BillingAddress } from "@/shared/BillingAddress";
import { Button } from "@/shared/Button";
import { ErrorMessage } from "@/shared/ErrorMessage";
import { MONTHS, YEARS } from "@/utils/dateFormatter";
import { Select } from "@/shared/Select";
import { useAlert } from "@/contexts/alertContext";
import creditImage from "@/images/mc_vs_ax_dis_h.jpg";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PaymentMethod = ({
  billingAddress,
  ownerId,
  salesTotal,
  setBillingAddress,
  saleUuids,
}) => {
  const { setAlert } = useAlert();

  const { address, city, state, zip } = billingAddress;

  const initialValues = {
    saleUuids: saleUuids,
    amount: salesTotal,
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingCountry: "US",
    billingNameOnCard: "",
    billingState: "",
    billingZip: "",
    creditCardBrands: "",
    creditCardExpiresOnMonth: "",
    creditCardExpiresOnYear: "",
    creditCardNumber: "",
    creditCardVerification: "",
    paymentMethod: "credit_card",
    paymentType: "credit_card",
  };

  return (
    <div className="span6">
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={async (values) => {
          values.amount = salesTotal;
          values.saleUuids = saleUuids;

          const response = await api.owners.payment(ownerId, values);

          if (response.success) {
            window.location.href = "/owners/" + ownerId;
          } else {
            setAlert({ children: response.message, context: "alert-danger" });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setValues,
          touched,
          values,
        }) => {
          useEffect(() => {
            values.billingAddress = address;
            values.billingAddress2 = "";
            values.billingCity = city;
            values.billingState = state;
            values.billingZip = zip;

            setValues(values);
          }, [billingAddress, setBillingAddress]);
          return (
            <Form
              className="form-horizontal"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="control-group">
                <label
                  className="control-label fontbold"
                  htmlFor="billingNameOnCard"
                >
                  Name on Card
                </label>

                <div className="controls">
                  <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.billingNameOnCard}
                    name="billingNameOnCard"
                  />
                  {errors.billingNameOnCard && touched.billingNameOnCard ? (
                    <ErrorMessage error={errors.billingNameOnCard} />
                  ) : null}
                </div>
              </div>
              <div className="control-group">
                <label
                  className="control-label fontbold"
                  htmlFor="creditCardBrands"
                >
                  Credit Card Type
                </label>

                <div className="controls">
                  <Select
                    defaultLabel="Credit Card"
                    id="creditCardBrands"
                    name="creditCardBrands"
                    onChange={handleChange}
                    selectedValue={values.creditCardBrands}
                    options={creditCardBrands}
                  />
                  {errors.creditCardBrands && touched.creditCardBrands ? (
                    <ErrorMessage error={errors.creditCardBrands} />
                  ) : null}
                </div>
              </div>
              <div className="control-group">
                <div className="controls">
                  <img src={creditImage} />
                </div>
              </div>
              <div className="control-group">
                <label
                  className="control-label fontbold"
                  htmlFor="creditCardNumber"
                >
                  Card Number
                </label>

                <div className="controls">
                  <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.creditCardNumber}
                    name="creditCardNumber"
                  />
                  {errors.creditCardNumber && touched.creditCardNumber ? (
                    <ErrorMessage error={errors.creditCardNumber} />
                  ) : null}
                </div>
              </div>
              <div className="credit_card_component control-group">
                <div className="control-group">
                  <label
                    className="control-label fontbold"
                    htmlFor="creditCardExpiresOnYear"
                  >
                    Expiration Date
                  </label>

                  <div className="controls">
                    <input
                      type="hidden"
                      id="order_credit_card_expires_on_3i"
                      name="order[credit_card_expires_on(3i)]"
                      value="1"
                    />
                    <Select
                      id="creditCardExpiresOnMonth"
                      name="creditCardExpiresOnMonth"
                      onChange={handleChange}
                      selectedValue={values.creditCardExpiresOnMonth}
                      options={MONTHS}
                      className="input-medium"
                    />
                    {errors.creditCardExpiresOnYear &&
                    touched.creditCardExpiresOnYear ? (
                      <ErrorMessage error={errors.creditCardExpiresOnYear} />
                    ) : null}
                    <Select
                      id="creditCardExpiresOnYear"
                      name="creditCardExpiresOnYear"
                      onChange={handleChange}
                      selectedValue={values.creditCardExpiresOnYear}
                      options={YEARS}
                      className="input-medium"
                    />
                    {errors.creditCardExpiresOnMonth &&
                    touched.creditCardExpiresOnMonth ? (
                      <ErrorMessage error={errors.creditCardExpiresOnMonth} />
                    ) : null}
                  </div>
                </div>
              </div>
              <label
                className="control-label fontbold"
                htmlFor="creditCardVerification"
              >
                CSV
              </label>

              <div className="controls">
                <input
                  className="input-mini"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.creditCardVerification}
                  name="creditCardVerification"
                  autoComplete="off"
                />
                {errors.creditCardVerification &&
                touched.creditCardVerification ? (
                  <ErrorMessage error={errors.creditCardVerification} />
                ) : null}
                <p className="help-block">
                  <em>{`(or 'CVC' or 'CVV')`}</em>
                  {` The last 3 digits displayed on the
                back of card. (Amex: 4 digits at top right, on front of card)`}
                </p>
              </div>
              <BillingAddress
                billingAddress={values.billingAddress}
                billingAddress2={values.billingAddress2}
                billingCity={values.billingCity}
                billingCountry={values.billingCountry}
                billingState={values.billingState}
                billingZip={values.billingZip}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
              />
              <ButtonWrapper>
                <Button
                  type="submit"
                  context="btn-success"
                  disabled={isSubmitting || !isValid}
                >
                  Make Payment
                </Button>
              </ButtonWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PaymentMethod.defaultProps = {
  billingAddress: "",
  setBillingAddress: "",
};

PaymentMethod.propTypes = {
  billingAddress: object,
  ownerId: number.isRequired,
  salesTotal: number.isRequired,
  setBillingAddress: func,
  saleUuids: array,
};

const creditCardBrands = [
  { label: "Visa", value: "visa" },
  { label: "Mastercard", value: "master" },
  { label: "American Express", value: "american_express" },
  { label: "Discover", value: "discover" },
];

const ValidationSchema = Yup.object().shape({
  amount: Yup.number().required("Required"),
  billingAddress: Yup.string().required("Required"),
  billingAddress2: Yup.string().notRequired(),
  billingCity: Yup.string().required("Required"),
  billingCountry: Yup.string().required("Required"),
  billingNameOnCard: Yup.string().required("Required"),
  billingState: Yup.string().required("Required"),
  billingZip: Yup.string().required("Required"),
  creditCardBrands: Yup.string().required("Required"),
  creditCardExpiresOnMonth: Yup.number().required("Required"),
  creditCardExpiresOnYear: Yup.number().required("Required"),
  creditCardNumber: Yup.number().required("Required"),
  creditCardVerification: Yup.number().required("Required"),
  paymentMethod: Yup.string().required("Required"),
  paymentType: Yup.string().required("Required"),
  saleUuids: Yup.array().required().min(1),
});
