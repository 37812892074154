import { api } from "../../../../api";
import cx from "classnames";
import { filterPrescriptions } from "../containerUtils";
import { func, number } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Checkbox } from "../../../../shared/Checkbox";
import { Loader } from "../../../../shared/Loader";
import { Pagination } from "../../../../shared/Pagination";
import { PortalModal } from "../../../../shared/PortalModal";
import { RenderIf } from "../../../../shared/RenderIf";
import { Table } from "../../../../shared/Table";
import { useGetApi } from "../../../../shared/useGetApi";
import { useModal } from "../../../../shared/useModal";
import { useRecordCount } from "../../../../shared/useRecordCount";
import { TableHeaderLabels } from "../TableHeaderLabels";
import { modalStates } from "./utils";

const AdHocPrescriptions = ({ ownerId, updateTotalCounts }) => {
  const [hideInactivePrescriptions, setHideInactivePrescriptions] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalState, setModalState] = useState("");
  const { open, openModal, closeModal } = useModal();

  const [adHocPrescriptions, loading] = useGetApi({
    get: api.owners.adHocPrescriptions,
    id: ownerId,
    params: {
      ad_hoc_prescription: pageNumber,
    },
  });

  const adHocPrescriptionsCount = useRecordCount(adHocPrescriptions);

  useEffect(() => {
    updateTotalCounts({ adHocPrescriptionsCount });
  }, [adHocPrescriptionsCount]);

  if (!adHocPrescriptions || loading) {
    return <Loader message="Loading Ad-Hoc Prescriptions..." />;
  }

  if (adHocPrescriptions.length === 0) return null;

  const initializeModal = (prescription, state) => {
    setModalState(modalStates(prescription, closeModal)[state]);
    openModal();
  };

  const filteredPrescriptions = filterPrescriptions(
    { hideInactivePrescriptions },
    adHocPrescriptions
  );

  return (
    <Fragment>
      <Pagination
        startPage={pageNumber}
        label={"Ad-Hoc Prescriptions"}
        onPageChange={setPageNumber}
        recordCount={adHocPrescriptionsCount}
      />

      <RenderIf isTrue={open}>
        <PortalModal closeModal={closeModal}>{modalState}</PortalModal>
      </RenderIf>

      <Table>
        <thead>
          <tr>
            <th colSpan="9">
              <h3>Ad-Hoc Prescriptions</h3>
            </th>
          </tr>
          <tr>
            <th colSpan="9">
              <div className="prescriptions-container__checkbox">
                <Checkbox
                  onChange={setHideInactivePrescriptions}
                  checked={hideInactivePrescriptions}
                  text={"Hide Inactive Prescriptions"}
                />
              </div>
            </th>
          </tr>
          <tr>
            <TableHeaderLabels />
          </tr>
        </thead>

        <tbody className="no-hover">
          {filteredPrescriptions.map((adHocPrescription) => {
            return (
              <tr
                key={adHocPrescription.id}
                className={cx(
                  "ad_hoc_prescription-show-all",
                  `ad_hoc_prescription-show-${adHocPrescription.statusObject.rowClass}`
                )}
              >
                <td className={cx("aligncenter", "vertical-align-top")}>
                  <div className="btn-group">
                    <button
                      className="btn btn-mini dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="icon icon-list"> </i>
                      <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          onClick={() =>
                            initializeModal(adHocPrescription, "view")
                          }
                        >
                          View Details
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>

                <td className="aligncenter">
                  <span
                    className={cx(
                      "label",
                      `label-${adHocPrescription.statusObject.labelClass}`
                    )}
                  >
                    {adHocPrescription.deliveryType}
                  </span>
                </td>

                <td className="aligncenter">{adHocPrescription.petName}</td>

                <td className="aligncenter">{adHocPrescription.productName}</td>

                <td>&nbsp;</td>

                <td className="aligncenter">{adHocPrescription.doses}</td>

                <td className="aligncenter">
                  {adHocPrescription.status}
                  <br />
                  Refills: {adHocPrescription.displayRefills}
                </td>

                <td className="aligncenter">
                  {`${adHocPrescription.vetFirstName} ${adHocPrescription.vetLastName}`}
                </td>

                <td className="aligncenter">{adHocPrescription.createdAt}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

AdHocPrescriptions.propTypes = {
  ownerId: number.isRequired,
  updateTotalCounts: func.isRequired,
};

export default AdHocPrescriptions;
