// eslint-disable-next-line no-unused-vars
import React from "react";
import { bool, node } from "prop-types";

export const RenderIf = ({ children, isTrue }) => (isTrue ? children : null);

RenderIf.propTypes = {
  children: node.isRequired,
  isTrue: bool.isRequired,
};
