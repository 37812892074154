export const formValuesToApi = ({
  rigCodeId,
  productId,
  lotNumber,
  lotExpirationDate,
  lotRelevanceStart,
  lotRelevanceEnd,
  isActive,
}) => ({
  rig_code_id: rigCodeId,
  product_id: productId,
  lot_number: lotNumber,
  lot_expiration_date: lotExpirationDate,
  lot_relevance_start: lotRelevanceStart,
  lot_relevance_end: lotRelevanceEnd,
  is_active: isActive
});
