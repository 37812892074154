import { make } from "./creation";
import { example } from "./example";
import {
  AMPMOptions,
  dayOfWeekOptions,
  hourOptions,
  minuteOptions,
} from "./options";
import {
  formValuesToApi,
  toAMPM,
  toCivilianTime,
  toMilitaryTime,
} from "./transforms";

export const ClinicLocationHours = {
  AMPMOptions,
  dayOfWeekOptions,
  example,
  formValuesToApi,
  hourOptions,
  make,
  minuteOptions,
  toAMPM,
  toCivilianTime,
  toMilitaryTime,
};
