import React, { useState } from "react";
import { array, number, string } from "prop-types";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { Pagination } from "../../../shared/Pagination";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useGetApi } from "../../../shared/useGetApi";
import { useRecordCount } from "../../../shared/useRecordCount";
import { useTabCount } from "../../../shared/useTabCount";

const getEmptyMessage = (entityType) => `No visits for this ${entityType}.`;

const Visits = ({ entityId, entityType, tabState }) => {
  const apiHandler = entityType === "owner" ? api.owners : api.pets
  const [pageNumber, setPageNumber] = useState(1);
  const [visits, loading] = useGetApi({
    get: apiHandler.visits,
    id: entityId,
    params: {
      visit: pageNumber,
    },
  });

  const visitsCount = useRecordCount(visits);
  useTabCount({ count: visitsCount }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Pagination
        startPage={pageNumber}
        label={"Visits"}
        onPageChange={setPageNumber}
        recordCount={visitsCount}
      />
      <Table id={"viewvisits"}>
        <thead>
          <tr>
            <th>Date</th>
            <RenderIf isTrue={entityType === "owner"}>
              <th>Pet</th>
            </RenderIf>
            <th>Type</th>
            <th>Location</th>
            <th>Veterinarian</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <RenderIf isTrue={visitsCount == 0}>
            <tr>
              <td>
                <p>{getEmptyMessage(entityType)}</p>
              </td>
            </tr>
          </RenderIf>
          {visits.map((visit, index) => (
            <tr key={`visit-${index}`}>
              <td>
                { visit.visitDate }
              </td>
              <RenderIf isTrue={entityType === "owner"}>
                <td>
                  { visit.pet.name }
                </td>
              </RenderIf>
              <td>
                { visit.visitType }
              </td>
              <td>
                { visit.locationName }
              </td>
              <td>
                { visit.vet }
              </td>
              <td>
                <a href={ visit.links.show }>
                  { visit.visitReference }
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
};

Visits.propTypes = {
  entityId: number.isRequired,
  entityType: string.isRequired,
  tabState: array.isRequired,
};

export default Visits;
