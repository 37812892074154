import EnterFulfillment from "../EnterFulfillment";
import React from "react";
import { titleize } from "../../../../../utils/string";
import ViewDetails from "../ViewDetails";

export const modalStates = (prescription, closeModal, executeReload, setExecuteReload) => (
  {
    view: <ViewDetails prescription={prescription} closeModal={closeModal} />,
    enterFulfillment: <EnterFulfillment
                        prescription={prescription}
                        closeModal={closeModal}
                        setExecuteReload={setExecuteReload}
                        executeReload={executeReload}
                      />,
  }
);

export const anyCurrentPrescriptions = (prescriptions) => {
  if (prescriptions) {
    return Boolean(prescriptions.find((prescription) => (prescription.currentPrescription == true)))
  }
};

export const canCreateOrder = (prescriptions, permissions) => {
  return Boolean(anyCurrentPrescriptions(prescriptions) && permissions.canCreateOrderForPrescription)
};

export const reselectTreatment = (prescription) => {
  window.location = prescription.links.reselectTreatment;
};

export const prescriptionOrderStatus = ({ status, paymentStatus }) => {
  if (status && paymentStatus) {
    `${titleize(status)} / ${titleize(paymentStatus)}`
  }
};
