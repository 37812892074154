import { values } from "ramda";
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";

export const useGetApi = ({ get, id, params, reload }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const deps = values(params);

  const getData = useCallback(async () => {
    setLoading(true);
    const data = await get(id, params);

    setData(data);
    setLoading(false);
  }, [...deps]);

  useEffect(() => {
    getData();
  }, [...deps, reload]);

  return [data, loading, getData];
};
