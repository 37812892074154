import React from "react";
import { object } from "prop-types";
import { useFormik } from "formik";
import { api } from "../../../api";
import { Button, ButtonContexts } from "../../../shared/Button";
import { Form } from "../Form";
import { formValuesToApi } from "../utils";

const EditRabiesLot = ({ rabiesLot }) => {
  const mode =
    rabiesLot.data.attributes.lotExpirationDate === "1970-01-01"
      ? "special-edit"
      : "standard-edit";
  const initialValues = {
    isActive: rabiesLot.data.attributes.isActive,
    lotExpirationDate: rabiesLot.data.attributes.lotExpirationDate,
    lotNumber: rabiesLot.data.attributes.lotNumber,
    lotRelevanceEnd: rabiesLot.data.attributes.lotRelevanceEnd,
    lotRelevanceStart: rabiesLot.data.attributes.lotRelevanceStart,
    productId: rabiesLot.data.attributes.productId.toString(),
    rigCodeId: rabiesLot.data.attributes.rigCodeId.toString(),
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const response = await api.rabiesLot.update(
        rabiesLot.data.id,
        formValuesToApi(values)
      );
      window.flash(response?.notice);
      window.location.replace(response?.redirect_to);
    },
  });

  return (
    <div className="row">
      <div className="span12">
        <div className="page-header">
          <div className="btn-group pull-right">
            <a className="btn" href={rabiesLot.data.links.show}>
              Show Details
            </a>
            <a className="btn" href={rabiesLot.data.links.index}>
              Back to List
            </a>
          </div>
          <h1>Editing Rabies Lot</h1>
        </div>
        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
          <Form mode={mode} formik={formik} />
          <div className="form-actions">
            <Button
              context={ButtonContexts.PRIMARY}
              isSubmitting={formik.isSubmitting}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditRabiesLot.propTypes = {
  rabiesLot: object,
};

export default EditRabiesLot;
