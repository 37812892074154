import React from "react";
import { render } from "@testing-library/react";
import { Layout } from "./";

describe("Layout", () => {
  const renderComponent = () => render(<Layout />);

  test("Renders <Layout/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
