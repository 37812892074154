import React from "react";
import { object, string } from "prop-types";
import { ControlGroup } from "../../../shared/ControlGroup";
import { DateTextbox } from "../../../shared/DateTextbox";
import { LotNumber } from "./LotNumber";
import { Product } from "./Product";
import { RenderIf } from "../../../shared/RenderIf";
import { RigCode } from "./RigCode";

export const Form = ({ mode, formik }) => {
  const editing = mode === "standard-edit";

  return (
    <>
      <ControlGroup label="Rig Code" identifier="rigCodeId">
        <RigCode
          disabled={editing}
          setFieldValue={formik.setFieldValue}
          value={formik.values.rigCodeId}
        />
      </ControlGroup>
      <ControlGroup label="Product" identifier="productId">
        <Product
          disabled={editing}
          setFieldValue={formik.setFieldValue}
          value={formik.values.productId}
        />
      </ControlGroup>
      <RenderIf
        isTrue={formik.values.productId != "" && formik.values.rigCodeId != ""}
      >
        <ControlGroup label="Lot Number" identifier="lotNumber">
          <LotNumber
            disabled={editing}
            mode={mode}
            key={formik.values.productId}
            productId={formik.values.productId}
            rigCodeId={formik.values.rigCodeId}
            setFieldValue={formik.setFieldValue}
            value={formik.values.lotNumber}
          />
          <RenderIf isTrue={formik.initialValues.lotNumber != "" && !editing}>
            <span className="fontred">
              Current: {formik.initialValues.lotNumber}
            </span>
          </RenderIf>
        </ControlGroup>
        <ControlGroup
          label="Lot Expiration Date"
          identifier="lotExpirationDate"
        >
          <DateTextbox
            disabled={true}
            field="lotExpirationDate"
            onChange={formik.setFieldValue}
            value={formik.values.lotExpirationDate}
          />
        </ControlGroup>
      </RenderIf>
      <ControlGroup label="Lot Relevance Start" identifier="lotRelevanceStart">
        <DateTextbox
          field="lotRelevanceStart"
          onChange={formik.setFieldValue}
          value={formik.values.lotRelevanceStart}
        />
      </ControlGroup>
      <ControlGroup label="Lot Relevance End" identifier="lotRelevanceEnd">
        <DateTextbox
          field="lotRelevanceEnd"
          onChange={formik.setFieldValue}
          value={formik.values.lotRelevanceEnd || ""}
        />
      </ControlGroup>
      <ControlGroup label="Is Active" identifier="isActive">
        <input
          className="checkbox__input"
          name="isActive"
          onChange={(e) => {
            formik.setFieldValue("isActive", e.target.checked);
          }}
          type="checkbox"
          value={formik.values.isActive.toString()}
          checked={formik.values.isActive}
        />
      </ControlGroup>
    </>
  );
};

Form.propTypes = {
  formik: object.isRequired,
  mode: string.isRequired,
};
