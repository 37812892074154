import cx from "classnames";
import React, { useEffect, useState } from "react";
import { RenderIf } from "../../../shared/RenderIf";
import { eventEmitter } from "../../../utils/eventEmitter";

const closeGlyph = "\u2715";

const Flash = () => {
  let [visibility, setVisibility] = useState(false);
  let [message, setMessage] = useState("");
  let [title, setTitle] = useState("");
  let [type, setType] = useState("");

  useEffect(() => {
    eventEmitter.addListener("flash", ({ message, type, title }) => {
      setVisibility(true);
      setMessage(message);
      setType(type);
      setTitle(title);
      setTimeout(() => {
        setVisibility(false);
      }, 4000);
    });
  }, []);

  const renderMessage = (message) => {
    if (Array.isArray(message)) {
      return (
        <ul>
          {message.map((message, index) => (
            <li key={`alert-message-${index}`}>{message}</li>
          ))}
        </ul>
      );
    }

    return message;
  };

  return (
    <RenderIf isTrue={visibility}>
      <div className={cx("row", "printhide", "flash__container")}>
        <div className={cx("span12", "flash__inner-container")}>
          <div className={`alert alert-${type}`}>
            <a
              className="close"
              data-dismiss="alert"
              onClick={() => setVisibility(false)}
            >
              {closeGlyph}
            </a>
            <RenderIf isTrue={Boolean(title)}>
              <h4 className="pb-2">{title}</h4>
            </RenderIf>
            {renderMessage(message)}
          </div>
        </div>
      </div>
    </RenderIf>
  );
};

export default Flash;
