import { api } from "../../../../../api";
import { useEffect, useState } from "react";
import { usePostApi } from "../../../../../shared/usePostApi";

export const usePrescriptionCreate = ({
  ownerId,
  productVaccertId,
  selectedTreatmentIds,
  setReloadCurrentPendingTreatments,
  reloadCurrentPendingTreatments,
  setReloadPrescriptions,
  reloadPrescriptions
}) => {
  const [executePrescriptionCreate, setExecutePrescriptionCreate] = useState(false);
  const [prescriptionResponseStatus, setPrescriptionResponseStatus] = useState(null);
  const [prescriptionResponseType, setPrescriptionResponseType] = useState(null);
  const [prescriptionData, setPrescriptionData] = useState(null);

  const [prescriptionResponse, loading] = usePostApi({
    post: api.prescriptions.create,
    body: {
      owner_id: ownerId,
      products_vaccert_id: productVaccertId,
      selected_treatment_id: selectedTreatmentIds,
    },
    shouldExecute: executePrescriptionCreate,
  });

  useEffect(() => {
    if (executePrescriptionCreate) {
      setExecutePrescriptionCreate(false);
    }
  }, [executePrescriptionCreate]);

  useEffect(() => {
    if(prescriptionResponse == prescriptionData) return;

    if (Object.keys(prescriptionResponse).includes('alert')) {
      setPrescriptionResponseType('warning');
      setPrescriptionResponseStatus(prescriptionResponse.alert);
    } else if (Object.keys(prescriptionResponse).includes('notice')) {
      setPrescriptionResponseType('success');
      setPrescriptionResponseStatus(prescriptionResponse.notice);
    }
    setPrescriptionData(prescriptionResponse);
    setReloadCurrentPendingTreatments(!reloadCurrentPendingTreatments);
    setReloadPrescriptions(!reloadPrescriptions);
  }, [prescriptionResponse, prescriptionData]);

  useEffect(() => {
    if (!(prescriptionResponseStatus && prescriptionResponseType)) return;

    window.flash(prescriptionResponseStatus, prescriptionResponseType);
  }, [prescriptionResponseStatus, prescriptionResponseType]);

  return { setExecutePrescriptionCreate, loadingPrescriptionCreate: loading };
}
