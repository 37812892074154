import { formatTime } from "./formatTime";

describe("formatTime", () => {
  describe("when 0 minutes", () => {
    const hour = 8;
    const minute = 0;

    test("it formats the time", () => {
      expect(formatTime(hour, minute)).toBe("8:00 AM");
    });
  });

  describe("before noon", () => {
    const hour = 8;
    const minute = 30;

    test("it formats the time", () => {
      expect(formatTime(hour, minute)).toBe("8:30 AM");
    });
  });

  describe("after noon", () => {
    const hour = 16;
    const minute = 30;

    test("it formats the time", () => {
      expect(formatTime(hour, minute)).toBe("4:30 PM");
    });
  });
});
