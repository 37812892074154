import React from "react";
import { render } from "@testing-library/react";
import { Alert } from "./";

describe("Alert", () => {
  const renderComponent = () => render(<Alert />);

  test("Renders <Alert/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
