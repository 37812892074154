import { renderHook } from "@testing-library/react-hooks";
import { useRecordCount } from "./";

describe("useRecordCount", () => {
  describe("with null data", () => {
    test("it returns 0", () => {
      const hook = renderHook(() => useRecordCount(null));

      expect(hook.result.current).toBe(0);
    });
  });

  describe("with no data", () => {
    test("it returns 0", () => {
      const hook = renderHook(() => useRecordCount([]));

      expect(hook.result.current).toBe(0);
    });
  });

  describe("with data", () => {
    test("it returns record count", () => {
      const hook = renderHook(() => useRecordCount([
        {
          meta: {
            recordCount: 10
          }
        }
      ]));

      expect(hook.result.current).toBe(10);
    });
  });

  describe("with missing meta data", () => {
    test("throws error", () => {
      expect(() => {
        renderHook(() => useRecordCount([{}]))
      }).toThrow(Error("Missing recordCount on meta data attribute"))
    });
  });
});
