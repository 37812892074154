import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import ProfileTestResults from "./";

describe("ProfileTestResults", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<ProfileTestResults {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have lab results", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no lab results", () => {
      const { getByText } = renderComponent();

      expect(getByText("No test results available.")).toBeTruthy();
    });
  });

  describe("when has lab results", () => {
    const profileTestResult = {
      barcode: "DX09979585",
      id: "1",
      isTestPositive: false,
      petName: "Tatum",
      profileTestId: 1,
      receivedOn: "2018-11-29",
      receivedOnFormatted: "11-29-2018",
      resultStatus: "negative",
      testNotes: null,
      updatedAt: "2020-08-24T09:07:18.081-07:00",
      links: {
        showProfileTest: "http://localhost:3000/profile_tests/1",
        showPet: "http://localhost:3000/pets/8"
      },
      meta: undefined
    }

    beforeEach(() => {
      const profileTestResults = [profileTestResult]
      jest.spyOn(api, "useGetApi").mockImplementation(() => [profileTestResults, false]);
    });

    test("shows the lab result", () => {
      const { getByText } = renderComponent();

      expect(getByText("DX09979585")).toBeTruthy();
    });
  });
});
