import React, { useLayoutEffect } from "react";

export function useElementStyles(selector, styles) {
  const element = document.querySelector(selector);

  useLayoutEffect(() => {
    if (element) {
      Object.assign(element.style, styles);
    }
  }, [styles]);
}
