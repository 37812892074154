import { normalizeArray, normalizeProps, snakeKeys } from "./transforms";

describe("normalizeArray", () => {
  const subject = [
    '{"data":{"id":"2","type":"vipNote","attributes":{"source_type":"Owner","source_id":11,"auto_generated":false,"note_text":"A tele-medicine note about things.\\r\\n\\r\\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique senectus et netus et malesuada fames ac turpis. Ornare arcu dui vivamus arcu felis bibendum ut. Pharetra massa massa ultricies mi quis hendrerit. Tellus rutrum tellus pellentesque eu tincidunt tortor aliquam nulla facilisi. Lorem ipsum dolor sit amet consectetur. Lobortis mattis aliquam faucibus purus in. Vitae congue eu consequat ac felis donec. Orci ac auctor augue mauris augue neque. Semper auctor neque vitae tempus. Arcu cursus euismod quis viverra. Laoreet non curabitur gravida arcu ac tortor dignissim convallis. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Ac ut consequat semper viverra nam libero justo laoreet sit. Ipsum dolor sit amet consectetur adipiscing elit duis. Proin libero nunc consequat interdum varius sit amet mattis vulputate. Neque viverra justo nec ultrices. Leo urna molestie at elementum eu. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan. Arcu felis bibendum ut tristique et egestas quis.","is_tele_health_note":true,"created_at":"08-06-2020  2:46pm PDT","source":"Matti Salokangas"},"links":{"show":"http://localhost:3000/vip_notes/2","edit":"http://localhost:3000/vip_notes/2/edit"}}}',
    '{"data":{"id":"1","type":"vipNote","attributes":{"source_type":"Owner","source_id":11,"auto_generated":false,"note_text":"This is the note.","is_tele_health_note":false,"created_at":"08-04-2020  4:24pm PDT","source":"Matti Salokangas"}}}',
  ];

  test("parses the array", () => {
    const actual = normalizeArray(subject);

    expect(actual.length).toBe(2);
  });

  test("parses the items in the array", () => {
    const actual = normalizeArray(subject);

    const [item1, item2] = actual;

    expect(item1.id).toBe("2");
    expect(item2.id).toBe("1");
  });
});

describe("normalizeProps", () => {
  describe("with links", () => {
    const subject =
      '{"data":{"id":"1","type":"vipNote","attributes":{"created_at":"2020-08-04T16:24:45.802-07:00","source_type":"Owner","source_id":11,"auto_generated":false,"note_text":"This is the note.","tele_health_note":false},"links":{"show":"http://localhost:3000/vip_notes/1","edit":"http://localhost:3000/vip_notes/1/edit"}}}';

    test("parses and flattens out data", () => {
      const actual = normalizeProps(subject);

      expect(actual.id).toEqual("1");
      expect(actual.data).toBeUndefined();
    });

    test("ensures pascal casing", () => {
      const actual = normalizeProps(subject);

      expect(actual.sourceType).toEqual("Owner");
    });

    test("includes links", () => {
      const actual = normalizeProps(subject);

      expect(actual.links.edit).toBeTruthy();
      expect(actual.links.show).toBeTruthy();
    });
  });

  describe("without links", () => {
    const subject =
      '{"data":{"id":"1","type":"vipNote","attributes":{"created_at":"2020-08-04T16:24:45.802-07:00","source_type":"Owner","source_id":11,"auto_generated":false,"note_text":"This is the note.","tele_health_note":false}}}';

    test("does not have links", () => {
      const actual = normalizeProps(subject);

      expect(actual.links).toBeFalsy();
    });
  });
});

describe("snakeKeys", () => {
  const params = {
    billingAddress: "542 some street",
    billingState: "CA",
    creditCardExpiresOnMonth: "2",
    owner: "2_123_616",
  };

  test("snake case keys", () => {
    const actual = snakeKeys(params);

    expect(actual.billing_state).toEqual("CA");
    expect(actual.billing_address).toEqual("542 some street");
    expect(actual.credit_card_expires_on_month).toEqual("2");
    expect(actual.owner).toEqual("2_123_616");
  });
});
