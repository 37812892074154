import React from "react";
import { render } from "@testing-library/react";
import * as api from "../../../shared/useGetApi";
import Callbacks from "./";

jest.mock("../../../shared/Pagination", () => ({
  Pagination: props => <x-Pagination {...props} />
}))

describe("Callbacks", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Callbacks {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when has callbacks", () => {
    const exampleCallbacks = [
      {
        callbackAt: "2020-08-28",
        callbackAtFormatted: "08-28-2020",
        id: "1",
        isPastDue: false,
        lastAction: null,
        links: {
          showPet: "http://localhost:3000/pets/11",
          showSource: "/vaccerts/1"
        },
        mailedAtFormatted: null,
        meta: {
          page: "1",
          recordCount: 2
        },
        petName: "Pet 1 name",
        productTypeName: "Frontline Promo",
        sourceDateFormatted: "07-18-2020",
        sourceNumber: "428651014-X",
        sourceType: "vaccert"
      },
      {
        callbackAt: "2020-08-30",
        callbackAtFormatted: "08-30-2020",
        id: "2",
        isPastDue: false,
        lastAction: null,
        links: {
          showPet: "http://localhost:3000/pets/11",
          showSource: "/orders/1"
        },
        mailedAtFormatted: null,
        meta: {
          page: "1",
          recordCount: 2
        },
        petName: "Pet 2 name",
        productTypeName: "System Default",
        sourceDateFormatted: "08-27-2020",
        sourceNumber: "1",
        sourceType: "order"
      },
    ];

    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [exampleCallbacks, false]);
    });

    test("shows the callbacks", () => {
      const { getByText } = renderComponent();

      expect(getByText("Pet 1 name")).toBeTruthy();
      expect(getByText("Pet 2 name")).toBeTruthy();
    });
  });
});
