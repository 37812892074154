import React, { useReducer } from "react";
import cx from "classnames";
import { api } from "../../../api";
import { useGetApi } from "../../../shared/useGetApi";
import { arrayOf, number, string } from "prop-types";
import { Loader } from "../../../shared/Loader";
import { RenderIf } from "../../../shared/RenderIf";
import { useQueryParam } from "../../../shared/useQueryParam";
import Tab from "./Tab";
import { tabConfigurations } from "./configurations";
import { hasCorrectPermissions, reducer } from "./utils";

const isActive = (tabToShow, tabId, index) => {
  if (tabToShow) {
    return `${tabId}-tab` === tabToShow;
  }

  return index === 0;
};

const defaultTabDetails = tabConfigurations.map((tab) => {
  const tabObject = {
    id: tab.id,
    count: null,
    title: tab.title,
  };
  return tabObject;
});

const TabContainer = ({ noteTypes, ownerId }) => {
  const { show_tab_name: tabToShow } = useQueryParam(location.search);
  const [permissions, loading] = useGetApi({
    get: api.owners.permissions,
    id: ownerId,
  });

  const [tabDetails, setTabDetails] = useReducer(reducer, defaultTabDetails);

  const shouldShowTab = (tab) => {
    if (!hasCorrectPermissions(permissions, tab)) {
      return false;
    }
    const foundTab = tabDetails.find((element) => element.id === tab.id);
    return tab.alwaysShow || foundTab.count === null || foundTab.count > 0;
  };

  const tabCountDisplay = (tab) => {
    const foundTab = tabDetails.find((element) => element.id === tab.id);
    return foundTab.count ? `(${foundTab.count})` : "(0)";
  };

  const tabTitle = (tab) => {
    const foundTab = tabDetails.find((element) => element.id === tab.id);
    return foundTab.title ? foundTab.title : tab.title;
  };

  const tabTitleClass = (tab) => {
    const foundTab = tabDetails.find((element) => element.id === tab.id);
    return foundTab.titleClass ? foundTab.titleClass : null;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="tab-container">
      <ul className="nav nav-tabs">
        {tabConfigurations.map((tab, index) => (
          <RenderIf isTrue={shouldShowTab(tab)} key={`tab-${index}`}>
            <Tab
              hasTitleSpan={tab.hasTitleSpan}
              id={tab.id}
              isActive={isActive(tabToShow, tab.id, index)}
              title={tabTitle(tab)}
              titleClass={tabTitleClass(tab)}
            >
              <span id={`${tab.id}-count`} className={tabTitleClass(tab)}>
                {tabCountDisplay(tab)}
              </span>
            </Tab>
          </RenderIf>
        ))}
      </ul>

      <div className={cx("tab-content", "overflow-visible")}>
        {tabConfigurations.map((Tab, index) => (
          <div
            className={cx("tab-pane", {
              active: isActive(tabToShow, Tab.id, index),
            })}
            id={`${Tab.id}-tab`}
            key={`tab-pane-${Tab.id}`}
          >
            <Tab.component
              entityId={ownerId}
              entityType={"owner"}
              ownerId={ownerId}
              tabState={[Tab.id, tabDetails, setTabDetails]}
              noteTypes={noteTypes}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

TabContainer.propTypes = {
  noteTypes: arrayOf(string).isRequired,
  ownerId: number.isRequired,
};

export default TabContainer;
