import { get, makeUrl } from "../";
import { normalizeArray } from "../../api/utils/transforms";

const petBaseUrl = "/pets";

export const api = {
  pets: {
    notes: (petId, params) =>
      get(makeUrl([petBaseUrl, "/", petId, "/notes"]), {
        params,
        transformResponse: normalizeArray,
      }),

    visits: (petId, params) =>
      get(makeUrl([petBaseUrl, "/", petId, "/visits"]), {
        params,
        transformResponse: normalizeArray,
      }),
  },
};