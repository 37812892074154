import React from "react";
import { render } from "@testing-library/react";
import ProductVaccerts from "./";

describe("ProductVaccerts", () => {
  const productVaccert = (result) => (
    {
      existingPrescriptions: false,
      id: "1",
      petName: "Chase",
      product: {
        id: "18737",
        productType: {
          name: "Test product type",
        },
      },
      profileTests: [
        {
          id: "1",
          profileTestResults: [
            {
              barcode: "8456277810",
              id: "2",
              isTestPositive: result,
              labTestCodeName: "All Other Tests Not Identified",
              testNotes: null,
            },
          ],
        },
      ],
      text1: "8456277810"
    }
  );

  const renderComponent = (props) =>
    render(<ProductVaccerts {...props} />);

  describe("with a positive test result", () => {
    test("renders the component", () => {
      const { getByText } = renderComponent({productVaccert: productVaccert(true)});

      expect(getByText("POSITIVE")).toBeTruthy();
    });
  });

  describe("with a negative test result", () => {
    test("renders the component", () => {
      const { getByText } = renderComponent({productVaccert: productVaccert(false)});

      expect(getByText("NEGATIVE")).toBeTruthy();
    });
  });
});
