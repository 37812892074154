export const defaultStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "1.75rem",
    height: "1.75rem",
  }),
  dropdownIndicator: () => ({
    color: "#555",
    svg: { height: "17px" },
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    height: "1.5rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    // width: "220px",
  }),
  option: (provided) => ({
    ...provided,
    height: "1.5rem",
    padding: "4px",
  }),
  singleValue: (provided) => ({
    ...provided,
    height: "1.5rem",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "1.75rem",
  }),
};
