export const hasCorrectPermissions = (permissions, tab) => {
  return tab.permissionAttribute ? permissions.meta.permissions[tab.permissionAttribute] : true;
};

export const reducer = (tabs, action) => {
  return tabs.map(tab => {
    switch (action.type) {
      case 'count':
        if (tab.id === action.tabId) {
          tab.count = action.count;
        }

        return tab;
      case 'title':
        if (tab.id === action.tabId) {
          tab.title = action.title;
          tab.titleClass = action.titleClass;
        }

        return tab;
      default:
        return tab;
    }
  });
};