import React from "react";
import { object } from "prop-types";
import { api } from "../../../api";
import { Button, ButtonContexts } from "../../../shared/Button";
import { Form } from "../Form";
import { formValuesToApi } from "../utils";
import { useFormik } from "formik";

const NewRabiesLot = ({ rabiesLot }) => {
  const initialValues = {
    isActive: true,
    lotExpirationDate: "",
    lotNumber: "",
    lotRelevanceEnd: "",
    lotRelevanceStart: "",
    productId: "",
    rigCodeId: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const response = await api.rabiesLot.create(formValuesToApi(values));
      window.flash(response?.notice);
      window.location.replace(response?.redirect_to);
    },
  });
  return (
    <div className="row">
      <div className="span12">
        <div className="page-header">
          <h1>New Rabies Lot</h1>
        </div>
        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
          <Form mode="new" formik={formik} />
          <div className="form-actions">
            <Button
              context={ButtonContexts.PRIMARY}
              isSubmitting={formik.isSubmitting}
              type="submit"
            >
              Create
            </Button>
            <a
              className="btn"
              style={{ marginLeft: ".3em" }}
              href={rabiesLot.data.links.index}
            >
              Cancel
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

NewRabiesLot.propTypes = {
  rabiesLot: object,
};

export default NewRabiesLot;
