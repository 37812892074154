import { camelizeKeys, isObject, isString, mapKeys } from "ramda-extension";

const defaultObject = {
  data: { attributes: {}, id: "UNKNOWN" },
};

export const normalizeProps = (input) => {
  const { attributes, id, meta, links } = parseData(input, defaultObject);

  if (attributes && isObject(attributes)) {
    Object.entries(attributes).map((items) => {
      if (isObject(items[1]) && Object.keys(items[1]).includes("data")) {
        if (Array.isArray(items[1].data)) {
          attributes[items[0]] = normalizeArray(items[1]);
        } else {
          attributes[items[0]] = normalizeProps(items[1]);
        }
      }
    });
  }

  return {
    id,
    meta: camelizeKeys(meta),
    links: camelizeKeys(links),
    ...camelizeKeys(attributes),
  };
};

export const normalizeArray = (input) =>
  parseData(input, []).map(normalizeProps);

const parseData = (input, defaultTo = null) => {
  try {
    const parsed = isString(input) ? JSON.parse(input) : input;

    return parsed.data ? parsed.data : parsed;
  } catch (e) {
    console.error("Failed to parse input", input);

    return defaultTo;
  }
};

export const snakeKeys = (values) => mapKeys(snakeCase, values);

const snakeCase = (key) => key.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
