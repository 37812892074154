import React from "react";
import { node, string } from "prop-types";

export function Layout({ children }) {
  return (
    <>
      <div className="row">
        {children}
      </div>
    </>
  );
}

export const SideGutter = ({ children }) => {
  return (
    <div className="span3">
      <div className="well">{children}</div>
    </div>
  );
};

export const Body = ({ className, title, children }) => {
  return (
    <>
      <div className={className}>
        <div className="page-header">
          <h1>{title}</h1>
        </div>
        {children}
      </div>
    </>
  );
};

export const RightSideGutter = ({ children }) => {
  return (
    <div className="span3 right_side_gutter">
      <div className="well">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: node,
};

RightSideGutter.propTypes = {
  children: node,
};

SideGutter.propTypes = {
  children: node,
};

Body.propTypes = {
  className: string,
  title: string,
  children: node,
};
