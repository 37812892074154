import React, { useState, useEffect } from "react";
import { func, number } from "prop-types";
import { api } from "@/api";
import { Checkbox } from "@/shared/Checkbox";
import { Loader } from "@/shared/Loader";
import { pluck, sum } from "ramda";
import { ErrorMessage } from "@/shared/ErrorMessage";
import { Table } from "@/shared/Table";
import { useGetApi } from "@/shared/useGetApi";
import { toDisplayDate } from "@/utils/dateFormatter";

export const UnpaidSales = ({ ownerId, setSalesTotal, setSaleUuids }) => {
  const styles = {
    textAlign: "center",
  };
  const [salesData, loadingSalesData] = useGetApi({
    get: api.owners.unpaidSales,
    id: ownerId,
  });

  const updateSaleChecked = (_value, event) => {
    const checked = event.target.checked;
    const saleID = event.target.value;

    if (checked) {
      const newSale = salesData.filter((sale) => sale.id == saleID);
      setSales([...sales, ...newSale]);
    } else {
      const filteredSales = sales.filter((sale) => sale.id != saleID);
      setSales(filteredSales);
    }
  };

  const [sales, setSales] = useState(salesData);

  const calculateSalesTotal = () => {
    const totalsToFloat = pluck("amountDue")(sales).map((total) =>
      parseFloat(total)
    );
    const salesTotal = sum(totalsToFloat);
    setSalesTotal(salesTotal);
  };

  useEffect(() => {
    setSales(salesData);
  }, [salesData]);

  useEffect(() => {
    if (sales) {
      calculateSalesTotal();
      let saleUuids = pluck("uniqueId")(sales);
      setSaleUuids(saleUuids);
    }
  }, [sales]);

  if (loadingSalesData) {
    return <Loader />;
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Sale Id</th>
            <th>Clinic Date</th>
            <th>Clinic Location</th>
            <th>Vaccert(s)</th>
            <th>Amount Due</th>
            <th>Include</th>
          </tr>
        </thead>
        <tbody>
          {salesData?.map((sale, i) => {
            const value = sale?.id?.toString();
            return (
              <tr key={i}>
                <td>{sale.id}</td>
                <td>{sale?.salesDate && toDisplayDate(sale?.salesDate)}</td>
                <td>{sale.clinicLocation}</td>
                <td>{sale.vaccert}</td>
                <td>{sale.amountDue}</td>
                <td style={styles}>
                  <Checkbox
                    onChange={updateSaleChecked}
                    defaultChecked={true}
                    value={value}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pull-right mb-2">
        {sales.length == 0 ? (
          <ErrorMessage error={"At least 1 sale is required"} />
        ) : null}
      </div>
    </div>
  );
};

UnpaidSales.propTypes = {
  ownerId: number.isRequired,
  setSalesTotal: func.isRequired,
  setSaleUuids: func.isRequired,
};
