import React from "react";
import cx from "classnames";
import { RenderIf } from "../../../../shared/RenderIf";
import { bool, node, string } from "prop-types";

const Tab = ({ 
  children, 
  hasTitleSpan, 
  id, 
  isActive, 
  title, 
  titleClass 
}) => {
  return (
    <li className={cx({ active: isActive })} key={`tab-${id}`}>
      <a href={`#${id}-tab`} id={`link-show-${id}-tab`} data-toggle="tab">
        <RenderIf isTrue={!hasTitleSpan}>
          { title }
        </RenderIf>
        <RenderIf isTrue={hasTitleSpan}>
          <span id={`${id}-title`} className={titleClass}>{ title }</span>
        </RenderIf>
        &nbsp;
        { children }
      </a>
    </li>
  );
};

Tab.propTypes = {
  children: node.isRequired,
  hasTitleSpan: bool,
  id: string.isRequired,
  isActive: bool.isRequired,
  title: string.isRequired,
  titleClass: string,
};

Tab.defaultProps = {
  hasTitleSpan: false,
  titleClass: "",
}

export default Tab;