import React from "react";
import { render } from "@testing-library/react";
import { Loader } from "./";

describe("Loader", () => {
  const defaultProps = {
    message: "MESSAGE",
    showMessage: true,
  };

  const renderComponent = (props) =>
    render(<Loader {...defaultProps} {...props} />);

  test("it shows the message", async () => {
    const { queryByText } = renderComponent();

    expect(await queryByText("MESSAGE")).toBeTruthy();
  });

  test("it can hide the message", async () => {
    const { queryByText } = renderComponent({
      showMessage: false,
    });

    expect(await queryByText("MESSAGE")).toBeFalsy();
  });
});
