import React, { useLayoutEffect } from "react";

export function useTabTitle(title, titleClass, tabState) {
  useLayoutEffect(() => {
    if (tabState) {
      const [tabId, _tabTitle, setTabTitle] = tabState;
      setTabTitle({
        type: "title",
        tabId: tabId,
        title: title,
        titleClass: titleClass,
      });
    }
  }, [title, titleClass]);
}
