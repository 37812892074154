import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import Orders from "./";

describe("Orders", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Orders {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have orders", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no orders", () => {
      const { getByText } = renderComponent();

      expect(getByText("No orders for this owner.")).toBeTruthy();
    });
  });

  describe("when has orders", () => {
    const exampleOrder = {
      id: 1,
      orderSource: "Web",
      paymentStatus: "sales_quote",
      shippingStatus: "no_shipments",
      status: "quote",
      totalCost: "$4.50",
      updatedAt: "08-27-2020",
      links: {
        show: "/orders/1"
      },
      meta: {
        page: "1",
        recordCount: 1
      }
    }

    beforeEach(() => {
      const order = [exampleOrder]
      jest.spyOn(api, "useGetApi").mockImplementation(() => [order, false]);
    });

    test("shows the order", () => {
      const { getByText } = renderComponent();

      expect(getByText(exampleOrder.orderSource)).toBeTruthy();
    });
  });
});
