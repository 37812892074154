import { formatPhoneNumber, toMoney, toNumber } from "./numberFormatter";

describe("toMoney", () => {
  describe("a positive number", () => {
    test("displays as money", () => {
      expect(toMoney(100)).toBe("$100.00");
    });
  });

  describe("a negative number", () => {
    test("displays with parenthesis", () => {
      expect(toMoney(-100)).toBe("($100.00)");
    });
  });
});

describe("toNumber", () => {
  test("add delimiters", () => {
    expect(toNumber(1000000)).toBe("1,000,000");
  });
});

describe("formatPhoneNumber", () => {
  describe("formats consecutive numbers", () => {
    test("display phone number with dashes", () => {
      expect(formatPhoneNumber(2067828410)).toBe("(206) 782-8410");
    });
  });

  describe("formats string of consecutive numbers", () => {
    test("display phone number with dashes", () => {
      expect(formatPhoneNumber("2067828410")).toBe("(206) 782-8410");
    });
  });

  describe("removes paranthesis", () => {
    test("instead replaces with dashes", () => {
      expect(formatPhoneNumber("(206) 782-8410")).toBe("(206) 782-8410");
    });
  });

  describe("removes periods", () => {
    test("instead replaces with dashes", () => {
      expect(formatPhoneNumber("206.782.8410")).toBe("(206) 782-8410");
    });
  });
});
