import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import Visits from "./";

describe("Visits", () => {
  const defaultProps = {
    entityId: 1,
    entityType: "owner",
  };

  const renderComponent = (props) =>
    render(<Visits {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have visits", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no visits", () => {
      const { getByText } = renderComponent();

      expect(getByText("No visits for this owner.")).toBeTruthy();
    });

    test("shows the correct entityType", () => {
      const { getByText } = renderComponent({ entityType: "pet" });

      expect(getByText("No visits for this pet.")).toBeTruthy();
    });
  });

  describe("when has visits", () => {
    const exampleVisit = {
      id: "17",
      locationName: "The Sanctuary Project- Shelter Training (PX) Tucson, AZ",
      pet: {
        type: "pet",
        attributes: {
          id: "1",
          name: "Brooks", 
          isDeceased: false
        },
        links: {
          show: "http://localhost:3000/pets/1"
        }
      },
      vet: "Elaina Schroeder",
      visitDate: "08-05-2020",
      visitReference: "675328594-6",
      visitType: "Community Clinic",
      links: {
        show: "http://localhost:3000/vaccerts/17"
      },
      meta: {
        page: "1",
        recordCount: 2
      }
    }

    beforeEach(() => {
      const visit = [exampleVisit]
      jest.spyOn(api, "useGetApi").mockImplementation(() => [visit, false]);
    });

    test("shows the visit", () => {
      const { getByText } = renderComponent();

      expect(getByText(exampleVisit.visitReference)).toBeTruthy();
    });
  });
});
