import React from "react";
import { node, oneOf, string } from "prop-types";

export const Form = ({ 
  action, 
  children, 
  className, 
  formId, 
  method 
}) => {
  const csrfToken = document.querySelector("meta[name=csrf-token]")?.content

  return (
    <form 
      acceptCharset="UTF-8"
      action={action}
      className={className}
      id={formId}
      method={method}
    >
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      { children }
    </form>
  );
};

Form.propTypes = {
  action: string.isRequired, 
  children: node,
  className: string, 
  formId: string.isRequired, 
  method: oneOf(['post', 'put'])
};