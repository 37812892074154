import { values } from "ramda";
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";

export const usePostApi = ({ post, id, body, params, shouldExecute }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(shouldExecute);

  const deps = [id, body, ...values(params)];

  const postData = useCallback(async () => {
    const data = await post(id, body, params);

    setData(data);
    setLoading(false);
  }, [...deps]);

  useEffect(() => {
    if (shouldExecute) {
      postData();
    }
  }, [...deps, shouldExecute]);

  return [data, loading];
};
