import React from "react";
import { render } from "@testing-library/react";
import Tab from "./";

describe("Tab", () => {
  const defaultProps = {
    children: <div data-testid="CHILD" />,
    hasTitleSpan: false,
    id: "test-id",
    isActive: true,
    title: "TEST TAB",
    titleClass: ""
  };

  const renderComponent = (props) =>
    render(<Tab {...defaultProps} {...props} />);

  test("it renders children", async () => {
    const { queryByTestId } = renderComponent();

    expect(await queryByTestId("CHILD")).toBeTruthy();
  });

  describe("title", () => {
    test("it renders title inside anchor tag", () => {
      const { getByText } = renderComponent();
      const element = getByText("TEST TAB");

      expect(element).toBeTruthy();
      expect(element.nodeName).toBe("A");
    });

    test("it includes span if hasTitleFlag prop present", () => {
      const { getByText } = renderComponent({ hasTitleSpan: true });
      const element = getByText("TEST TAB");

      expect(element).toBeTruthy();
      expect(element.nodeName).toBe("SPAN");
    });

    test("includes className if passed", () => {
      const { getByText } = renderComponent({ hasTitleSpan: true, titleClass: "test-class" });
      const element = getByText("TEST TAB");

      expect(element).toBeTruthy();
      expect(element.className).toBe("test-class");
    });
  });

  describe("active", () => {
    test("has active class if isActive", () => {
      const { getByText } = renderComponent();
      const element = getByText("TEST TAB")

      expect(element.parentElement.className).toBe("active");
    });

    test("does not have active class if not isActive", () => {
      const { getByText } = renderComponent({ isActive: false });
      const element = getByText("TEST TAB")

      expect(element.parentElement.className).not.toBe("active");
    });
  });
});