const initial = {
  balanceDue: 50,
  createdAt: "2020-08-31T14:27:55.224-07:00",
  id: "1",
  links: {
    show: "http://localhost:3000/collection_accounts/1",
  },
  meta: {},
  status: "new",
  totalDue: 50,
  totalPayments: 0,
};

export const example = (overrides) => ({
  ...initial,
  ...overrides,
});
