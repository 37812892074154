import React from 'react'
import { bool, func, string }  from 'prop-types'
import { ascend, prop, sortWith } from "ramda";
import { api } from "../../../../api";
import { Loader } from "../../../../shared/Loader";
import { Select } from "../../../../shared/Select";
import { useGetApi } from "../../../../shared/useGetApi";

export const Product = ({ disabled, setFieldValue, value }) => {
  const [products, loading] = useGetApi({
    get: api.rabiesLot.products
  });

  const productOptions = (products || []).map(product => ({ label: product.name, value: product.id }))
  const options = sortWith([ascend(prop('label'))], productOptions)

  if (loading) {
    return <Loader />;
  }
  return (
    <Select
      defaultLabel="Please Select"
      defaultValue={value}
      disabled={disabled}
      onChange={(e) => setFieldValue('productId', e.target.value)}
      options={options}
      selectedValue={value}
    />
  )
}

Product.propTypes = {
  disabled: bool.isRequired,
  setFieldValue: func.isRequired,
  value: string.isRequired
};
