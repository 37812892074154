import React from "react";
import { api } from "@/api";
import { Loader } from "@/shared/Loader";
import { useGetApi } from "@/shared/useGetApi";
import { number, string } from "prop-types";

const CollectionStatus = ({ ownerId, saleUuid }) => {
  const [salesData, loadingSalesData] = useGetApi({
    get: api.owners.unpaidSales,
    id: ownerId,
  });

  if (loadingSalesData) {
    return <Loader className="mt-2" />;
  }

  let display = false;

  if (salesData?.length > 0) {
    if (saleUuid) {
      let matchedSale = salesData.filter((sale) => {
        return sale.uniqueId == saleUuid;
      });
      if (matchedSale.length > 0) {
        display = true;
      }
    } else {
      display = true;
    }
  }

  if (display) {
    return <div className="alert alert-danger mt-2">Unpaid</div>;
  } else {
    return null;
  }
};

CollectionStatus.propTypes = {
  ownerId: number.isRequired,
  saleUuid: string.isRequired
};

export default CollectionStatus;
