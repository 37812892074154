import { array, func } from "prop-types";
import React from "react";
import { Checkbox } from "../../../../../shared/Checkbox";
import { useTreatmentSelected } from "./useTreatmentSelected";

const TreatmentsGroup = ({
  treatmentsGroup,
  pendingTreatments,
  setPendingTreatments,
}) => {
  const { setCheckedEvent } = useTreatmentSelected({ pendingTreatments, setPendingTreatments });

  const updateTreatmentChecked = (value, event) => {
    setCheckedEvent({
      treatmentProductId: event.target.name,
      checked: event.target.checked,
    });
  }

  return (
    treatmentsGroup.map((treatment) => {
      const { id: treatmentId, treatmentProduct } = treatment;
      const { id: treatmentProductId, fullName } = treatmentProduct;

      return (
        <li key={treatment.id}>
          <Checkbox
            name={treatmentProductId}
            onChange={updateTreatmentChecked}
            text={fullName}
            value={treatmentId}
            checked={treatment.checked || false}
          />
        </li>
      )
    })
  );
}

TreatmentsGroup.propTypes = {
  treatmentsGroup: array.isRequired,
  pendingTreatments: array.isRequired,
  setPendingTreatments: func.isRequired,
}

export default TreatmentsGroup;
