import { renderHook } from "@testing-library/react-hooks";
import { useTabTitle } from "./";

describe("useTabTitle", () => {

  let hook, tabState, tabDetails = null;

  beforeEach(() => {
    tabDetails = [
      {
        id: "test-id",
        title: "Test Title",
        titleClass: "test-title-class"
      },
    ];

    // Resets the call count before each test
    hook = jest.fn(value => value);

    tabState = [
      "test-id",
      tabDetails,
      hook,
    ];
  });

  test("it executes callback with correct attributes", () => {
    renderHook(() => useTabTitle("New Test Title", "new-test-title-class", tabState));

    expect(hook.mock.calls.length).toBe(1);
    expect(hook.mock.calls[0][0]).toStrictEqual({
      tabId: "test-id",
      title: "New Test Title",
      titleClass: "new-test-title-class",
      type: "title",
    });
  });
});
