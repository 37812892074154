import { groupBy, pipe, prop, sort } from "ramda";

export const checkedIds = (treatments) => {
  const checkedTreatments = treatments.filter((treatment) => (
    treatment.checked
  ));

  return checkedTreatments.map((treatment) => treatment.id);
}

export const groupBySymptoms = groupBy((treatment) => (
  treatment.symptom.id
));

const groupByProductVaccert = groupBy((treatment) => (
  treatment.purchasedProductVaccert.id
));

export const groupTreatmentsByProductVaccert = (treatments) => {
  const sorted = sortByPetName(treatments);
  return groupByProductVaccert(sorted)
};

export const treatmentForSymptom = (treatments, symptomId) => {
  return treatments.find((treatment) => (treatment.symptomId == symptomId))
};

export const sortByProductName = sort(
  pipe(
    prop("treatmentProduct"),
    prop("fullName")
  )
);

const sortByPetName = sort(
  pipe(
    prop("purchasedProductVaccert"),
    prop("petName")
  )
);
