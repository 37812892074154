import React from "react";
import { api } from "../../../../api";
import { useGetApi } from "../../../../shared/useGetApi";
import { Checkbox } from "../../../../shared/Checkbox";
import { Form } from "../../../../shared/Form";
import { Loader } from "../../../../shared/Loader";
import Modal from "../../../../shared/Modal";
import { RenderIf } from "../../../../shared/RenderIf";
import { Table } from "../../../../shared/Table";
import { number, string } from "prop-types";
import { uniqBy } from "ramda";

const getEmptyMessage = () => "No test results available.";
const distinctResults = (profileTestResults) =>
  uniqBy((result) => result.profileTestId, profileTestResults);

export const ProfileTestResultsModal = ({ className, ownerId }) => {
  const [profileTestResults, loading] = useGetApi({
    get: api.owners.profileTestResults,
    id: ownerId,
  });

  if (loading) {
    return (
      <Modal className={className} modalId="send-lab-results-modal">
        <Modal.Header>
          <h3>Send Lab Results</h3>
        </Modal.Header>
        <Modal.Body>
          <Loader />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  };

  const formAction = profileTestResults[0]?.links?.sendEmail || '';

  return (
    <Modal className={className} modalId="send-lab-results-modal">
      <Form
        className="form-horizonal"
        formId="send-lab-results-modal-form"
        action={formAction}
        method="post"
      >
        <Modal.Header>
          <h3>Send Lab Results</h3>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <RenderIf isTrue={profileTestResults.length === 0}>
                <tr>
                  <td>
                    { getEmptyMessage() }
                  </td>
                </tr>
              </RenderIf>
              {distinctResults(profileTestResults).map((result, index) =>
                <tr key={`profile-test-result-${index}`}>
                  <td>
                    <Checkbox
                      defaultChecked={false}
                      name="email_profile_test_ids[]"
                      onChange={() => {}}
                      value={result.profileTestId.toString()}
                    />
                  </td>
                  <td className="col-15">
                    { result.receivedOnFormatted }
                  </td>
                  <td className="col-10">
                    <a href={ result.links.showProfileTest }>{ result.barcode }</a>
                  </td>
                  <td className="col10">
                    <a href={ result.links.showPet }>{ result.petName }</a>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <RenderIf isTrue={profileTestResults.length > 0}>
            <input type="submit" name="commit" value="Send Email" className="btn btn-primary submit-on-condition" />
          </RenderIf>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ProfileTestResultsModal.propTypes = {
  className: string.isRequired,
  ownerId: number.isRequired,
};

export default ProfileTestResultsModal;
