import React from "react";
import { node } from "prop-types";

export const TableHeader = ({ children }) => {
  return (
    <thead>
      <tr className="font-bold">{children}</tr>
    </thead>
  );
};

TableHeader.defaultProps = {
  children: null,
};

TableHeader.propTypes = {
  children: node,
};
