import axios from "axios";
import { api as clinicLocationsApi } from "./clinicLocation";
import { api as couponsApi } from "./coupon";
import { api as ordersApi } from "./order";
import { api as ownersApi } from "./owner";
import { api as petsApi } from "./pet";
import { api as prescriptionsApi } from "./prescription";
import { api as rabiesLotApi } from "./rabiesLot";

const setAxiosDefaults = () => {
  const csrfTokenElement = document.querySelector("meta[name=csrf-token]");

  if (csrfTokenElement) {
    const csrfToken = csrfTokenElement.content;
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  }

  axios.defaults.headers.common["Accept"] = "application/json";
};

setAxiosDefaults();

const handleUnprocessableEntity = (error) => {
  if (error.response && error.response.status === 422) {
    window.flash(
      error.response.data,
      "error",
      "The following errors occurred:"
    );
  }
};

const handleServerError = (error) => {
  if (error.response && error.response.status === 500) {
    window.flash(
      "We're sorry, but something went wrong.",
      "error",
      "Internal Server Error"
    );
  }
};

export const makeUrl = (parts) => parts.join("");

export const get = async (url, options) => {
  const { data } = await axios
    .get(url, options)
    .catch((e) => handleServerError(e));

  return data;
};

export const post = async (url, body, options) => {
  const { data } = await axios
    .post(url, body, options)
    .catch(handleUnprocessableEntity)
    .catch(handleServerError);

  return data;
};

export const put = async (url, body, options) => {
  const { data } = await axios
    .put(url, body, options)
    .catch(handleUnprocessableEntity)
    .catch(handleServerError);

  return data;
};

export const remove = async (url) => axios.delete(url).catch(handleServerError);

export const api = {
  ...clinicLocationsApi,
  ...couponsApi,
  ...ordersApi,
  ...ownersApi,
  ...prescriptionsApi,
  ...rabiesLotApi,
  ...petsApi,
};
