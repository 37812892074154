import React from "react";
import * as api from "../../../../shared/useGetApi";
import { render } from "@testing-library/react";
import ProfileTestResultsModal from "./";

describe("ProfileTestResultsModal", () => {
  const className = "can-send-emails-yes";
  const ownerId = 1;

  const defaultProps = {
    className,
    ownerId,
  };

  const renderComponent = (props) =>
    render(<ProfileTestResultsModal {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have lab results", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no lab results", () => {
      const { getByText } = renderComponent();

      expect(getByText("No test results available.")).toBeTruthy();
    });
  });

  describe("when has lab results", () => {
    const profileTestResult = {
      barcode: "DX09979585",
      id: "1",
      isTestPositive: false,
      petName: "Tatum",
      profileTestId: 1,
      receivedOn: "2018-11-29",
      receivedOnFormatted: "11-29-2018",
      resultStatus: "negative",
      testNotes: null,
      updatedAt: "2020-08-24T09:07:18.081-07:00",
      links: {
        sendEmail: "http://localhost:3000/owners/1/send_lab_results",
        showProfileTest: "http://localhost:3000/profile_tests/1",
        showPet: "http://localhost:3000/pets/8"
      },
      meta: undefined
    }

    beforeEach(() => {
      const profileTestResults = [profileTestResult, profileTestResult]
      jest.spyOn(api, "useGetApi").mockImplementation(() => [profileTestResults, false]);
    });

    test("shows unique lab results", () => {
      const { getAllByText } = renderComponent();

      expect(getAllByText("DX09979585").length).toBe(1);
    });
  });
});
