import React, { useEffect, useState } from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { useGetApi } from "../../../shared/useGetApi";
import { usePostApi } from "../../../shared/usePostApi";
import { Button, ButtonContexts } from "../../../shared/Button";
import { Loader } from "../../../shared/Loader";
import { Pagination } from "../../../shared/Pagination";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useTabCount } from "../../../shared/useTabCount";
import { useTabTitle } from "../../../shared/useTabTitle";
import { useRecordCount } from "../../../shared/useRecordCount"

const getEmptyMessage = () => "No notifications for this owner.";

const getTimezone = (notifications) => notifications.length > 0 ? notifications[0].meta.timeZone : null;

const canCancelNotification = (notification) =>
  notification.meta.permissions.canCancelNotification && notificationHasPendingStatus(notification)

const notificationHasPendingStatus = (notification) => {
  let notification_statuses = notification.report.map(report => report.table.status)
  return notification_statuses.includes('Pending')
}

const getConfirmation = (message, callback) => {
  const confirmed = confirm(message);
  if (confirmed) {
    callback();
  }
}

const Notifications = ({ ownerId, tabState }) => {
  const [cancelNotificationId, setCancelNotificationId] = useState(null);
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const [canceledNotificationData, setCanceledNotificationData] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [notifications, loading] = useGetApi({
    get: api.owners.notifications,
    id: ownerId,
    params: {
      notification: pageNumber,
    },
    reload: reloadNotifications,
  });

  const [canceledNotification, _canceledNotificationLoading] = usePostApi({
    post: api.owners.cancelNotification,
    id: cancelNotificationId,
    params: {},
    shouldExecute: Boolean(cancelNotificationId),
  });

  useEffect(() => {
    if (canceledNotification != canceledNotificationData) {
      setCanceledNotificationData(canceledNotification);
      setCancelNotificationId(null);
      setReloadNotifications(!reloadNotifications);
    }
  }, [canceledNotification, canceledNotificationData]);

  let title = "Notifications";
  let titleClass = null;
  const hasActiveNotifications = notifications ? notifications.some((notification) => notification.isActive) : false;

  if (hasActiveNotifications) {
    title = "Active Notifications";
    titleClass = "error"
  }

  useTabTitle(title, titleClass, tabState);

  const notificationsCount = useRecordCount(notifications);
  useTabCount({ count: notificationsCount }, tabState);

  if (!notifications && loading) {
    return <Loader />;
  }

  return (
    <div>
      <Pagination
        startPage={pageNumber}
        label={"Notifications"}
        onPageChange={setPageNumber}
        recordCount={notificationsCount}
      />
      <Table id={"viewnotifications"}>
        <RenderIf isTrue={notifications.length > 0}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Attempts</th>
              <th className="aligncenter span1">Current Status</th>
              <th className="span3">Time</th>
              <th className="span1">Action</th>
            </tr>
          </thead>
        </RenderIf>

        <tbody>
          <RenderIf isTrue={notifications.length === 0}>
            <tr>
              <td>
                { getEmptyMessage() }
              </td>
            </tr>
          </RenderIf>
          {notifications.map((notification, index) => (
            <tr key={`notification-${index}`}>
              <td>
                <strong>{ notification.description }</strong>
                <br />
              </td>
              <td>
                { notification.report.map((report, index) => (
                    <div key={`history-${index}`}>
                      <span className={`label label-${report.table.className}`}>
                        { report.table.status.toUpperCase() }
                      </span>
                      <p>{report.table.message}</p>
                    </div>
                ))}
              </td>
              <td className="aligncenter">
                <span className={`label label-${notification.report[0].table.className}`}>
                  { notification.report[0].table.status.toUpperCase() }
                </span>
              </td>
              <td>
                { notification.report[0].table.dateTimeFormatted }
              </td>
              <td className="aligncenter">
                <RenderIf isTrue={canCancelNotification(notification)}>
                  <Button
                    className="btn-mini"
                    context={ButtonContexts.DANGER}
                    onClick={() => getConfirmation(
                      "Are you sure you want to cancel this notification?",
                      () => setCancelNotificationId(notification.id)
                    )}
                  >
                    <i className="icon-remove icon-white"></i>
                  </Button>
                </RenderIf>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <RenderIf isTrue={notifications.length > 0}>
        <div className="pull-right">
          <span className="pr-4">
            <i>
              Owner&apos;s timezone is { getTimezone(notifications) }.
            </i>
          </span>
        </div>
      </RenderIf>
    </div>
  );
};

Notifications.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
}

export default Notifications;
