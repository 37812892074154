import cx from "classnames";
import { downloadAndPrint } from "../../containerUtils";
import { array, bool, func, object } from "prop-types";
import React, { Fragment, useState } from "react";
import { Checkbox } from "../../../../../shared/Checkbox";
import { PortalModal } from "../../../../../shared/PortalModal";
import { RenderIf } from "../../../../../shared/RenderIf";
import { useModal } from "../../../../../shared/useModal";
import { modalStates, reselectTreatment } from "../utils";

const Prescription = ({
  anyCurrentFilteredPrescriptions,
  executeReload,
  permissions,
  prescription,
  selectedPrescriptionIds,
  setExecuteReload,
  updatePrescriptionIdsList,
}) => {
  const [modalState, setModalState] = useState({});
  const { open, openModal, closeModal } = useModal();

  const initializeModal = (prescription, state) => {
    setModalState(
      modalStates(
        prescription,
        closeModal,
        executeReload,
        setExecuteReload
      )[state]
    )
    openModal();
  };

  return (
    <Fragment>
      {open &&
        <PortalModal closeModal={closeModal}>
          {modalState}
        </PortalModal>
      }

      <tr key={prescription.id} className={cx("prescription-show-all", `prescription-show-${prescription.statusObject.rowClass}`)}>
        <td className={cx("aligncenter", "vertical-align-top")}>
          <div className="btn-group">
            <button className="btn btn-mini dropdown-toggle" data-toggle="dropdown">
              <i className="icon icon-list"> </i>
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <a onClick={() => initializeModal(prescription, "view")}>View Details</a>
              </li>
              <RenderIf isTrue={Boolean(permissions.canReactivateAlternateTreatments && prescription.allowsAlternateTreatmentSelection)}>
                <li>
                  <a onClick={() => { if (window.confirm('Are you sure you want to cancel this prescription and select a different product?')) reselectTreatment(prescription) } }>
                    Reselect Treatment
                  </a>
                </li>
              </RenderIf>
              <RenderIf isTrue={prescription.currentPrescription}>
                <li>
                  <a onClick={() => { if (window.confirm('A prescription can only be printed once. Are you sure you want to print the prescription?')) downloadAndPrint(prescription) } }>
                    Download & Print
                  </a>
                </li>
              </RenderIf>
              <RenderIf isTrue={Boolean(permissions.canFulfill && prescription.fulfilledAt == null && (prescription.currentPrescription || prescription.printed))}>
                <li>
                  <a onClick={() => initializeModal(prescription, "enterFulfillment")}>Enter Fulfillment</a>
                </li>
              </RenderIf>
              <li>
              </li>
            </ul>
          </div>
        </td>

        <td className="aligncenter">
          <span className={cx("label", `label-${prescription.statusObject.labelClass}`)}>
            {prescription.statusObject.status}
          </span>
        </td>

        <td className='aligncenter'>
          <a href={prescription.pet.links.show}>{prescription.pet.name}</a>
        </td>

        <td className='aligncenter'>
          {prescription.product.fullName}
        </td>

        <td className="aligncenter">
          {prescription.productQuantity * prescription.product.containerCount}
        </td>

        <td className="aligncenter">
          {prescription.refillDosesAvailable}
        </td>

        <td className="aligncenter">
          {prescription.fulfillmentNote}
        </td>

        <td className="aligncenter">
          {prescription.vetFullName}
        </td>

        <td className="aligncenter">
          {prescription.effectiveOn}
        </td>

        <RenderIf isTrue={anyCurrentFilteredPrescriptions}>
          <td className="aligncenter">
            <RenderIf isTrue={Boolean(permissions.canCreateOrderForPrescription && prescription.currentPrescription && prescription.activePharmacy)}>
              <Checkbox
                name={prescription.id}
                onChange={updatePrescriptionIdsList}
                checked={selectedPrescriptionIds.includes(prescription.id)}
                text={""}
              />
            </RenderIf>
            <RenderIf isTrue={!(permissions.canCreateOrderForPrescription && prescription.currentPrescription && prescription.activePharmacy)}>
              &nbsp;
            </RenderIf>
          </td>
        </RenderIf>
      </tr>
    </Fragment>
  )
};

Prescription.propTypes = {
  anyCurrentFilteredPrescriptions: bool.isRequired,
  executeReload: bool.isRequired,
  permissions: object.isRequired,
  prescription: object.isRequired,
  updatePrescriptionIdsList: func.isRequired,
  selectedPrescriptionIds: array.isRequired,
  setExecuteReload: func.isRequired,
}

export default Prescription;
