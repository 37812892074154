import React from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { Loader } from "../../../shared/Loader";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { TableHeader } from "../../../shared/TableHeader";
import { truncate } from "../../../utils/string";
import { descend, prop, sortBy } from "ramda";

const getEmptyMessage = () => "No test results available.";
const sortData = sortBy(descend(prop('updatedAt')));

const ProfileTestResults = ({ ownerId, tabState }) => {
  const [profileTestResults, loading] = useGetApi({
    get: api.owners.profileTestResults,
    id: ownerId
  });

  useTabCount({ collection: profileTestResults }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <Table id="viewtests">
      <TableHeader>
        <td className='span2'>Lab Received</td>
        <td>Result</td>
        <td>Barcode</td>
        <td>Pet</td>
      </TableHeader>
      <tbody>
        {renderData(profileTestResults)}
        {renderEmptyData(profileTestResults)}
      </tbody>
    </Table>
  )
};

const renderEmptyData = (profileTestResults) => {
  return (
    <RenderIf isTrue={profileTestResults.length === 0}>
      <tr>
        <td colSpan="4">
          <p>{getEmptyMessage()}</p>
        </td>
      </tr>
    </RenderIf>
  )
};

const renderData = (profileTestResults) => {
  return sortData(profileTestResults).map((profileTestResult, index) => (
      <tr key={`profile-test-result-${index}`}>
        <td>
          { profileTestResult.receivedOnFormatted }
        </td>
        <td>
          <strong>
            { profileTestResult.resultStatus.toUpperCase() }
          </strong>
          { " " }
          <span>
            { truncate(profileTestResult.testNotes) }
          </span>
        </td>
        <td>
          <a href={ profileTestResult.links.showProfileTest }>
            { profileTestResult.barcode }
          </a>
        </td>
        <td>
          <a href={ profileTestResult.links.showPet }>
            { profileTestResult.petName }
          </a>
        </td>
      </tr>
    )
  )
};

ProfileTestResults.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default ProfileTestResults;
