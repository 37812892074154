import React from "react";
import cx from "classnames";
import { bool, func, shape, string } from "prop-types";
import { Button, ButtonContexts } from "../../../shared/Button";

export const PaginationButtonContexts = {
  NEXT: {
    className: "next next_page",
    label: "\u2192",
  },
  PREV: {
    className: "prev previous_page",
    label: "\u2190",
  },
};

export const PaginationButton = ({ context, label, isDisabled, onClick }) => {
  const contextClassNames = context ? context.className : "";

  return (
    <li
      className={cx(
        {
          "pagination-button__disabled": isDisabled,
        },
        contextClassNames
      )}
    >
      <a>
        <Button
          className={cx("pagination-button", {
            "pagination-button__disabled": isDisabled,
          })}
          disabled={isDisabled}
          context={ButtonContexts.LINK}
          onClick={() => onClick()}
          role={"pagination-button"}
        >
          {context ? context.label : label}
        </Button>
      </a>
    </li>
  );
};

PaginationButton.defaultProps = {
  label: "0",
  isDisabled: false,
};

PaginationButton.propTypes = {
  context: shape({
    className: string,
    label: string,
  }),
  label: string,
  isDisabled: bool,
  onClick: func.isRequired,
};
