import {
  formValuesToApi,
  toAMPM,
  toCivilianTime,
  toMilitaryTime,
} from "./transforms";

describe("transforms", () => {
  describe("formValuesToApi", () => {
    const formValues = {
      closeAMPM: "PM",
      closeHour: 4,
      closeMinute: 0,
      dayOfWeek: 0,
      openHour: 8,
      openMinute: 0,
      openAMPM: "AM",
    };

    it("converts values to api format", () => {
      expect(formValuesToApi(formValues)).toEqual({
        close_hour: 16,
        close_minute: 0,
        day: 0,
        open_hour: 8,
        open_minute: 0,
      });
    });
  });

  describe("toAMPM", () => {
    describe("when null", () => {
      expect(toAMPM(null)).toBe("");
    });

    describe("when before noon", () => {
      expect(toAMPM(1)).toBe("AM");
    });

    describe("when noon", () => {
      expect(toAMPM(12)).toBe("PM");
    });

    describe("when after noon", () => {
      expect(toAMPM(18)).toBe("PM");
    });
  });

  describe("toCivilianTime", () => {
    describe("when null", () => {
      expect(toCivilianTime(null)).toBe("");
    });

    describe("when before noon", () => {
      expect(toCivilianTime(10)).toBe(10);
    });

    describe("when noon", () => {
      expect(toCivilianTime(12)).toBe(12);
    });

    describe("when after noon", () => {
      expect(toCivilianTime(24)).toBe(12);
    });
  });

  describe("toMilitaryTime", () => {
    describe("when null", () => {
      expect(toMilitaryTime(null, null)).toBe("");
    });

    describe("when PM", () => {
      const period = "PM";

      it("converts to military time", () => {
        const hour = 6;

        expect(toMilitaryTime(hour, period)).toBe(18);
      });

      it("converts to strings to int", () => {
        const hour = "8";

        expect(toMilitaryTime(hour, period)).toBe(20);
      });
    });

    describe("when AM", () => {
      const period = "AM";

      it("converts to military time", () => {
        const hour = 6;

        expect(toMilitaryTime(hour, period)).toBe(6);
      });
    });
  });
});
