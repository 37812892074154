import { api } from "../../../../api";
import cx from "classnames";
import { filterPrescriptions } from "../containerUtils";
import Prescription from "./Prescription";
import { bool, func, number } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonContexts } from "../../../../shared/Button";
import { Checkbox } from "../../../../shared/Checkbox";
import { Loader } from "../../../../shared/Loader";
import { Pagination } from "../../../../shared/Pagination";
import { RenderIf } from "../../../../shared/RenderIf";
import { Table } from "../../../../shared/Table";
import { useGetApi } from "../../../../shared/useGetApi";
import { useRecordCount } from "../../../../shared/useRecordCount";
import { TableHeaderLabels } from "../TableHeaderLabels";
import { usePostOrderForPrescriptions } from "./usePostOrderForPrescriptions";
import { anyCurrentPrescriptions, canCreateOrder } from "./utils";

const Prescriptions = ({ ownerId, updateTotalCounts, reloadPrescriptions, setReloadPrescriptions }) => {
  const [hideInactivePrescriptions, setHideInactivePrescriptions] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    updatePrescriptionIdsList,
    selectedPrescriptionIds,
    setExecuteCreateOrder,
    loadingOrderCreate,
  } = usePostOrderForPrescriptions({ ownerId });

  const [prescriptions, loading] = useGetApi({
    get: api.owners.prescriptions,
    id: ownerId,
    params: {
      prescription: pageNumber,
    },
    reload: reloadPrescriptions,
  });

  const prescriptionsCount = useRecordCount(prescriptions);

  useEffect(() => {
    updateTotalCounts({ prescriptionsCount });
  }, [prescriptionsCount]);

  if (!prescriptions || loading || loadingOrderCreate) {
    return <Loader message="Loading Prescriptions..." />;
  }

  const filteredPrescriptions = filterPrescriptions(
    { hideInactivePrescriptions },
    prescriptions
  );

  const anyCurrentFilteredPrescriptions = anyCurrentPrescriptions(
    filteredPrescriptions
  );
  const currentColSpan = anyCurrentFilteredPrescriptions ? "10" : "9";

  const permissions =
    prescriptions[0] && prescriptions[0].meta
      ? prescriptions[0].meta.permissions
      : {};

  return (
    <Fragment>
      <Pagination
        startPage={pageNumber}
        label={"Prescriptions"}
        onPageChange={setPageNumber}
        recordCount={prescriptionsCount}
      />

      <Table>
        <thead>
          <tr>
            <th colSpan={currentColSpan}>
              <div
                className={cx("pull-right", "prescriptions-container__header")}
              >
                <RenderIf
                  isTrue={canCreateOrder(filteredPrescriptions, permissions)}
                >
                  <Button
                    disabled={selectedPrescriptionIds.length == 0}
                    onClick={() => setExecuteCreateOrder(true)}
                    context={ButtonContexts.PRIMARY}
                  >
                    Create Order for Prescriptions
                  </Button>
                </RenderIf>
              </div>
              <h3>Prescriptions</h3>
            </th>
          </tr>
          <tr>
            <th colSpan={currentColSpan}>
              <div className="prescriptions-container__checkbox">
                <Checkbox
                  onChange={setHideInactivePrescriptions}
                  checked={hideInactivePrescriptions}
                  text={"Hide Inactive Prescriptions"}
                />
              </div>
            </th>
          </tr>
          <tr>
            <TableHeaderLabels />
            <RenderIf isTrue={anyCurrentFilteredPrescriptions}>
              <th className="span1 aligncenter">Select</th>
            </RenderIf>
          </tr>
        </thead>

        <tbody className="no-hover">
          {filteredPrescriptions.map((prescription) => {
            return (
              <Prescription
                anyCurrentFilteredPrescriptions={
                  anyCurrentFilteredPrescriptions
                }
                key={prescription.id}
                permissions={permissions}
                prescription={prescription}
                selectedPrescriptionIds={selectedPrescriptionIds}
                updatePrescriptionIdsList={updatePrescriptionIdsList}
                executeReload={reloadPrescriptions}
                setExecuteReload={setReloadPrescriptions}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

Prescriptions.propTypes = {
  ownerId: number.isRequired,
  updateTotalCounts: func.isRequired,
  reloadPrescriptions: bool.isRequired,
  setReloadPrescriptions: func.isRequired,
};

export default Prescriptions;
