import { makeUrl, post } from "../";

const prescriptionBaseUrl = "/prescriptions";

export const api = {
  prescriptions: {
    create: (_prescriptionId, body, params) =>
      post(
        prescriptionBaseUrl,
        body,
        {
          params,
        }
      ),
    fulfill: (prescriptionId, body) =>
      post(makeUrl([prescriptionBaseUrl, "/", prescriptionId, "/fulfill"]), body)
  }
};
