import React, { useState } from "react";
import { number } from "prop-types";
import { Alert } from "@/shared/Alert";
import { AlertProvider } from "@/contexts/alertContext";
import { AssociatedPets } from "@/shared/AssociatedPets";
import { BillingAddressBook } from "@/shared/BillingAddressBook";
import { Body, Layout, RightSideGutter, SideGutter } from "@/shared/Layout";
import { OwnerInfo } from "@/shared/OwnerInfo";
import { PaymentMethod } from "@/shared/PaymentMethod";
import { UnpaidSales } from "./UnpaidSales";
import { UnpaidSalesTotals } from "./UnpaidSalesTotals";

const UnpaidSalesPayment = ({ ownerId }) => {
  const [billingAddress, setBillingAddress] = useState({});
  const [salesTotal, setSalesTotal] = useState();
  const [saleUuids, setSaleUuids] = useState([]);
  const [paymentTotal, setPaymentTotal] = useState(0);

  return (
    <AlertProvider>
      <Layout>
        <Alert />
        <SideGutter>
          <OwnerInfo ownerId={ownerId} />
          <AssociatedPets ownerId={ownerId} />
        </SideGutter>
        <Body
          className="span9"
          ownerId={ownerId}
          title="Unpaid Sale(s) Payment"
        >
          <UnpaidSales
            ownerId={ownerId}
            setSalesTotal={setSalesTotal}
            setSaleUuids={setSaleUuids}
          />
        </Body>
        <Body className="span6">
          <PaymentMethod
            billingAddress={billingAddress}
            saleUuids={saleUuids}
            ownerId={ownerId}
            salesTotal={paymentTotal}
            setBillingAddress={setBillingAddress}
          />
        </Body>
        <RightSideGutter>
          <UnpaidSalesTotals
            key={salesTotal}
            salesTotal={salesTotal}
            setPaymentTotal={setPaymentTotal}
          />
        </RightSideGutter>
        <RightSideGutter>
          <BillingAddressBook
            handleChange={setBillingAddress}
            ownerId={ownerId}
          />
        </RightSideGutter>
      </Layout>
    </AlertProvider>
  );
};

export default UnpaidSalesPayment;

UnpaidSalesPayment.propTypes = {
  ownerId: number.isRequired,
};
