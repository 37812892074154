import * as containerUtils from "../containerUtils";
import React from "react";
import * as api from "../../../../shared/useGetApi";
import { render } from "@testing-library/react";
import * as utils from "./utils";
import Prescriptions from "./";

describe("Prescriptions", () => {
  const ownerId = 1;
  const updateTotalCounts = jest.fn();

  const defaultProps = {
    ownerId,
    updateTotalCounts,
  };

  const renderComponent = (props) =>
    render(<Prescriptions {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading Prescriptions...")).toBeTruthy();
    });
  });

  describe("when does not have prescriptions", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("still renders the container", () => {
      const { queryByText } = renderComponent();

      expect(queryByText("Prescriptions")).toBeTruthy();
    });
  });

  describe("when has prescriptions", () => {
    const examplePrescription = {
      activePharmacy: true,
      allowsAlternateTreatmentSelection: false,
      currentPrescription: false,
      dosageInstructions: "Give one dose per 3 months or use as directed.",
      effectiveOn: "08-28-2020",
      expiresOn: "08-29-2020",
      fulfilledAt: "2020-09-02T12:40:21.804-07:00",
      fulfilledAtClinic: false,
      fulfilledBy: "System Account VIP Pet Records",
      fulfillmentNote: null,
      id: "2",
      identifier: "22-2",
      links: {
        reselectTreatment: "http://localhost:3000/prescriptions/2/reactivate_alternate_treatments",
        printPrescription: "http://localhost:3000/prescriptions/2/print?owner_id=7"
      },
      meta: {
        permissions: {
          canCreateOrderForPrescription: true,
          canFulfill: true,
          canReactivateAlternateTreatments: true,
        },
        currentUser: {},
        recordCount: 68,
        page: "1"
      },
      orderItem: {
        id: undefined, meta: undefined, links: undefined
      },
      owner: {
        id: "7", meta: undefined, links: undefined, name: "Deon Gerlach"
      },
      pet: {
        id: "7", meta: undefined, links: { show: "http://localhost:3000/pets/7" }, name: "Elnora", isDeceased: false
      },
      prescriptionVaccert: {
        id: "22",
        meta: undefined,
        links: undefined,
        vaccertNumber: "2020-Rx-2",
        clinic: {
          clinicLocation: {
            id: "354",
            meta: undefined,
            links: undefined,
            facilityInternalWithExternalId: "Windsor HQ"
          },
          clinicOn: "08-21-2020",
          id: "235",
          links: undefined,
          meta: undefined,
        }
      },
      printed: true,
      printedAt: "09-02-2020",
      printedBy: "System Account VIP Pet Records",
      product: {
        id: "11958",
        meta: undefined,
        links: undefined,
        containerCount: "1.0",
        fullName: "Bravecto - 4.4-9.9lbs Yellow - 3 Months",
        productType: {
          id: "345",
          links: undefined,
          meta: undefined,
          name: "Bravecto",
        }
      },
      productQuantity: 2,
      refillDosesAvailable: 0,
      statusMessages: [],
      statusObject: {
        status: "OPEN", labelClass: "success", alertClass: "success", rowClass: "active"
      },
      updatedAt: "09-02-2020  8:31pm PDT",
      vendorExternalId: null,
      vetFullName: "Esperanza Reichert",
    };

    beforeEach(() => {
      const prescriptions = [examplePrescription];
      jest.spyOn(api, "useGetApi").mockImplementation(() => [prescriptions, false]);

      jest.spyOn(containerUtils, "filterPrescriptions").mockImplementation(() => prescriptions);
    });

    test("shows the prescriptions", () => {
      const { getByText } = renderComponent();

      expect(getByText("Prescriptions")).toBeTruthy();
      expect(getByText("Elnora")).toBeTruthy();
    });

    describe("with a current prescription and create order permissions", () => {
      beforeEach(() => {
        jest.spyOn(utils, "anyCurrentPrescriptions").mockImplementation(() => true);
        jest.spyOn(utils, "canCreateOrder").mockImplementation(() => true);
      });

      test("shows the create order form", () => {
        const { getByText } = renderComponent();

        expect(getByText("Create Order for Prescriptions")).toBeTruthy();
      });
    });
  });
});
