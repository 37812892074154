import { api } from "../../../api";
import { bool, func, number } from "prop-types";
import React, { useState } from "react";
import { Loader } from "../../../shared/Loader";
import { RenderIf } from "../../../shared/RenderIf";
import { useGetApi } from "../../../shared/useGetApi";
import Treatments from "./Treatments";
import {
  groupTreatmentsByProductVaccert,
} from "./utils";


const CurrentPendingTreatments = ({ ownerId, reloadPrescriptions, setReloadPrescriptions }) => {
  const [reloadCurrentPendingTreatments, setReloadCurrentPendingTreatments] = useState(false)

  const [currentPendingTreatments, loading] = useGetApi({
    get: api.owners.currentPendingTreatments,
    id: ownerId,
    reload: reloadCurrentPendingTreatments
  });

  if (!currentPendingTreatments && loading) {
    return <Loader message="Loading Current Pending Treatments..." />;
  }

  const groupedTreatmentsByProductVaccert = groupTreatmentsByProductVaccert(currentPendingTreatments);

  return (
    <RenderIf isTrue={currentPendingTreatments.length > 0}>
      <div>
        <h3>Select Pending Treatments for Positive Tests</h3>
      </div>
      {Object.entries(groupedTreatmentsByProductVaccert).map((treatmentGroup, index) => {
        const treatments = treatmentGroup[1];

        return(
          <Treatments
            key={`group-${index}`}
            treatments={treatments}
            ownerId={ownerId}
            setReloadCurrentPendingTreatments={setReloadCurrentPendingTreatments}
            reloadCurrentPendingTreatments={reloadCurrentPendingTreatments}
            reloadPrescriptions={reloadPrescriptions}
            setReloadPrescriptions={setReloadPrescriptions}
          />
        )
      })}
    </RenderIf>
  );
};

CurrentPendingTreatments.propTypes = {
  ownerId: number.isRequired,
  reloadPrescriptions: bool.isRequired,
  setReloadPrescriptions: func.isRequired,
};

export default CurrentPendingTreatments;
