import { renderHook } from "@testing-library/react-hooks";
import { usePostApi } from ".";

describe("usePostApi", () => {
  const post = jest
    .fn()
    .mockName("post")
    .mockResolvedValue([
      {
        id: "ID",
      },
    ]);
  const id = "ID";
  const body = null;
  const params = {};

  describe("shouldExecute is false", () => {
    test("does not call the api", () => {
      const hook = renderHook(() =>
        usePostApi({
          post,
          id,
          body,
          params,
          shouldExecute: false,
        })
      );

      const [_data, loading] = hook.result.current;

      expect(loading).toBe(false);
      expect(post).not.toBeCalled();
    });
  });

  describe("shouldExecute is true", () => {
    test("calls the provided api", () => {
      const hook = renderHook(() =>
        usePostApi({
          post,
          id,
          body,
          params,
          shouldExecute: true,
        })
      );

      const [_data, loading] = hook.result.current;

      expect(loading).toBe(true);
      expect(post).toBeCalledWith(id, body, params);

      hook.waitForNextUpdate();
    });

    test("posts data", (done) => {
      const hook = renderHook(() =>
        usePostApi({
          post,
          id,
          body,
          params,
          shouldExecute: true,
        })
      );

      hook.waitForNextUpdate().then(() => {
        const [data, loading] = hook.result.current;

        expect(loading).toBe(false);
        expect(data).toEqual([
          {
            id: "ID",
          },
        ]);
        done();
      });

      hook.rerender({
        post,
        id,
        body,
        params,
        shouldExecute: true,
      });
    });
  });
});
