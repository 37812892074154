import React from "react";
import { render, screen } from "@testing-library/react";
import { ControlGroup } from "./";

describe("ControlGroup", () => {
  const defaultProps = {
    children: <div data-testid="CHILD" />,
    identifier: "IDENTIFIER",
    label: "LABEL",
  };

  const renderComponent = (props) =>
    render(<ControlGroup {...defaultProps} {...props} />);

  describe("when one child", () => {
    test("it renders the label", async () => {
      renderComponent();

      const label = await screen.getByText("LABEL");

      expect(label).toBeTruthy();
      expect(label.getAttribute("for")).toBe("IDENTIFIER");
    });

    test("it renders the children", async () => {
      renderComponent();

      const child = await screen.queryByTestId("CHILD");

      expect(child).toBeTruthy();
      expect(child.getAttribute("name")).toBe("IDENTIFIER");
      expect(child.getAttribute("id")).toBe("IDENTIFIER");
    });
  });

  describe("when more than one child", () => {
    const children = (
      <React.Fragment>
        <div data-testid="CHILD-1" />
        <div data-testid="CHILD-2" />
      </React.Fragment>
    );

    test("it renders the label", async () => {
      renderComponent({ children });

      const label = await screen.getByText("LABEL");

      expect(label).toBeTruthy();
      expect(label.getAttribute("for")).toBe("IDENTIFIER");
    });

    test("it renders the children", async () => {
      renderComponent({ children });

      const child1 = await screen.queryByTestId("CHILD-1");
      const child2 = await screen.queryByTestId("CHILD-2");

      expect(child1).toBeTruthy();
      expect(child2).toBeTruthy();
    });
  });
});
