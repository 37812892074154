import React from "react";
import { render } from "@testing-library/react";
import { PaginationButton, PaginationButtonContexts } from "./";

describe("PaginationButton", () => {
  const defaultProps = {
    onClick: jest.fn(),
  };

  const renderComponent = (props) =>
    render(<PaginationButton {...defaultProps} {...props} />);

  test("it displays correct label", async () => {
    const { findByText } = renderComponent({ label: "5" });
    
    expect(await findByText('5')).toBeTruthy;
  });

  describe("disabled", () => {
    test("it is disabled if button isDisabled", async () => {
      const { findByText } = renderComponent({ isDisabled: true, label: "1" });
      const button = await findByText('1');
      
      expect(button.disabled).toBeTruthy;
    });
  });

  describe("next/prev buttons", () => {
    test("next renders correctly", async () => {
      const { findByRole } = renderComponent({ context: PaginationButtonContexts.NEXT });
      const button = await findByRole("pagination-button");
      const a = button.parentElement;
      const li = a.parentElement;

      expect(li.className).toBe("next next_page");
      expect(a).toBeTruthy;
      expect(button.textContent).toBe("\u2192");
    });

    test("prev renders correctly", async () => {
      const { findByRole } = renderComponent({ context: PaginationButtonContexts.PREV });
      const button = await findByRole("pagination-button");
      const a = button.parentElement;
      const li = a.parentElement;

      expect(li.className).toBe("prev previous_page");
      expect(a).toBeTruthy;
      expect(button.textContent).toBe("\u2190");
    });
  });
});
