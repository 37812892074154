import { capitalizeFirst } from "ramda-extension";

export const titleize = (text) => {
  let words = [];

  text.split(/[\s_]/).forEach((fragment) => {
    let capitalWords = splitOnCapital(fragment).map((word) =>
      capitalizeFirst(word)
    );
    words.push.apply(words, capitalWords);
  });

  return words.join(" ");
};
export const splitOnCapital = (phrase) => {
  let words = [];
  let word = "";

  for (let index in phrase) {
    const char = phrase.charAt(index);
    const isNextUpperCase = index > 0 && char === char.toUpperCase();
    const isLast = parseInt(index) + 1 === phrase.length;

    if (isNextUpperCase) {
      words.push(word);
      word = "";
    }

    word += char;

    if (isLast) {
      words.push(word);
    }
  }

  return words;
};

export const truncate = (value, maxLength = 40, omission = "...") => {
  if (!value || value.length <= maxLength) {
    return value;
  }

  return `${value.slice(0, maxLength)}${omission}`;
};
