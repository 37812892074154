import React, { useState, useEffect, useCallback } from "react";
import { func, node, number, string } from "prop-types";
import { PaginationButton, PaginationButtonContexts } from "./PaginationButton";
import { RenderIf } from "../../shared/RenderIf";

const recordIntervalLabel = (currentPage, perPage, total) => {
  const startNum = (currentPage - 1) * perPage + 1;
  const endNum = currentPage * perPage > total ? total : currentPage * perPage;

  return `${startNum} - ${endNum}`;
};

export const Pagination = ({
  children,
  label,
  onPageChange,
  perPage,
  recordCount,
  startPage,
}) => {
  const [currentPage] = useState(startPage);
  const [recordInterval, setRecordInterval] = useState(
    recordIntervalLabel(startPage, perPage, recordCount)
  );

  useEffect(() => {
    setRecordInterval(recordIntervalLabel(currentPage, perPage, recordCount));
  }, [currentPage]);

  const totalPages = Math.ceil(recordCount / perPage);

  const boundedOnPageChange = (value) => {
    let newPage = value;

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > totalPages) {
      newPage = totalPages;
    }

    onPageChange(newPage);
  };

  const buildButtonWindow = useCallback(() => {
    let window = Array.from({ length: totalPages }, (_v, k) => k + 1).filter(
      (value) => Math.abs(currentPage - value) < 2
    );

    while (totalPages > window.length && window.length < 3) {
      if (window[0] == 1) {
        window.push(window[window.length - 1] + 1);
      } else {
        window.unshift(window[0] - 1);
      }
    }

    return window;
  }, [totalPages, currentPage]);

  const buttonWindow = buildButtonWindow();

  return (
    <div className="row">
      <RenderIf isTrue={recordCount > 0}>
        <div className="span3 pagination-centered">
          <RenderIf isTrue={totalPages > 1}>
            <div className="pt-2">
              {label} <b>{recordInterval}</b> of <b>{recordCount}</b>
            </div>
          </RenderIf>
          <RenderIf isTrue={totalPages == 1}>
            <div className="pt-2">
              <b>{recordCount > 1 ? "All" : ""} {recordCount}</b> {label}
            </div>
          </RenderIf>
        </div>
        <div className="span5 pagination-right">
          <div role="navigation" aria-label="Pagination" className="pagination">
            <RenderIf isTrue={totalPages > 1}>
              <ul className="pagination">
                <PaginationButton
                  context={PaginationButtonContexts.PREV}
                  isDisabled={currentPage == 1}
                  onClick={() => boundedOnPageChange(currentPage - 1)}
                />
                {buttonWindow.map((value) => (
                  <PaginationButton
                    key={value}
                    label={value.toString()}
                    isDisabled={value == currentPage}
                    onClick={() => boundedOnPageChange(value)}
                  />
                ))}
                <PaginationButton
                  context={PaginationButtonContexts.NEXT}
                  isDisabled={currentPage == totalPages}
                  onClick={() => boundedOnPageChange(currentPage + 1)}
                />
              </ul>
            </RenderIf>
          </div>
        </div>
      </RenderIf>
      <RenderIf isTrue={typeof children !== "undefined"}>
        <div className="span4 pagination-right">{children}</div>
      </RenderIf>
    </div>
  );
};

Pagination.defaultProps = {
  children: undefined,
  label: null,
  onPageChange: undefined,
  perPage: 30,
  recordCount: 0,
  startPage: 1,
};

Pagination.propTypes = {
  children: node,
  label: string,
  onPageChange: func,
  perPage: number,
  recordCount: number,
  startPage: number,
};
