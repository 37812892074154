import React from "react";
import styled from "styled-components";
import { number } from "prop-types";
import { format } from "date-fns";
import { api } from "@/api";
import { Loader } from "../Loader";
import { useGetApi } from "../useGetApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .category-spacing {
    display: flex;
    padding-bottom: 4px;
  }
`;

export const AssociatedPets = ({ ownerId }) => {
  const [associatedPets, loadingAssociatedPets] = useGetApi({
    get: api.owners.associatedPets,
    id: ownerId,
  });

  if (loadingAssociatedPets) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <h2>Associated Pets</h2>
      {associatedPets?.data?.map((pet) => {
        pet.attributes.pet.data.attributes.name;
        const {
          name,
          species,
          age,
          breed,
          gender,
          positive_tests,
          microchips,
        } = pet.attributes.pet.data.attributes;

        let positiveTest = positive_tests;
        if (positiveTest.length > 0) {
          positiveTest.map((test) => {
            return (test.createdAt = format(
              new Date(test.created_at),
              "MM-dd-yyyy"
            ));
          });
        }

        return (
          <dl key={pet.attributes.pet_id}>
            <a href={`/pets/${pet.attributes.pet_id}`}>
              <h3>{name}</h3>
            </a>
            <div className="category-spacing">
              <dt>
                <strong>Microchips: </strong>
              </dt>
              <span>
                {microchips.length > 0 &&
                  microchips.map((chips, i) => <dd key={i}> {chips} </dd>)}
              </span>
            </div>
            <div className="category-spacing">
              <dt>
                <strong>Species: </strong>
              </dt>
              <dd>{species.name}</dd>
            </div>

            <div className="category-spacing">
              <dt>
                <strong>Age: </strong>
              </dt>
              <dd>{age}</dd>
            </div>
            <div className="category-spacing">
              <dt>
                <strong>Gender: </strong>
              </dt>
              <dd>{gender}</dd>
            </div>

            <div className="category-spacing">
              <dt>
                <strong>Breed: </strong>
              </dt>
              <dd>{breed}</dd>
            </div>

            <div className="category-spacing">
              <dt>
                <strong>Recent Positives: </strong>
              </dt>
              <dd>{positiveTest.length}</dd>
            </div>

            <div className="category-spacing">
              <span>
                {positiveTest.map((test, i) => {
                  return (
                    <span key={i}>
                      <dt>
                        <strong>{test.createdAt}:</strong>
                      </dt>
                      <dd>{test.test_result}</dd>
                    </span>
                  );
                })}
              </span>
            </div>
          </dl>
        );
      })}
    </Wrapper>
  );
};

AssociatedPets.propTypes = {
  ownerId: number.isRequired,
};
