import { renderHook } from "@testing-library/react-hooks";
import { useQueryParam } from "./";

describe("useQueryParam", () => {
  describe("when there are params", () => {
    test("retrieves a single parameter", () => {
      const hook = renderHook(() => useQueryParam("?foo=bar"));

      const { foo } = hook.result.current;

      expect(foo).toBe("bar");
    });

    test("retrieves many parameter", () => {
      const hook = renderHook(() => useQueryParam("?foo=bar&baz=foo"));

      const { baz, foo } = hook.result.current;

      expect(foo).toBe("bar");
      expect(baz).toBe("foo");
    });
  });

  describe("when there are no params", () => {
    test("returns nothing", () => {
      const hook = renderHook(() => useQueryParam());

      const actual = hook.result.current;

      expect(actual).toStrictEqual({});
    });
  });
});
