import { renderHook } from "@testing-library/react-hooks";
import { useTabCount } from "./";

describe("useTabCount", () => {

  let hook, tabState, tabDetails = null;

  beforeEach(() => {
    tabDetails = [
      {
        id: "test-id",
        count: null,
      },
    ];

    // Resets the call count before each test
    hook = jest.fn(value => value);

    tabState = [
      "test-id",
      tabDetails,
      hook,
    ];
  });

  describe("with collections", () => {
    test("it executes callback with correct attributes for valid collection", () => {
      renderHook(() => useTabCount({ collection: ['1', '2', '3'] }, tabState));

      expect(hook.mock.calls.length).toBe(1);
      expect(hook.mock.calls[0][0]).toStrictEqual({
        count: 3,
        tabId: "test-id",
        type: "count",
      });
    });

    test("it executes callback with correct attributes for invalid collection", () => {
      renderHook(() => useTabCount({ collection: null, count: 1 }, tabState));

      expect(hook.mock.calls.length).toBe(1);
      expect(hook.mock.calls[0][0]).toStrictEqual({
        count: 1,
        tabId: "test-id",
        type: "count",
      });
    });
  });

  describe("with integers", () => {
    test("it executes callback with correct attributes given a number", () => {
      renderHook(() => useTabCount({ count: 5 }, tabState));

      expect(hook.mock.calls.length).toBe(1);
      expect(hook.mock.calls[0][0]).toStrictEqual({
        count: 5,
        tabId: "test-id",
        type: "count",
      });
    });
  });

  describe("with no data", () => {
    test("it executes callback with correct attributes if both collection and number not present", () => {
      renderHook(() => useTabCount({}, tabState));

      expect(hook.mock.calls.length).toBe(1);
      expect(hook.mock.calls[0][0]).toStrictEqual({
        count: 0,
        tabId: "test-id",
        type: "count",
      });
    });
  });
});
