import React, { useState } from "react";
import { array, arrayOf, number, string } from "prop-types";
import cx from "classnames";
import { useDebounce } from "use-debounce";
import { api } from "../../../api";
import { Button, ButtonContexts } from "../../../shared/Button";
import { Checkbox } from "../../../shared/Checkbox";
import { Flex } from "../../../shared/Flex";
import { Loader } from "../../../shared/Loader";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { RenderIf } from "../../../shared/RenderIf";
import { Select } from "../../../shared/Select";
import { Table } from "../../../shared/Table";
import { Textbox } from "../../../shared/Textbox";
import { toLabelValuePairs } from "../../../utils/toLabelValuePairs";
import { splitOnCapital } from "../../../utils/string";
import {
  MAX_NOTE_LENGTH,
  filterNotes,
  isPastDue,
  truncateNote,
  teleHealthTitle,
} from "./utils";

const getEmptyMessage = (search) =>
  search.length > 0 ? `No notes found for "${search}"` : "No notes";

const toWhiteSpaced = (items) =>
  items.map((item) => splitOnCapital(item).join(" "));

const Notes = ({ entityId, entityType, noteTypes, tabState }) => {
  const apiHandler = entityType == "owner" ? api.owners : api.pets;
  const [teleHealthOnly, setTeleHealthOnly] = useState(false);
  const [ignoreAutoGenerated, setIgnoreAutoGenerated] = useState(true);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 300);
  const [selectedNoteType, setSelectedNoteType] = useState("");

  const [notes, loading] = useGetApi({
    get: apiHandler.notes,
    id: entityId,
    params: {
      search_note_text: debouncedSearch,
    },
  });

  useTabCount({ collection: notes }, tabState);

  if (!notes && loading) {
    return <Loader />;
  }

  const filteredNotes = filterNotes(
    { teleHealthOnly, ignoreAutoGenerated, selectedNoteType },
    notes
  );

  const noteOptions = toWhiteSpaced(noteTypes);

  return (
    <Table id="viewvipnotes">
      <thead>
        <tr>
          <th colSpan="6">
            <Flex>
              <Select
                defaultLabel="All Notes"
                selectedValue={selectedNoteType}
                onChange={(e) => setSelectedNoteType(e.target.value)}
                options={toLabelValuePairs(noteOptions, noteOptions)}
              />
              <Flex className="pl-2">
                <strong className="pr-1">Search:</strong>
                <Textbox className="mb-0" onChange={setSearch} value={search} />
                <RenderIf isTrue={search.length > 0}>
                  <Button
                    context={ButtonContexts.LINK}
                    onClick={() => setSearch("")}
                  >
                    Clear
                  </Button>
                </RenderIf>
              </Flex>
              <div className="pl-2">
                <Checkbox
                  onChange={setIgnoreAutoGenerated}
                  checked={ignoreAutoGenerated}
                  text={"Ignore Auto-generated"}
                />
              </div>
              <div className="pl-2">
                <Checkbox
                  onChange={setTeleHealthOnly}
                  checked={teleHealthOnly}
                  text={"Tele-Medicine Only"}
                />
              </div>
            </Flex>
          </th>
        </tr>
        <tr>
          <th>Type</th>
          <th>Source</th>
          <th>Created On</th>
          <th>Note</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {filteredNotes.map((note, index) => (
          <tr key={`vip-note-${index}`}>
            <td width="100">{note.sourceType}</td>
            <td width="100">
              <RenderIf isTrue={note.meta.permissions.canViewSourceType}>
                <a href={note.links.showSourceType}>{note.source}</a>
              </RenderIf>
              <RenderIf isTrue={!note.meta.permissions.canViewSourceType}>
                {note.source}
              </RenderIf>
            </td>
            <td width="100">{note.createdAtFormatted}</td>
            <td>
              <div>
                {truncateNote(note.noteText)}
                <RenderIf isTrue={note.noteText.length >= MAX_NOTE_LENGTH}>
                  <a href={note.links.show}>Read more</a>
                </RenderIf>
              </div>
              <RenderIf isTrue={note.isTeleHealthNote}>
                <span className={cx("muted", { fontred: isPastDue(note) })}>
                  {teleHealthTitle(note)}
                </span>
              </RenderIf>
            </td>
            <td className="aligncenter span1">
              <a href={note.links.show}>Show</a>
            </td>
            <td className="aligncenter span1">
              <RenderIf isTrue={note.meta.permissions.canEditNonTelehealth}>
                <a href={note.links.edit}>Edit</a>
              </RenderIf>
            </td>
          </tr>
        ))}
        <RenderIf isTrue={filteredNotes.length === 0}>
          <tr>
            <td colSpan="6">
              <p>{getEmptyMessage(search)}</p>
            </td>
          </tr>
        </RenderIf>
      </tbody>
    </Table>
  );
};

Notes.propTypes = {
  entityId: number.isRequired,
  entityType: string.isRequired,
  tabState: array.isRequired,
  noteTypes: arrayOf(string).isRequired,
};

export default Notes;
