import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import History from "./";

describe("History", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<History {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have detached pets", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no detached pets", () => {
      const { getByText } = renderComponent();

      expect(getByText("No detached pets for this owner.")).toBeTruthy();
    });
  });

  describe("when has detached pets", () => {
    const exampleDetachedPet = {
      id: "1",
      petId: 1,
      ownerId: 1,
      pet: {
        type: "pet",
        id: "1",
        name: "Brooks", 
        isDeceased: false,
        links: {
          show: "http://localhost:3000/pets/1"
        },
      },
      links: {
        reattach: "http://localhost:3000/owners/1/attach?pet_id=1",
      },
      meta: undefined,
      updatedAt: "08-18-2020",
    }

    beforeEach(() => {
      const detachedPet = [exampleDetachedPet]
      jest.spyOn(api, "useGetApi").mockImplementation(() => [detachedPet, false]);
    });

    test("shows the detached pet", () => {
      const { getByText } = renderComponent();

      expect(getByText("Brooks ( detached )")).toBeTruthy();
    });
  });
});
