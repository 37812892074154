import React from "react";
import { render } from "@testing-library/react";
import { Textbox } from "./";

describe("Textbox", () => {
  const defaultProps = {
    onChange: jest.fn().mockName("onChange"),
  };

  const renderComponent = (props) =>
    render(<Textbox data-testid="textbox" {...defaultProps} {...props} />);

  test("it renders the value", async () => {
    const { queryByTestId } = renderComponent({
      value: "value",
    });

    const textbox = await queryByTestId("textbox");

    expect(textbox.value).toBe("value");
  });
});
