import React from "react";
import { bool, object } from "prop-types";
import { ControlGroup } from "../../../shared/ControlGroup";
import { RenderIf } from "../../../shared/RenderIf";
import { Select } from "../../../shared/Select";
import { ClinicLocationHours } from "../types";

const getDayName = (value) =>
  ClinicLocationHours.dayOfWeekOptions.find(
    (dayOfWeek) => dayOfWeek.value === value
  )?.label;

export const Form = ({ formik, isEditing }) => {
  return (
    <React.Fragment>
      <RenderIf isTrue={isEditing}>
        <ControlGroup label="Day of the Week" identifier="dayOfWeek">
          <div className="pt-2">{getDayName(formik.values.dayOfWeek)}</div>
        </ControlGroup>
      </RenderIf>
      <RenderIf isTrue={!isEditing}>
        <ControlGroup label="Day of the Week" identifier="dayOfWeek">
          <Select
            defaultLabel="Select a Day"
            onChange={formik.handleChange}
            options={ClinicLocationHours.dayOfWeekOptions}
            selectedValue={formik.values.dayOfWeek}
          />
        </ControlGroup>
      </RenderIf>
      <ControlGroup label="Open" identifier="openHour">
        <React.Fragment>
          <Select
            className="input-mini mr-1"
            defaultLabel="-"
            id="openHour"
            name="openHour"
            onChange={formik.handleChange}
            options={ClinicLocationHours.hourOptions}
            selectedValue={formik.values.openHour}
          />
          <Select
            className="input-mini mr-1"
            id="openMinute"
            name="openMinute"
            defaultLabel="-"
            onChange={formik.handleChange}
            options={ClinicLocationHours.minuteOptions}
            selectedValue={formik.values.openMinute}
          />
          <Select
            className="input-mini"
            id="openAMPM"
            name="openAMPM"
            defaultLabel="-"
            onChange={formik.handleChange}
            options={ClinicLocationHours.AMPMOptions}
            selectedValue={formik.values.openAMPM}
          />
        </React.Fragment>
      </ControlGroup>
      <ControlGroup label="Close" identifier="closeHour">
        <React.Fragment>
          <Select
            className="input-mini mr-1"
            defaultLabel="-"
            id="closeHour"
            name="closeHour"
            onChange={formik.handleChange}
            options={ClinicLocationHours.hourOptions}
            selectedValue={formik.values.closeHour}
          />
          <Select
            className="input-mini mr-1"
            id="closeMinute"
            name="closeMinute"
            defaultLabel="-"
            onChange={formik.handleChange}
            options={ClinicLocationHours.minuteOptions}
            selectedValue={formik.values.closeMinute}
          />
          <Select
            className="input-mini"
            id="closeAMPM"
            name="closeAMPM"
            defaultLabel="-"
            onChange={formik.handleChange}
            options={ClinicLocationHours.AMPMOptions}
            selectedValue={formik.values.closeAMPM}
          />
        </React.Fragment>
      </ControlGroup>
    </React.Fragment>
  );
};

Form.defaultProps = {
  isEditing: false,
};

Form.propTypes = {
  formik: object.isRequired,
  isEditing: bool,
};
