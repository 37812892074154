// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const toSearchObject = (entries) =>
  entries.reduce((accum, item) => {
    const [key, value] = item;
    accum[key] = value;

    return accum;
  }, {});

export const useQueryParam = (search = "") => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const entries = Array.from(searchParams.entries());

    setParams(toSearchObject(entries));
  }, [search]);

  return params;
};
