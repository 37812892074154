import axios from "axios";
import { api } from "./";

jest.mock("axios", () => ({
  defaults: {
    headers: {
      common: {}
    }
  },
  get: jest.fn().mockName("get").mockResolvedValue({
    data: [],
  }),
  put: jest.fn().mockName("put").mockResolvedValue({
    data: [],
  })
}));

describe("Pet API", () => {
  const petId = "PET_ID";

  describe("notes", () => {
    test("it calls the notes action", async () => {
      await api.pets.notes(petId, {});

      expect(axios.get).toBeCalledWith(
        `/pets/${petId}/notes`,
        expect.any(Object)
      );
    });

    test("it passes the search query along", async () => {
      const params = {
        search_note_text: "search",
      };
      await api.pets.notes(petId, params);

      expect(axios.get).toBeCalledWith(`/pets/${petId}/notes`, {
        params,
        transformResponse: expect.any(Function),
      });
    });
  });

  describe("visits", () => {
    test("it calls the visits action", async () => {
      await api.pets.visits(petId, {});

      expect(axios.get).toBeCalledWith(
        `/pets/${petId}/visits`,
        expect.any(Object)
      );
    });
  });
});