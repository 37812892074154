import React, { useEffect } from "react";
import { number, func } from "prop-types";
import { toMoney } from "@/utils/numberFormatter";
import { Loader } from "@/shared/Loader";

export const UnpaidSalesTotals = ({ salesTotal, setPaymentTotal }) => {
  
  if (salesTotal == null) {
    return <Loader />;
  }

  useEffect(() => {
    setPaymentTotal(salesTotal);
  }, []);

  const handleChange = (e) => {
    if (e.target.validity.valid) {
      setPaymentTotal(parseFloat(e.target.value));
    } else {
      e.target.value = e.target.value.replace(/[^0-9.]+/g, "");
    }
  };

  return (
    <>
      <div className="row-fluid">
        <div className="span6 fontbold">Total:</div>
        <div className="span6">{toMoney(salesTotal)}</div>
      </div>
      <div className="row-fluid">
        <div className="span6 fontbold">Payment Total:</div>
        <div className="span6">
          <div className="input-prepend">
            <span className="add-on">$</span>
            <input
              defaultValue={parseFloat(salesTotal)}
              className="input-mini"
              type="text"
              placeholder="0.00"
              pattern="^[0-9.]*$"
              onChange={handleChange}
              name="paymentTotal"
            ></input>
          </div>
        </div>
      </div>
    </>
  );
};

UnpaidSalesTotals.propTypes = {
  salesTotal: number,
  setPaymentTotal: func,
};
