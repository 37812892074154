import React from "react";
import { render } from "@testing-library/react";
import { UnpaidSalesTotals } from ".";

const defaultProps = {
  salesTotal: 0,
  setSalesTotal: () => {},
};

describe("UnpaidSalesTotals", () => {
  const renderComponent = () =>
    render(<UnpaidSalesTotals props={defaultProps} />);

  test("Renders <UnpaidSalesTotals/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
