import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import Sales from "./";

describe("Sales", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Sales {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have sales", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no sales", () => {
      const { getByText } = renderComponent();

      expect(getByText("No sales for this owner.")).toBeTruthy();
    });
  });

  describe("when has sales", () => {
    describe("not canceled, Asta POS", () => {
      const exampleSale = {
        id: "2",
        clinicFacility: "The Sanctuary Project- Shelter Training (PX)",
        isAstaPosSale: true,
        isCanceled: false,
        inCollections: true,
        saleDate: "08-20-2020",
        uniqueId: "e85acd56-267d-4479-8895-c1604208c4f4",
        links: {
          show: "/sales/2",
          printReceipt: "/sales/2/print_receipt",
        },
        meta: {
          recordCount: 10,
          page: "1",
        },
      };

      beforeEach(() => {
        const sale = [exampleSale];
        jest.spyOn(api, "useGetApi").mockImplementation(() => [sale, false]);
      });

      test("shows the sale", () => {
        const { getByText } = renderComponent();

        expect(getByText(exampleSale.clinicFacility)).toBeTruthy();
      });

      test("sale unpaid shows Unpaid", () => {
        const { getByText } = renderComponent();

        expect(getByText(/Unpaid/)).toBeTruthy();
      });

      test("shows the receipt", () => {
        const { getByText } = renderComponent();

        expect(getByText("Download Receipt")).toBeTruthy();
      });
    });

    describe("canceled", () => {
      const exampleCanceledSale = {
        id: "2",
        clinicFacility: "The Sanctuary Project- Shelter Training (PX)",
        isAstaPosSale: true,
        isCanceled: true,
        saleDate: "08-20-2020",
        uniqueId: "e85acd56-267d-4479-8895-c1604208c4f4",
        links: {
          show: "/sales/2",
          printReceipt: "/sales/2/print_receipt",
        },
        meta: {
          recordCount: 10,
          page: "1",
        },
      };

      beforeEach(() => {
        const sale = [exampleCanceledSale];
        jest.spyOn(api, "useGetApi").mockImplementation(() => [sale, false]);
      });

      test("shows the sale", () => {
        const { getByText } = renderComponent();

        expect(getByText(exampleCanceledSale.clinicFacility)).toBeTruthy();
      });

      test("sale canceled shows Canceled", () => {
        const { getByText } = renderComponent();

        expect(getByText(/Canceled/)).toBeTruthy();
      });
    });
  });
});
