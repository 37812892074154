import { api } from "../../../../../api";
import { useEffect, useState } from "react";
import { usePostApi } from "../../../../../shared/usePostApi";

export const usePostOrderForPrescriptions = ({ ownerId }) => {
  const [selectedPrescriptionIds, setSelectedPrescriptionIds] = useState([]);
  const [executeCreateOrder, setExecuteCreateOrder] = useState(false);
  const [togglePrescriptionId, setTogglePrescriptionId] = useState(null);

  const [orderData, loadingOrderCreate] = usePostApi({
    post: api.orders.createOrderForPrescriptions,
    body: {
      owner_id: ownerId,
      selected_prescription: selectedPrescriptionIds,
    },
    shouldExecute: executeCreateOrder,
  });

  useEffect(() =>{
    if (!togglePrescriptionId) return;

    const index = selectedPrescriptionIds.indexOf(togglePrescriptionId);
    const tempPrescriptionIds = selectedPrescriptionIds;

    if (index === -1) {
      tempPrescriptionIds.push(togglePrescriptionId);
    } else {
      tempPrescriptionIds.splice(index, 1);
    }

    setSelectedPrescriptionIds(tempPrescriptionIds);
    setTogglePrescriptionId(null);
  }, [togglePrescriptionId]);

  useEffect(() => {
    setExecuteCreateOrder(false);
    if (!(orderData && orderData.message)) return;

    if (orderData.url) {
      window.location = orderData.url
    } else {
      window.flash(orderData.message, orderData.type);
    }
  }, [orderData]);

  const updatePrescriptionIdsList = (value, event) => {
    setTogglePrescriptionId(event.target.name)
  };

  return { updatePrescriptionIdsList, selectedPrescriptionIds, setExecuteCreateOrder, loadingOrderCreate };
};
