import cx from "classnames";
import { bool, node, oneOf, string } from "prop-types";
import { isNil, values } from "ramda";
import React from "react";

export const ButtonContexts = {
  DEFAULT: "",
  PRIMARY: "btn-primary",
  INFO: "btn-info",
  SUCCESS: "btn-success",
  WARNING: "btn-warning",
  DANGER: "btn-danger",
  INVERSE: "btn-inverse",
  LINK: "btn-link",
};

// false positive: react/button-has-type does not handle dynamic types
// https://github.com/yannickcr/eslint-plugin-react/issues/1555
/* eslint-disable react/button-has-type */
const Button = ({
  context,
  children,
  className,
  disabled,
  isSubmitting,
  label,
  onClick,
  type,
  ...props
}) => {
  const isDisabled = disabled || isSubmitting;

  // Fixes a react bug that doesn't disable buttons inside a disabled fieldset
  // https://github.com/facebook/react/issues/7711
  const onClickProxy = (e) => {
    if (!onClick) return;

    if (e.currentTarget.matches(":disabled")) return;

    onClick(e);
  };

  return (
    <button
      aria-label={label}
      className={cx("btn", context, className)}
      type={type}
      {...props}
      disabled={isDisabled}
      onClick={onClickProxy}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: undefined,
  context: ButtonContexts.DEFAULT,
  disabled: false,
  isSubmitting: false,
  label: undefined,
  onClick: undefined,
  type: "button",
};

Button.propTypes = {
  children: node.isRequired,
  className: string,
  context: oneOf(values(ButtonContexts)),
  disabled: bool,
  isSubmitting: bool,
  label: string,
  onClick: (props, propName) => {
    const { [propName]: onClick, type } = props;

    if (type === "submit") return undefined;

    if (isNil(onClick)) {
      return new Error(`${propName} is required and was not provided`);
    }

    if (typeof onClick !== "function") {
      return new Error(
        `The provided value for ${propName} must be a function as was of type ${typeof onClick}`
      );
    }

    return undefined;
  },
  type: string,
};

export { Button };
