import { isPast, parseISO } from "date-fns";
import { toDisplayDate } from "../../../../utils/dateFormatter";

export const MAX_NOTE_LENGTH = 150;

export const filterNotes = (
  { teleHealthOnly, ignoreAutoGenerated, selectedNoteType },
  notes
) =>
  notes
    .filter((note) => Boolean(note.noteText))
    .filter((note) =>
      !teleHealthOnly ? true : note.isTeleHealthNote === teleHealthOnly
    )
    .filter((note) =>
      ignoreAutoGenerated && note.autoGenerated ? false : true
    )
    .filter((note) =>
      selectedNoteType ? note.sourceType === selectedNoteType : true
    );

export const truncateNote = (noteText) => {
  if (noteText.length >= MAX_NOTE_LENGTH) {
    return `${noteText.substring(0, MAX_NOTE_LENGTH)}...`;
  }

  return noteText;
};

export const isPastDue = ({ followUpDate, needsFollowUp }) =>
  needsFollowUp && isPast(parseISO(followUpDate));

export const teleHealthTitle = (note) => {
  const { followUpDate, needsFollowUp, teleHealthTitle } = note;
  let suffix = "";
  if (needsFollowUp) {
    if (isPastDue(note)) {
      suffix = "(Follow-Up Past Due!)";
    } else {
      suffix = `(Follow-Up on ${toDisplayDate(followUpDate)})`;
    }
  }

  return `${teleHealthTitle} ${suffix}`;
};
