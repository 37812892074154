import React from "react";
import { render } from "@testing-library/react";
import { TableHeader } from "./";

describe("TableHeader", () => {
  test("renders children", async () => {
    const table = document.createElement('table');
    const { findByText } = render(
      <TableHeader>
        <td>ONE</td>
        <td>TWO</td>
      </TableHeader>,
      {
        container: document.body.appendChild(table)
      }
    );

    expect(await findByText("ONE")).toBeTruthy();
    expect(await findByText("TWO")).toBeTruthy();
  });
});
