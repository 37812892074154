import React from "react";
import { render } from "@testing-library/react";
import Modal from "./";

describe("Modal", () => {
  const defaultChildren = (
    <div>
      <Modal.Header>
        <div data-testid="HEADER-CHILD" />
      </Modal.Header>
      <Modal.Body>
        <div data-testid="BODY-CHILD" />
      </Modal.Body>
      <Modal.Footer>
        <div data-testid="FOOTER-CHILD" />
      </Modal.Footer>
    </div>
  )
  const defaultProps = {
    children: defaultChildren,
    className: "test-modal",
    modalId: "test-modal-id"
  };

  const buttonGlyph = "\u2715";

  const renderComponent = (props) =>
    render(<Modal {...defaultProps} {...props} />);

  describe("Header", () => {
    test("renders header-specific components", async () => {
      const { queryByText } = renderComponent();

      expect(await queryByText(buttonGlyph)).toBeTruthy();
    });

    test("renders header children", async () => {
      const { queryByTestId } = renderComponent();

      expect(await queryByTestId("HEADER-CHILD")).toBeTruthy();
    });
  });

  describe("Body", () => {
    test("renders body children", async () => {
      const { queryByTestId } = renderComponent();

      expect(await queryByTestId("BODY-CHILD")).toBeTruthy();
    });
  });

  describe("Footer", () => {
    test("renders footer-specific components", async () => {
      const { queryByText } = renderComponent();

      expect(await queryByText(/Close/)).toBeTruthy();
    });

    test("renders footer children", async () => {
      const { queryByTestId } = renderComponent();

      expect(await queryByTestId("FOOTER-CHILD")).toBeTruthy();
    });
  });
});