import React from "react";
import * as api from "../../../shared/useGetApi";
import * as utils from "./utils";
import { render } from "@testing-library/react";
import CurrentPendingTreatments from "./";

describe("CurrentPendingTreatments", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId
  };

  const renderComponent = (props) =>
    render(<CurrentPendingTreatments {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading Current Pending Treatments...")).toBeTruthy();
    });
  });

  describe("when does not have currentPendingTreatments", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("does not render anything", () => {
      const { queryByText } = renderComponent();

      expect(queryByText("Select Pending Treatments for Positive Tests")).toBeFalsy();
    });
  });

  describe("when has currentPendingTreatments", () => {
    const exampleCurrentPendingTreatment = {
      id: "3",
      links: undefined,
      meta: undefined,
      purchasedProductVaccert: {
        id: "1",
        meta: undefined,
        links: undefined,
        text1: "8456277810",
        prescribableSymptomsAndTreatmentProducts: [],
        productType: {
          name: "Example product type",
        },
        product: {
          containerCount: "1.0",
          fullName: "Soothe Night Time Eye Lube Ointment 3.5gm",
          id: "18737",
          productType: {
            name: "Example product type",
          }
        },
        profileTests: [
          {
            id: "1",
            links: undefined,
            meta: undefined,
            profileTestResults: [
              {
                barcode: "8456277810",
                id: "2",
                isTestPositive: false,
                labTestCodeName: "All Other Tests Not Identified",
                meta: undefined,
                petName: "Elnora",
                profileTestId: 1,
                receivedOn: "2020-08-24",
                receivedOnFormatted: "08-24-2020",
                resultStatus: "negative",
                testNotes: null,
                updatedAt: "2020-08-24T19:32:51.464-07:00",
              }
            ]
          }
        ]
      },
      symptom: {
        id: "7",
        meta: undefined,
        links: undefined,
        name: "Heartworm",
      },
      treatmentProduct: {
        id: "0",
        meta: undefined,
        links: undefined,
        containerCount: "1.0",
        fullName: "System Default",
        productType: {
          name: "System Default",
        }
      }
    };

    beforeEach(() => {
      const currentPendingTreatments = [exampleCurrentPendingTreatment];
      jest.spyOn(api, "useGetApi").mockImplementation(() => [currentPendingTreatments, false]);

      jest.spyOn(utils, "groupTreatmentsByProductVaccert").mockImplementation(() => ({ 1: currentPendingTreatments }));
    });

    test("shows the treatment selection", () => {
      const { getByText } = renderComponent();

      expect(getByText("Select Pending Treatments for Positive Tests")).toBeTruthy();
      expect(getByText("Treatments:")).toBeTruthy();
    });
  });
});
