import React from "react";
import { render } from "@testing-library/react";
import { RenderIf } from "./";

describe("RenderIf", () => {
  const defaultProps = {
    isTrue: true,
    children: <div data-testid="CHILD" />,
  };

  const renderComponent = (props) =>
    render(<RenderIf {...defaultProps} {...props} />);

  test("it renders children when true", async () => {
    const { queryByTestId } = renderComponent({
      isTrue: true,
    });

    expect(await queryByTestId("CHILD")).toBeTruthy();
  });

  test("it does not render children when false", async () => {
    const { queryByTestId } = renderComponent({
      isTrue: false,
    });

    expect(await queryByTestId("CHILD")).toBeFalsy();
  });
});
