import { useEffect, useState } from "react";

export const useTreatmentSelected = ({ pendingTreatments, setPendingTreatments }) => {
  const [checkedEvent, setCheckedEvent] = useState({});
  const { treatmentProductId, checked } = checkedEvent;

  const findCheckedTreatmentInSymptomGroup = (treatment) => (
    pendingTreatments.find((pendingTreatment) => (
      checked &&
        pendingTreatment.treatmentProduct.id == treatmentProductId &&
        pendingTreatment.symptom.id === treatment.symptom.id
    ))
  );

  const mapUpdatedTreatments = () => (
    pendingTreatments.map((treatment) => {
      const checkedTreatmentInGroup = findCheckedTreatmentInSymptomGroup(treatment);

      return (
        treatment.treatmentProduct.id === treatmentProductId
        ? {...treatment, checked : checked}
        : {...treatment, checked : checkedTreatmentInGroup ? false : treatment.checked}
      )
    })
  );

  useEffect(() => {
    if (checkedEvent === {}) return;

    const updatedPendingTreatments = mapUpdatedTreatments();

    setPendingTreatments(updatedPendingTreatments);
  }, [checkedEvent])

  return { setCheckedEvent };
};
