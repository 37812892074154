import React from "react";
import { node, string } from "prop-types";
import { RenderIf } from "../RenderIf";

export const ControlGroup = ({ children, label, identifier }) => {
  const childrenCount = React.Children.count(children);

  return (
    <div className="control-group">
      <label className="control-label fontbold" htmlFor={identifier}>
        {label}
      </label>
      <div className="controls">
        <RenderIf isTrue={childrenCount === 1}>
          {React.cloneElement(children, { id: identifier, name: identifier })}
        </RenderIf>
        <RenderIf isTrue={childrenCount > 1}>{children}</RenderIf>
      </div>
    </div>
  );
};

ControlGroup.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
  identifier: string.isRequired,
};
