import React from "react";
import { bool, string } from "prop-types";
import { RenderIf } from "../RenderIf";

export const Loader = ({ message, showMessage }) => (
  <div className="loader__container">
    <div className="loader__spinner"></div>
    <RenderIf isTrue={showMessage}>
      <div className="loader__message">{message}</div>
    </RenderIf>
  </div>
);

Loader.defaultProps = {
  message: "Loading...",
  showMessage: true,
};

Loader.propTypes = {
  message: string,
  showMessage: bool,
};
