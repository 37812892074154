import React, { useState } from "react";
import { arrayOf, string } from "prop-types";
import {
  anyPass,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  reject,
  toPairs,
} from "ramda";
import { api } from "../../../api";

import { useGetApi } from "../../../shared/useGetApi";
import { useRecordCount } from "../../../shared/useRecordCount";

import { toYesNo } from "../../../utils/booleanFormatter";
import { toNumber } from "../../../utils/numberFormatter";
import { toDisplayDate } from "../../../utils/dateFormatter";
import { toLabelValuePairs } from "../../../utils/toLabelValuePairs";

import { Table } from "../../../shared/Table";
import { Loader } from "../../../shared/Loader";
import { Select } from "../../../shared/Select";
import { Pagination } from "../../../shared/Pagination";
import { TableHeader } from "../../../shared/TableHeader";
import { SearchSelect } from "../../../shared/SearchSelect";

const List = ({ campaigns, codes, names }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [sortColumn, setSortColumn] = useState("coupons.name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [campaign, setCampaign] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("both");
  const [filterExpirationDate, setFilterExpirationDate] = useState("available");
  const [filterVirtual, setFilterVirtual] = useState();

  const filters = {
    direction: sortDirection,
    filterCampaign: campaign,
    filterCode: code,
    filterExpirationDate: filterExpirationDate,
    filterName: name,
    filterStartDate: filterStartDate,
    filterVirtual: filterVirtual,
    page: pageNumber,
    sort: sortColumn,
  };

  const [data, loading] = useGetApi({
    get: api.coupons.get,
    params: filters,
  });

  const count = useRecordCount(data);

  if (loading) {
    return <Loader />;
  }

  const imageLink = (coupon) => (
    <a href={coupon.links.imageUrl}>
      <img src={coupon.links.imageUrl} width="150" />
    </a>
  );

  const sort = (column) => {
    if (column == sortColumn) {
      setSortDirection(sortDirection == "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc");
      setSortColumn(column);
    }
  };
  const styles = {
    whiteSpace: "nowrap",
  };

  const campaignOptions = toLabelValuePairs(campaigns, campaigns);
  const filterNameOptions = toLabelValuePairs(names, names);
  const filterCodeOptions = toLabelValuePairs(codes, codes);

  const filterExpirationDateOptions = [
    { label: "Available", value: "available" },
    { label: "Expired", value: "expired" },
    { label: "Both", value: "both" },
  ];

  const filterStartDateOptions = [
    { label: "Both", value: "both" },
    { label: "Started", value: "started" },
    { label: "Not Started", value: "not_started" },
  ];

  const filterVirtualOptions = [
    { label: "Both", value: "" },
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  const exportCsv = () => {
    const csvFilters = pipe(
      reject(anyPass([isNil, isEmpty])),
      toPairs,
      map(join("=")),
      join("&")
    )(filters);

    return `coupons.csv?${csvFilters}`;
  };

  const ListHeader = () => (
    <Pagination
      label= {count > 1 ? "Coupons" : "Coupon"}
      onPageChange={setPageNumber}
      recordCount={count}
      startPage={pageNumber}
    >
      <div className="btn-group pull-right">
        <a className="btn" href={exportCsv()}>
          Export
        </a>
        <a className="btn" href="coupons/new">
          New
        </a>
      </div>
    </Pagination>
  );

  return (
    <div className="row">
      <div className="span12">
        <div className="page-header">
          <h1>Coupons</h1>
        </div>
        <ListHeader />
        <Table id="coupons-table">
          <thead>
            <tr>
              <td className="span3" style={styles}>
                <SearchSelect
                  className="span3"
                  isClearable={true}
                  isSearchable={true}
                  onChange={(option) => setName(option?.value)}
                  options={filterNameOptions}
                  value={name}
                />
              </td>
              <td className="span2">
                <SearchSelect
                  className="span2"
                  isClearable={true}
                  isSearchable={true}
                  onChange={(option) => setCode(option?.value)}
                  options={filterCodeOptions}
                  value={code}
                />
              </td>
              <td>
                <Select
                  className="width-reset"
                  onChange={(e) => setCampaign(e.target.value)}
                  options={campaignOptions}
                  selectedValue={campaign}
                />
              </td>
              <td>
                <Select
                  className="width-reset"
                  hasDefaultOption={false}
                  onChange={(e) => setFilterStartDate(e.target.value)}
                  options={filterStartDateOptions}
                  selectedValue={filterStartDate}
                />
              </td>
              <td>
                <Select
                  className="width-reset"
                  hasDefaultOption={false}
                  onChange={(e) => setFilterExpirationDate(e.target.value)}
                  options={filterExpirationDateOptions}
                  selectedValue={filterExpirationDate}
                />
              </td>
              <td>
                <Select
                  className="width-reset"
                  hasDefaultOption={false}
                  onChange={(e) => setFilterVirtual(e.target.value)}
                  options={filterVirtualOptions}
                  selectedValue={filterVirtual}
                />
              </td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <TableHeader>
            <th>
              <a onClick={() => sort("coupons.name")}>Name</a>
            </th>
            <th>
              <a onClick={() => sort("coupons.code")}>Code</a>
            </th>
            <th>
              <a onClick={() => sort("coupons.campaign")}>Campaign Id</a>
            </th>
            <th>
              <a onClick={() => sort("coupons.start_date")}>Start Date</a>
            </th>
            <th>
              <a onClick={() => sort("coupons.expiration_date")}>
                Expiration Date
              </a>
            </th>
            <th>
              <a onClick={() => sort("coupons.is_virtual")}>Virtual</a>
            </th>
            <th>
              <a onClick={() => sort("redemption_count")}>Redeemed</a>
            </th>
            <th>Image</th>
          </TableHeader>
          <tbody>
            {(data || []).map((coupon, index) => (
              <tr
                className={index % 2 ? "even" : "odd"}
                key={`coupon-${coupon.id}`}
              >
                <td>
                  <a href={coupon.links.show}>{coupon.name}</a>
                </td>
                <td>{coupon.code}</td>
                <td>{coupon.campaign}</td>
                <td>{toDisplayDate(coupon.startDate)}</td>
                <td>{toDisplayDate(coupon.expirationDate)}</td>
                <td>{toYesNo(coupon.isVirtual)}</td>
                <td>{toNumber(coupon.redemptionCount)}</td>
                <td>{coupon.links.imageUrl ? imageLink(coupon) : "😦"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ListHeader />
      </div>
    </div>
  );
};

List.propTypes = {
  campaigns: arrayOf(string),
  codes: arrayOf(string),
  names: arrayOf(string),
};

export default List;
