import React, { useState } from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { Pagination } from "../../../shared/Pagination";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useGetApi } from "../../../shared/useGetApi";
import { useRecordCount } from "../../../shared/useRecordCount";
import { useTabCount } from "../../../shared/useTabCount";

const getEmptyMessage = () => "No sales for this owner.";

const Sales = ({ ownerId, tabState }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [sales, loading] = useGetApi({
    get: api.owners.sales,
    id: ownerId,
    params: {
      sale: pageNumber,
    },
  });

  const salesCount = useRecordCount(sales);
  useTabCount({ count: salesCount }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Pagination
        startPage={pageNumber}
        label={"Sales"}
        onPageChange={setPageNumber}
        recordCount={salesCount}
      />
      <Table id={"viewsales"}>
        <tbody>
          <RenderIf isTrue={salesCount == 0}>
            <tr>
              <td>
                <p>{getEmptyMessage()}</p>
              </td>
            </tr>
          </RenderIf>
          {sales.map((sale, index) => (
            <tr
              className={index % 2 == 0 ? "even" : "odd"}
              key={`sale-${index}`}
            >
              <td>{sale.saleDate}</td>
              <td>{sale.clinicFacility}</td>
              <td>
                <RenderIf isTrue={sale.isCanceled}>
                  <span className="fontred">(Canceled) </span>
                </RenderIf>
                <a href={sale.links.show}>{sale.uniqueId}</a>
              </td>
              <td>
                <RenderIf isTrue={sale.inCollections}>
                  <span className="label label-important">Unpaid</span>
                </RenderIf>
              </td>
              <td>
                <RenderIf isTrue={!sale.isCanceled && sale.isAstaPosSale}>
                  <a href={sale.links.printReceipt} className={"btn btn-mini"}>
                    Download Receipt
                  </a>
                </RenderIf>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

Sales.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default Sales;
