import React from "react";
import { render, screen } from "@testing-library/react";
import * as api from "../../../shared/useGetApi";
import { Collection } from "./type";
import Collections from "./";

describe("Collections", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Collections {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      renderComponent();

      expect(screen.getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have collections", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no collections", () => {
      renderComponent();

      expect(screen.getByText("No collections for this owner.")).toBeTruthy();
    });
  });

  describe("when has collections", () => {
    beforeEach(() => {
      const collection = [Collection.example()];
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [collection, false]);
    });

    test("shows the collection", () => {
      renderComponent();

      expect(screen.getByText(/New/)).toBeTruthy();
    });
  });
});
