import AdHocPrescriptions from "./AdHocPrescriptions";
import CurrentPendingTreatments from "../CurrentPendingTreatments";
import Prescriptions from "./Prescriptions";
import { array, number } from "prop-types";
import { values } from "ramda";
import React, { Fragment, useState } from "react";
import { useTabCount } from "../../../shared/useTabCount";

const PrescriptionsContainer = ({ ownerId, tabState }) => {
  const [reloadPrescriptions, setReloadPrescriptions] = useState(false);
  const [allPrescriptionCounts, setAllPrescriptionCounts] = useState({
    prescriptionsCount: 0,
    adHocPrescriptionsCount: 0,
  });

  useTabCount({ count: values(allPrescriptionCounts).sum() }, tabState);

  const updateTotalCounts = (countObject) => {
    setAllPrescriptionCounts({...allPrescriptionCounts, ...countObject});
  };

  const params = {
    ownerId: ownerId,
    updateTotalCounts,
  }

  return (
    <Fragment>
      <CurrentPendingTreatments ownerId={ownerId} reloadPrescriptions={reloadPrescriptions} setReloadPrescriptions={setReloadPrescriptions} />
      <Prescriptions {...params} reloadPrescriptions={reloadPrescriptions} setReloadPrescriptions={setReloadPrescriptions} />
      <AdHocPrescriptions {...params} />
    </Fragment>
  )
};

PrescriptionsContainer.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
}

export default PrescriptionsContainer;
