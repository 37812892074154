import React, { useState } from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { useGetApi } from "../../../shared/useGetApi";
import { useRecordCount } from "../../../shared/useRecordCount";
import { Table } from "../../../shared/Table";
import { RenderIf } from "../../../shared/RenderIf";
import { Pagination } from "../../../shared/Pagination";
import { useTabCount } from "../../../shared/useTabCount";

const InternalNotes = ({ ownerId, tabState }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [internalNotes, loading] = useGetApi({
    get: api.owners.internalNotes,
    id: ownerId,
    params: {
      internalNotes: pageNumber,
    },
  })
  const internalNotesCount = useRecordCount(internalNotes);
  useTabCount({ count: internalNotesCount }, tabState);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Pagination
        startPage={pageNumber}
        label={"Internal-Only Notes"}
        onPageChange={setPageNumber}
        recordCount={internalNotesCount}
      />
      <a href="#internal-note-modal" data-toggle="modal" className="btn btn-primary mb-1">New Internal-only Note</a>
      <Table id={"viewinternalnotes"}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Notes</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderIf isTrue={internalNotesCount == 0}>
            <tr>
              <td>
                <p>No internal-only notes for this owner</p>
              </td>
            </tr>
          </RenderIf>
          {internalNotes.map((note, index) => (
            <tr key={`note-${index}`}>
              <td>
                { note.date }
              </td>
              <td>
                { note.notes }
              </td>
              <td>
                { note.status }
              </td>
              <td>
                <a href={ note.links.edit } data-toggle="modal">Edit</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

InternalNotes.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};
export default InternalNotes
