import React from "react";
import { useFormik } from "formik";
import { api } from "../../../api";
import { ClinicLocationHours } from "../types";
import { func, object, string } from "prop-types";

import { Form } from "../Form";
import Modal from "../../../shared/Modal";
import { Button, ButtonContexts } from "../../../shared/Button";

export const EditHoursModal = ({ closeModal, hour, onDone, timezone }) => {
  const initialValues = ClinicLocationHours.make({
    closeHour: ClinicLocationHours.toCivilianTime(hour.closeHour),
    closeMinute: hour.closeMinute,
    closeAMPM: ClinicLocationHours.toAMPM(hour.closeHour),
    dayOfWeek: hour.day,
    openHour: ClinicLocationHours.toCivilianTime(hour.openHour),
    openMinute: hour.openMinute,
    openAMPM: ClinicLocationHours.toAMPM(hour.openHour),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const response = await api.clinicLocation.hours.update(
        hour.clinicLocationId,
        hour.id,
        ClinicLocationHours.formValuesToApi(values)
      );

      onDone(response?.notice);
    },
  });

  return (
    <form className="form-horizontal" onSubmit={formik.handleSubmit}>
      <Modal.Header closeModal={closeModal}>
        <h3>Edit {hour.dayName}</h3>
      </Modal.Header>
      <Modal.Body>
        <Form formik={formik} isEditing={true} />
        <p style={{ textAlign: "center" }}>Times are in {timezone}</p>
      </Modal.Body>
      <Modal.Footer closeModal={closeModal}>
        <Button
          context={ButtonContexts.PRIMARY}
          type="submit"
          isSubmitting={formik.isSubmitting}
        >
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};

EditHoursModal.defaultProps = {
  hour: ClinicLocationHours.make(),
};

EditHoursModal.propTypes = {
  closeModal: func.isRequired,
  hour: object,
  onDone: func.isRequired,
  timezone: string.isRequired,
};
