import { get, makeUrl } from "../";
import { normalizeArray, snakeKeys } from "../../api/utils/transforms";

const couponBaseUrl = "/coupons";

export const api = {
  coupons: {
    get: (_id, params) =>
      get(makeUrl([couponBaseUrl]), {
        params: snakeKeys(params),
        transformResponse: normalizeArray,
      }),
  },
};
