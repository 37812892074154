import {
  splitOnCapital,
  titleize,
  truncate,
} from "./string";

describe("titleize", () => {
  describe("basic cases", () => {
    test("titleizes snake case", () => {
      expect(titleize("hello_world")).toEqual("Hello World");
    });
  
    test("titleizes lowercase", () => {
      expect(titleize("hello world")).toEqual("Hello World");
    });
  
    test("titleizes mixed case", () => {
      expect(titleize("mixedUpper_andSnake_case")).toEqual("Mixed Upper And Snake Case");
    });
  
    test("handles existing titles", () => {
      expect(titleize("Hello World")).toEqual("Hello World");
    });
  });

  describe("edge cases", () => {
    test("titleizes lots of spaces", () => {
      expect(titleize("   Hello   World   ")).toEqual("Hello World");
    });

    test("titleizes lots of underscores", () => {
      expect(titleize("___Hello___World___")).toEqual("Hello World");
    });
  });
});

describe("splitOnCapital", () => {
  test("splits on capitals", () => {
    expect(splitOnCapital("HelloWorld")).toEqual(["Hello", "World"]);
  });
});

describe("truncate", () => {
  const undef = undefined;
  const nil = null;
  const blank = "";
  const value = 'VALUE';

  test("returns value when not set", () => {
    expect(truncate(undef)).toEqual(undefined);
    expect(truncate(nil)).toEqual(null);
    expect(truncate(blank)).toEqual("");
  });

  test("returns value when less than max", () => {
    expect(truncate(value)).toEqual("VALUE");
  });

  test("returns value when equal to max", () => {
    expect(truncate(value, 5)).toEqual("VALUE");
  });

  test("returns truncated value with default omission", () => {
    expect(truncate(value, 4)).toEqual("VALU...");
  });

  test("returns truncated value with specified omission", () => {
    expect(truncate(value, 4, "**")).toEqual("VALU**");
  });
});
