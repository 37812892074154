import { api } from "../../../api";
import React, { useState } from "react";
import { number, string } from "prop-types";
import { formatTime } from "./utils/formatTime";

import { Flex } from "../../../shared/Flex";
import { Table } from "../../../shared/Table";
import { Loader } from "../../../shared/Loader";
import { AddHoursModal } from "../AddHoursModal";
import { EditHoursModal } from "../EditHoursModal";
import { RenderIf } from "../../../shared/RenderIf";
import { useModal } from "../../../shared/useModal";
import { useGetApi } from "../../../shared/useGetApi";
import { PortalModal } from "../../../shared/PortalModal";
import { Button, ButtonContexts } from "../../../shared/Button";

const Hours = ({ clinicLocationId, timezone }) => {
  const [currentHour, setCurrentHour] = useState(null);
  const {
    open: isAddNewOpen,
    openModal: openAddNewModal,
    closeModal: closeAddNewModal,
  } = useModal();

  const {
    open: isEditOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const [hours, loading, refetch] = useGetApi({
    get: api.clinicLocation.hours.get,
    id: clinicLocationId,
  });

  const deleteHours = async (hour) => {
    const openAt = formatTime(hour.openHour, hour.openMinute);
    const closeAt = formatTime(hour.closeHour, hour.closeMinute);
    const confirmed = confirm(
      `Are you sure you want to remove the hours for ${hour.dayName} from ${openAt} to ${closeAt}?`
    );

    if (confirmed) {
      const response = await api.clinicLocation.hours.delete(
        clinicLocationId,
        hour.id
      );

      if (response) {
        refetch();

        if (response?.data?.notice) {
          window.flash(response.data.notice);
        }
      }
    }
  };

  return (
    <div>
      <Flex className="pb-3">
        <Button onClick={openAddNewModal} className="ml-auto">
          New Clinic Hour
        </Button>
        <RenderIf isTrue={isAddNewOpen}>
          <PortalModal>
            <AddHoursModal
              closeModal={closeAddNewModal}
              clinicLocationId={clinicLocationId}
              onDone={(notice) => {
                closeAddNewModal();
                refetch();
                if (notice) {
                  window.flash(notice);
                }
              }}
              timezone={timezone}
            />
          </PortalModal>
        </RenderIf>
        <RenderIf isTrue={isEditOpen && Boolean(currentHour)}>
          <PortalModal>
            <EditHoursModal
              closeModal={closeEditModal}
              hour={currentHour}
              onDone={(notice) => {
                closeEditModal();
                setCurrentHour(null);
                refetch();

                if (notice) {
                  window.flash(notice);
                }
              }}
              timezone={timezone}
            />
          </PortalModal>
        </RenderIf>
      </Flex>
      <Table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Open</th>
            <th>Close</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <RenderIf isTrue={loading}>
            <tr>
              <td colSpan="4">
                <Loader />
              </td>
            </tr>
          </RenderIf>
          <RenderIf isTrue={!loading}>
            {(hours || []).map((hour, index) => (
              <tr key={`hour-${index}`}>
                <td className="vertical-align-middle">{hour.dayName}</td>
                <td className="vertical-align-middle">
                  <RenderIf isTrue={!hour.isClosed}>
                    {formatTime(hour.openHour, hour.openMinute)}
                  </RenderIf>
                  <RenderIf isTrue={hour.isClosed}>Closed</RenderIf>
                </td>
                <td className="vertical-align-middle">
                  <RenderIf isTrue={!hour.isClosed}>
                    {formatTime(hour.closeHour, hour.closeMinute)}
                  </RenderIf>
                </td>
                <td className="vertical-align-middle">
                  <RenderIf isTrue={!hour.isClosed}>
                    <Button
                      onClick={() => {
                        setCurrentHour(hour);
                        openEditModal();
                      }}
                      context={ButtonContexts.LINK}
                    >
                      Edit
                    </Button>
                    <span>|</span>
                    <Button
                      onClick={() => deleteHours(hour)}
                      context={ButtonContexts.LINK}
                    >
                      Delete
                    </Button>
                  </RenderIf>
                </td>
              </tr>
            ))}
          </RenderIf>
        </tbody>
      </Table>
    </div>
  );
};

Hours.propTypes = {
  clinicLocationId: number.isRequired,
  timezone: string.isRequired,
};

export default Hours;
