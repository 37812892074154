import React from "react";
import { bool, func, string } from "prop-types";
import { api } from "../../../../api";
import { Loader } from "../../../../shared/Loader";
import { SearchSelect } from "../../../../shared/SearchSelect";
import { useGetApi } from "../../../../shared/useGetApi";

export const LotNumber = ({
  disabled,
  mode,
  productId,
  rigCodeId,
  setFieldValue,
  value,
}) => {
  const [officeRabiesLots, loading] = useGetApi({
    get: api.officeRabiesLots.get,
    id: rigCodeId,
    params: {
      product_id: productId,
    },
  });
  const lotNumberOptions = (officeRabiesLots || [])
    .filter(
      (officeRabiesLot) => mode === "special-edit" || officeRabiesLot.isActive
    )
    .map((officeRabiesLot) => ({
      label: officeRabiesLot.lotNumber,
      value: officeRabiesLot.lotNumber,
    }));

  const handleLotNumberChange = (value, setFieldValue) => {
    const officeRabiesLot =
      officeRabiesLots.find((lot) => lot.lotNumber === value) || "";
    setFieldValue("lotNumber", officeRabiesLot.lotNumber);
    setFieldValue("lotExpirationDate", officeRabiesLot.lotExpirationDate);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <SearchSelect
      className="select"
      disabled={disabled}
      onChange={(el) => handleLotNumberChange(el.value, setFieldValue)}
      options={lotNumberOptions}
      value={value}
    />
  );
};

LotNumber.propTypes = {
  disabled: bool.isRequired,
  mode: string.isRequired,
  productId: string.isRequired,
  rigCodeId: string.isRequired,
  setFieldValue: func.isRequired,
  value: string.isRequired,
};
