import { func, object } from "prop-types";
import React, { Fragment } from "react";
import { Button } from "../../../../../shared/Button";
import { Table } from "../../../../../shared/Table" ;

const ViewDetails = ({ prescription, closeModal }) => (
  <Fragment>
    <div className="modal-header">
      <Button onClick={closeModal} className="close" aria-hidden="true">&times;</Button>
      <h3>{`Ad-Hoc Prescription for ${prescription.petName} (${prescription.ownerFirstName} ${prescription.ownerLastName})`}</h3>
    </div>
    <div className="modal-body">
      <Table isNested={true} isBordered={false} isStriped={false} isCondensed={false}>
        <tbody className="no-hover">
          <tr>
            <td colSpan="2" className='no-border'>
              <strong>Pharmacy Status:</strong>&nbsp;
              <span className="label label-success">
                {prescription.status.toUpperCase()}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Product:</strong> {prescription.productName}<br />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Clinic: </strong>
              {prescription.clinic.clinicLocation.facilityInternalWithExternalId}
            </td>
            <td>
              <strong>Clinic Date: </strong>
              {prescription.clinic.clinicOn}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Vet: </strong>
              {`${prescription.vetFirstName} ${prescription.vetLastName}`}
            </td>
            <td>
              <strong>Vaccert: </strong>
              {prescription.vaccertNumber}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Effective On: </strong>
              {prescription.prescribedOn}
            </td>
            <td>
              <strong>Fulfilled On: </strong>
              {prescription.createdAt}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Prescription Doses: </strong>
              {prescription.doses}
            </td>
            <td>
              <strong>Refill Doses Available: </strong>
              {prescription.displayRefills}
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Dosing Instructions: </strong>
              {prescription.instructions}
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="muted">
              <i>
                Updated at {prescription.updatedAt}<br />
                ID: {prescription.id}
              </i>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
    <div className="modal-footer">
      <Button onClick={closeModal}>Close</Button>
    </div>
  </Fragment>
);

ViewDetails.propTypes = {
  prescription: object.isRequired,
  closeModal: func.isRequired,
};

export default ViewDetails;
