import * as containerUtils from "../containerUtils";
import React from "react";
import * as api from "../../../../shared/useGetApi";
import { render } from "@testing-library/react";
import AdHocPrescriptions from "./";

describe("AdHocPrescriptions", () => {
  const ownerId = 1;
  const updateTotalCounts = jest.fn();

  const defaultProps = {
    ownerId,
    updateTotalCounts,
  };

  const renderComponent = (props) =>
    render(<AdHocPrescriptions {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading Ad-Hoc Prescriptions...")).toBeTruthy();
    });
  });

  describe("when does not have adHocPrescriptions", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("does not render the container", () => {
      const { queryByText } = renderComponent();

      expect(queryByText("Ad-Hoc Prescriptions")).toBeFalsy();
    });
  });

  describe("when has adHocPrescriptions", () => {
    const exampleAdHocPrescription = {
      clinic: {
        clinicLocation: {
          facilityInternalWithExternalId: "Windsor HQ",
          id: "354",
          links: undefined,
          meta: undefined,
        },
        clinicOn: "08-28-2020",
        id: "237",
        links: undefined,
        meta: undefined,
      },
      createdAt: "08-28-2020",
      deliveryType: "PRINT",
      displayRefills: 0,
      doses: 0,
      id: "1",
      instructions: "Take da meds",
      links: undefined,
      meta: {
        currentUser: {},
        recordCount: 35,
        page: "1",
      },
      ownerFirstName: "Deon",
      ownerLastName: "Gerlach",
      petName: "Elnora",
      prescribedOn: "08-28-2020",
      productName: "Blah",
      status: "Printed In Clinic",
      statusObject: {
        alertClass: "info",
        labelClass: "success",
        rowClass: "inactive",
      },
      updatedAt: "09-03-2020 12:12am PDT",
      vaccertNumber: "abcd",
      vetFirstName: "System Account",
      vetLastName: "VIP Pet Records",
    };

    beforeEach(() => {
      const adHocPrescriptions = [exampleAdHocPrescription];
      jest.spyOn(api, "useGetApi").mockImplementation(() => [adHocPrescriptions, false]);

      jest.spyOn(containerUtils, "filterPrescriptions").mockImplementation(() => adHocPrescriptions);
    });

    test("shows the ad-hoc prescriptions", () => {
      const { getByText } = renderComponent();

      expect(getByText("Ad-Hoc Prescriptions")).toBeTruthy();
      expect(getByText("Elnora")).toBeTruthy();
    });
  });
});
