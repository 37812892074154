import React, { useLayoutEffect } from 'react';

// TODO: Don't rely on setting innerHTML of DOM element when all tabs use TabContainer component
export function useTabCount({ collection, count = 0 }, tabState) {
  const tabCount = collection ? collection.length : count;

  useLayoutEffect(() => {
    if (tabState?.length === 3) {
      const [tabId, tabCounts, setTabCounts] = tabState;
      const foundTab = tabCounts.find(element => element.id === tabId);
      const newTabCount = foundTab.count + tabCount;
      setTabCounts({ type: "count", tabId: tabId, count: newTabCount });
    } else if (tabState) {
      const [elementId] = tabState
      const element = document.getElementById(elementId);
      if (element) {
        element.innerHTML = `(${tabCount})`;
      }
    }
  }, [collection, count]);
}
