import React from "react";
import { bool, func, string } from "prop-types";

export const Checkbox = ({
  defaultChecked,
  name,
  onChange,
  text,
  value,
  checked,
}) => {
  const inputProps = {
    className: "checkbox__input",
    name: name,
    onChange: (event) => onChange(!checked, event),
    type: "checkbox",
    value: value,
    defaultChecked: defaultChecked,
  };

  if (checked !== undefined) {
    inputProps.checked = checked;
  } else {
    inputProps.defaultChecked = defaultChecked || false;
  }

  return (
    <label className="checkbox__container">
      <input {...inputProps} />
      {text}
    </label>
  );
};

Checkbox.defaultProps = {
  checked: undefined,
  defaultChecked: false,
  value: "",
  text: "",
  name: "",
};

Checkbox.propTypes = {
  defaultChecked: bool,
  name: string,
  onChange: func.isRequired,
  text: string,
  value: string,
  checked: bool,
};
