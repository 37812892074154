import React from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { Loader } from "../../../shared/Loader";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";

const getEmptyMessage = () => "No detached pets for this owner.";

const petLinkText = ({ pet }) =>
  pet.isDeceased ? "deceased, detached" : "detached";

const History = ({ ownerId, tabState }) => {

  const [detachedPets, loading] = useGetApi({
    get: api.owners.history,
    id: ownerId,
    params: {},
  });

  useTabCount({ collection: detachedPets }, tabState);

  if (!detachedPets && loading) {
    return <Loader />;
  }

  return (
    <Table id="viewhistory">
      <tbody>
        {detachedPets.map((history, index) => (
          <tr
            className={index % 2 == 0 ? "even" : "odd"}
            key={`history-${index}`}
          >
            <td>{history.updatedAt}</td>
            <td>
              <a href={history.pet.links.show}>
                {history.pet.name} ( {petLinkText(history)} )
              </a>
            </td>
            <td>
              <a href={history.links.reattach} data-method="put">re-attach</a>
            </td>
          </tr>
        ))}
        <RenderIf isTrue={detachedPets.length === 0}>
          <tr>
            <td>
              <p>{getEmptyMessage()}</p>
            </td>
          </tr>
        </RenderIf>
      </tbody>
    </Table>
  );
};

History.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default History;
