import React from "react";
import * as api from "@/shared/useGetApi";
import { render } from "@testing-library/react";
import { OwnerInfo } from "./";

const defaultProps = {
  ownerId: 1,
};

const apiResponseExample = {
  data: [
    {
      id: "225302",
      type: "owner",
      attributes: {
        name: "Cass Sanchez",
        email: "cool@email.com",
        phone: "6283766033",
        address: "1017 Rggent St",
      },
    },
  ],
};

describe("OwnerInfo", () => {
  const renderComponent = () => render(<OwnerInfo {...defaultProps} />);

  describe("When Loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("Shows Loading Message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  test("Renders <OwnerInfo/>", () => {
    expect(renderComponent).toBeTruthy();
  });

  describe("Fetch API", () => {
    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [apiResponseExample, false]);
    });

    test("Renders with API data", () => {
      const { getByText } = renderComponent();

      expect(getByText("Sanchez, Cass")).toBeTruthy();
      expect(getByText("cool@email.com")).toBeTruthy();
    });
  });
});
