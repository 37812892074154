import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import Notifications from "./";

describe("Notifications", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Notifications {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have notifications", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no notifications", () => {
      const { getByText } = renderComponent();

      expect(getByText("No notifications for this owner.")).toBeTruthy();
    });
  });

  describe("when has notifications", () => {
    describe("can cancel", () => {
      const exampleNotification = {
        id: "1",
        description: "Positive Lab Test Result",
        isActive: false,
        meta: {
          page: "1",
          recordCount: 10,
          timeZone: "Arizona",
          permissions: {
            canCancelNotification: true
          }
        },
        report: [
            {
              table :
                  {
                    id: "fbb439b5-eab8-47d8-92e9-666db05e4b4b",
                    type: "status_report",
                    dateTime: "09-01-2020 10:10am PDT",
                    message: "Next sms message for Test Result Positive is scheduled for 08-31-2020 at 12:33pm MST",
                    name: "important",
                    status: "Pending"
                  }
            }
        ]
      }

      beforeEach(() => {
        const notification = [exampleNotification]
        jest.spyOn(api, "useGetApi").mockImplementation(() => [notification, false]);
      });

      test("shows the notification", () => {
        const { getByText } = renderComponent();

        expect(getByText("Positive Lab Test Result")).toBeTruthy();
      });

      test("displays a button to cancel", () => {
        const { getByRole } = renderComponent();

        expect(getByRole("button")).toBeTruthy();
      });
    });

    describe("cannot cancel", () => {
      const exampleUncancelableNotification = {
        id: "1",
        description: "Positive Lab Test Result",
        isActive: false,
        meta: {
          page: "1",
          recordCount: 10,
          timeZone: "Arizona",
          permissions: {
            canCancelNotification: false
          }
        },
        report: [
          {
            table :
                {
                  id: "fbb439b5-eab8-47d8-92e9-666db05e4b4b",
                  type: "status_report",
                  dateTime: "09-01-2020 10:10am PDT",
                  message: "Next sms message for Test Result Positive is scheduled for 08-31-2020 at 12:33pm MST",
                  name: "important",
                  status: "Pending"
                }
          }
        ]
      }

      beforeEach(() => {
        const notification = [exampleUncancelableNotification]
        jest.spyOn(api, "useGetApi").mockImplementation(() => [notification, false]);
      });

      test("no button displayed", () => {
        const { queryByRole } = renderComponent();

        expect(queryByRole("button")).toBeNull();
      });
    });
  });
});
