export const filterPrescriptions = (
  { hideInactivePrescriptions },
  prescriptions
) =>
  prescriptions
    .filter((prescription) =>
      hideInactivePrescriptions && prescription.statusObject.rowClass == "inactive" ? false : true
    );

export const downloadAndPrint = (prescription) => {
  window.location = prescription.links.printPrescription;
};
