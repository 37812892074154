import { make } from "./creation";

describe("creation", () => {
  describe("make", () => {
    it("has empty defaults", () => {
      expect(make()).toEqual({
        dayOfWeek: "",
        openHour: "",
        openMinute: "",
        openAMPM: "",
        closeHour: "",
        closeMinute: "",
        closeAMPM: "",
      });
    });

    it("accepts overrides", () => {
      const overrides = {
        dayOfWeek: "1",
        openHour: "1",
        openMinute: "00",
        openAMPM: "AM",
        closeHour: "13",
        closeMinute: "45",
        closeAMPM: "PM",
      };

      expect(make(overrides)).toEqual(overrides);
    });
  });
});
