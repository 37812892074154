export const toMilitaryTime = (hour, period) => {
  if (!hour || !period) return "";
  if (period === "PM" && Number(hour) !== 12) return Number(hour) + 12;
  return Number(hour);
};

export const toCivilianTime = (hour) => {
  if (!hour) return "";
  if (Number(hour) > 12) return Number(hour) - 12;
  return hour;
};

export const toAMPM = (hour) => {
  if (!hour) return "";
  if (Number(hour) >= 12) return "PM";
  return "AM";
};

export const formValuesToApi = ({
  closeAMPM,
  closeHour,
  closeMinute,
  dayOfWeek,
  openHour,
  openMinute,
  openAMPM,
}) => ({
  close_hour: toMilitaryTime(closeHour, closeAMPM),
  close_minute: closeMinute,
  day: dayOfWeek,
  open_hour: toMilitaryTime(openHour, openAMPM),
  open_minute: openMinute,
});
