import React from "react";
import * as api from "../../../shared/useGetApi";
import * as utils from "./utils";
import { render } from "@testing-library/react";
import Notes from "./";

describe("Notes", () => {
  const entityId = 1;
  const entityType = "owner"
  const noteTypes = ["Owner", "Pet"];

  const defaultProps = {
    entityId,
    entityType,
    noteTypes,
  };

  const renderComponent = (props) =>
    render(<Notes {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when does not have notes", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("shows message that there are no notes", () => {
      const { getByText } = renderComponent();

      expect(getByText("No notes")).toBeTruthy();
    });
  });

  describe("when has notes", () => {
    const exampleNote = {
      id: "2",
      meta: {
        permissions: { canEditNonTelehealth: true, canViewSourceType: true },
      },
      links: {
        showSourceType: "http://localhost:3000/owners/11",
        show: "http://localhost:3000/vip_notes/2",
        edit: "http://localhost:3000/vip_notes/2/edit",
      },
      autoGenerated: false,
      followUpDate: "2020-08-20",
      needsFollowUp: true,
      noteText: "A tele-medicine note about things.",
      sourceType: "Owner",
      sourceId: 11,
      teleHealthTitle: "Tele-Medicine",
      isTeleHealthNote: true,
      createdAt: "08-06-2020  2:46pm PDT",
      source: "Matti Salokangas",
    };

    beforeEach(() => {
      const notes = [exampleNote];
      jest.spyOn(api, "useGetApi").mockImplementation(() => [notes, false]);

      jest.spyOn(utils, "filterNotes").mockImplementation(() => notes);
    });

    test("shows the notes", () => {
      const { getByText } = renderComponent();

      expect(getByText("A tele-medicine note about things.")).toBeTruthy();
    });
  });
});
