const initial = {
  dayOfWeek: "",
  openHour: "",
  openMinute: "",
  openAMPM: "",
  closeHour: "",
  closeMinute: "",
  closeAMPM: "",
};

export const make = (overrides) => ({
  ...initial,
  ...overrides,
});
