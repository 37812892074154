import React, { createContext, useContext, useState } from "react";
import { node } from "prop-types";

const AlertContext = createContext();

export function useAlert() {
  return useContext(AlertContext);
}


export function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  return (
    <AlertContext.Provider value={{alert, setAlert}}>
        {children}
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: node.isRequired,
};
