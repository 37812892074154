import Modal from "../Modal";
import Portal from "./portal";
import { func, node } from "prop-types";
import React from "react";

export const PortalModal = ({ children, closeModal }) => {
  return (
    <Portal>
      <div onClick={closeModal} className="modal-backdrop"></div>
      <Modal
        className="portal-modal"
        modalId="base-portal-modal"
        display="block"
      >
        {children}
      </Modal>
    </Portal>
  );
};

PortalModal.defaultProps = {
  closeModal: () => null,
};

PortalModal.propTypes = {
  children: node.isRequired,
  closeModal: func,
};
