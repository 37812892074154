import React from "react";
import { render } from "@testing-library/react";
import { BillingAddress } from "./";

describe("BillingAddress", () => {
  const renderComponent = () => render(<BillingAddress />);

  test("Renders <BillingAddress/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
