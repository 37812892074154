import Callbacks from "../../Callbacks";
import Collections from "../../Collections";
import History from "../../History";
import Notes from "../../../shared/Notes";
import Notifications from "../../Notifications";
import Orders from "../../Orders";
import PrescriptionsContainer from "../../PrescriptionsContainer";
import ProfileTestResults from "../../ProfileTestResults";
import Reactions from "../../Reactions";
import Sales from "../../Sales";
import Visits from "../../../shared/Visits";
import InternalNotes from "../../InternalNotes";

// Order matters, determines render order for tabs
export const tabConfigurations = [
  {
    id: "vip-notes",
    alwaysShow: true,
    component: Notes,
    title: "Notes",
  },
  {
    id: "reactions",
    alwaysShow: false,
    component: Reactions,
    title: "Reactions",
  },
  {
    id: "sales",
    alwaysShow: false,
    component: Sales,
    permissionAttribute: "canShowSales",
    title: "Sales",
  },
  {
    id: "visits",
    alwaysShow: false,
    component: Visits,
    title: "Visits",
  },
  {
    id: "profile-tests",
    alwaysShow: false,
    component: ProfileTestResults,
    title: "Tests",
  },
  {
    id: "callbacks",
    alwaysShow: false,
    component: Callbacks,
    title: "Reminders",
  },
  {
    id: "orders",
    alwaysShow: false,
    component: Orders,
    title: "Orders",
  },
  {
    id: "notifications",
    alwaysShow: false,
    component: Notifications,
    hasTitleSpan: true,
    title: "Notifications",
  },
  {
    id: "collections",
    alwaysShow: false,
    component: Collections,
    hasTitleSpan: true,
    title: "Collections",
  },
  {
    id: "prescriptions",
    alwaysShow: true,
    component: PrescriptionsContainer,
    title: "Prescriptions",
  },
  {
    id: "history",
    alwaysShow: false,
    component: History,
    title: "Detached Pets",
  },
  {
    id: "internal-only-notes",
    alwaysShow: true,
    component: InternalNotes,
    title: "Internal-Only Notes"
  }
];
