import React from "react";
import * as api from "../../../shared/useGetApi";
import { render } from "@testing-library/react";
import Reactions from "./";

describe("Reactions", () => {
  const ownerId = 1;

  const defaultProps = {
    ownerId,
  };

  const renderComponent = (props) =>
    render(<Reactions {...defaultProps} {...props} />);

  describe("when loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("shows loading message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("when has reactions", () => {
    const exampleReactions = [
      {
        id: 12,
        links: {
          show: "http://localhost:3000/reactions/3",
        },
        reactionId: 3,
        status: "Open",
        date: "08-10-2020",
        pet: {
          id: 7,
          name: "Pet 1 name",
          links: {
            show: "http://localhost:3000/pets/12",
          },
        },
      },
      {
        id: 13,
        links: {
          show: "http://localhost:3000/reactions/5",
        },
        reactionId: 5,
        status: "Open",
        date: "08-10-2020",
        pet: {
          id: 11,
          name: "Pet 2 name",
          links: {
            show: "http://localhost:3000/pets/13",
          },
        },
      },
    ];

    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [exampleReactions, false]);
    });

    test("shows the reactions", () => {
      const { getByText } = renderComponent();

      expect(getByText("Pet 1 name")).toBeTruthy();
      expect(getByText("Pet 2 name")).toBeTruthy();
    });
  });
});
