import cx from "classnames"
import { func, node, string } from "prop-types";
import React from "react";

const closeGlyph = "\u2715";

export const Modal = ({
  children,
  className,
  modalId,
  display,
}) => {
  return (
    <div
      className={cx("modal", className)}
      id={modalId}
      role="dialog"
      style={{display: display}}
    >
      { children }
    </div>
  );
};

const Header = ({ children, closeModal }) => {
  return (
    <div className={"modal-header"}>
      <button className="close" data-dismiss="modal" onClick={closeModal}>{ closeGlyph }</button>
      { children }
    </div>
  )
};

const Body = ({ children }) => {
  return (
    <div className={"modal-body"}>
      { children }
    </div>
  )
};

const Footer = ({ children, closeModal }) => {
  return (
    <div className={"modal-footer"}>
      { children }
      <button className="btn" data-dismiss="modal" onClick={closeModal}>Close</button>
    </div>
  )
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.defaultProps = {
  display: "none",
}

Modal.propTypes = {
  children: node,
  className: string.isRequired,
  modalId: string.isRequired,
  display: string,
};

Header.defaultProps = {
  closeModal: () => {},
}

Header.propTypes = {
  children: node,
  closeModal: func,
};

Body.propTypes = {
  children: node,
  closeModal: func,
};

Footer.defaultProps = {
  closeModal: () => {},
}

Footer.propTypes = {
  children: node,
  closeModal: func,
};

export default Modal;
