import { toYesNo } from "./booleanFormatter";

describe("toYesNo", () => {
  test("formats true boolean as expected", () => {
    expect(toYesNo(true)).toBe("Yes");
  });

  test("formats false boolean as expected", () => {
    expect(toYesNo(false)).toBe("No");
  });
});
