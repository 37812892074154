import React from "react";
import cx from "classnames";
import { bool, node, string } from "prop-types";

export const Table = ({
  children,
  id,
  isBordered,
  isCondensed,
  isNested,
  isStriped,
}) => (
  <table
    id={id}
    className={cx("table", {
      nested: isNested,
      "table-bordered": isBordered,
      "table-condensed": isCondensed,
      "table-striped": isStriped,
    })}
  >
    {children}
  </table>
);

Table.defaultProps = {
  children: null,
  id: undefined,
  isBordered: true,
  isCondensed: true,
  isNested: false,
  isStriped: true,
};

Table.propTypes = {
  children: node,
  id: string,
  isBordered: bool,
  isCondensed: bool,
  isNested: bool,
  isStriped: bool,
};
