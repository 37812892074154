import React from "react";
import { render } from "@testing-library/react";
import { AssociatedPets } from "./";
import * as api from "@/shared/useGetApi";

const defaultProps = {
  ownerId: 1,
};

const apiResponseExample = {
  data: [
    {
      id: "3852637",
      attributes: {
        pet_id: 3804339,
        owner_id: 2253020,
        pet: {
          data: {
            id: "3804339",
            type: "pet",
            attributes: {
              name: "Sadie",
              species: {
                id: 2,
                name: "Dog",
              },
              age: "11.5 years",
              gender: "F",
              breed: "Great Dane",
              positive_tests: [
                {
                  id: 4938616,
                  test_result: "NEGATIVE",
                  test_notes: null,
                  created_at: "2020-08-19T15:01:34.643-07:00",
                  createdAt: "08-19-2020",
                },
              ],
              microchips: ["16516515"],
            },
          },
        },
      },
    },
    {
      id: "3422648",
      attributes: {
        pet_id: 3376669,
        owner_id: 2253020,
        pet: {
          data: {
            id: "3376669",
            attributes: {
              name: "Krillin",
              species: {
                id: 2,
                name: "Dog",
              },
              age: "9.5 years",
              gender: "M",
              breed: "Mixed Breed",
              positive_tests: [],
              microchips: [],
            },
          },
        },
      },
    },
  ],
};

describe("AssociatedPets", () => {
  const renderComponent = (props) =>
    render(<AssociatedPets {...defaultProps} {...props} />);

  describe("When Loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("Shows Loading Message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  test("Renders <AssociatedPets/>", () => {
    expect(renderComponent).toBeTruthy();
  });

  describe("Fetch API", () => {
    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [apiResponseExample, false]);
    });

    test("Renders with API data", () => {
      const { getByText } = renderComponent();

      expect(getByText("Sadie")).toBeTruthy();
      expect(getByText("Krillin")).toBeTruthy();
    });
  });
});
