import React from "react";
import { render } from "@testing-library/react";
import { PaymentMethod } from "./";

describe("PaymentMethod", () => {
  const renderComponent = () => render(<PaymentMethod />);

  test("Renders <PaymentMethod/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
