import React from "react";
import { api } from "../../../api";
import { useFormik } from "formik";
import { ClinicLocationHours } from "../types";
import { func, number, string } from "prop-types";

import { Form } from "../Form";
import Modal from "../../../shared/Modal";
import { Button, ButtonContexts } from "../../../shared/Button";

export const AddHoursModal = ({
  closeModal,
  clinicLocationId,
  onDone,
  timezone,
}) => {
  const formik = useFormik({
    initialValues: ClinicLocationHours.make(),
    onSubmit: async (values) => {
      const response = await api.clinicLocation.hours.create(
        clinicLocationId,
        ClinicLocationHours.formValuesToApi(values)
      );

      onDone(response?.notice);
    },
  });
  return (
    <form className="form-horizontal" onSubmit={formik.handleSubmit}>
      <Modal.Header closeModal={closeModal}>
        <h3>New Clinic Hour</h3>
      </Modal.Header>
      <Modal.Body>
        <Form formik={formik} />
        <p style={{ textAlign: "center" }}>Times are in {timezone}</p>
      </Modal.Body>
      <Modal.Footer closeModal={closeModal}>
        <Button
          context={ButtonContexts.PRIMARY}
          type="submit"
          isSubmitting={formik.isSubmitting}
        >
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};

AddHoursModal.propTypes = {
  closeModal: func.isRequired,
  clinicLocationId: number.isRequired,
  onDone: func.isRequired,
  timezone: string.isRequired,
};
