import axios from "axios";
import { api } from "./";

jest.mock("axios", () => ({
  defaults: {
    headers: {
      common: {},
    },
  },
  get: jest.fn().mockName("get").mockResolvedValue({
    data: [],
  }),
  put: jest.fn().mockName("put").mockResolvedValue({
    data: [],
  }),
  post: jest.fn().mockName("post").mockResolvedValue({
    data: [],
  }),
}));

describe("Owner API", () => {
  const ownerId = "OWNER_ID";

  describe("callbacks", () => {
    test("it calls the callbacks action", async () => {
      await api.owners.callbacks(ownerId);

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/callbacks`,
        expect.any(Object)
      );
    });
  });

  describe("notes", () => {
    test("it calls the notes action", async () => {
      await api.owners.notes(ownerId, {});

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/notes`,
        expect.any(Object)
      );
    });

    test("it passes the search query along", async () => {
      const params = {
        search_note_text: "search",
      };
      await api.owners.notes(ownerId, params);

      expect(axios.get).toBeCalledWith(`/owners/${ownerId}/notes`, {
        params,
        transformResponse: expect.any(Function),
      });
    });
  });

  describe("profileTestResult", () => {
    test("it calls the profile test results action", async () => {
      await api.owners.profileTestResults(ownerId);

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/profile_test_results`,
        expect.any(Object)
      );
    });
  });

  describe("reactions", () => {
    test("it calls the reactions action", async () => {
      await api.owners.reactions(ownerId);

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/reactions`,
        expect.any(Object)
      );
    });
  });

  describe("history", () => {
    test("it calls the detached_pets action", async () => {
      await api.owners.history(ownerId, {});

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/detached_pets`,
        expect.any(Object)
      );
    });
  });

  describe("attach", () => {
    const ownerId = "OWNER_ID";
    const petId = "PET_ID";
    const params = { pet_id: petId };

    test("it calls the attach action", async () => {
      await api.owners.attach(ownerId, null, params);

      expect(axios.put).toBeCalledWith(
        `/owners/${ownerId}/attach`,
        expect.any(Object),
        { params: params }
      );
    });
  });

  describe("sales", () => {
    test("it calls the sales action", async () => {
      await api.owners.sales(ownerId);

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/sales`,
        expect.any(Object)
      );
    });
  });

  describe("visits", () => {
    test("it calls the visits action", async () => {
      await api.owners.visits(ownerId, {});

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/visits`,
        expect.any(Object)
      );
    });
  });

  describe("collections", () => {
    test("it calls the collections action", async () => {
      await api.owners.collections(ownerId, {});

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/collections`,
        expect.any(Object)
      );
    });
  });

  describe("unpaidSales", () => {
    test("it calls unpaidSales action", async () => {
      await api.owners.unpaidSales(ownerId, {});

      expect(axios.get).toBeCalledWith(
        `/owners/${ownerId}/unpaid_sales_payment.json`,
        expect.any(Object)
      );
    });
  });

  describe("payment", () => {
    const ownerId = "OWNER_ID";
    const params = { cool: "obj" };

    test("it calls payment action", async () => {
      await api.owners.payment(ownerId, params);
      expect(axios.post).toBeCalledWith(
        `/owners/${ownerId}/payment`,
        expect.any(Object),
        undefined
      );
    });
  });
});
