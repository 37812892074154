import { renderHook } from "@testing-library/react-hooks";
import { useGetApi } from "./";

describe("useGetApi", () => {
  const get = jest
    .fn()
    .mockName("get")
    .mockResolvedValue([
      {
        id: "ID",
      },
    ]);
  const id = "ID";
  const params = {};

  test("calls the provided api", () => {
    const hook = renderHook(() =>
      useGetApi({
        get,
        id,
        params,
      })
    );

    const [_data, loading] = hook.result.current;

    expect(loading).toBe(true);
    expect(get).toBeCalledWith(id, params);

    hook.waitForNextUpdate();
  });

  test("gets data", (done) => {
    const hook = renderHook(() =>
      useGetApi({
        get,
        id,
        params,
      })
    );

    hook.waitForNextUpdate().then(() => {
      const [data, loading] = hook.result.current;

      expect(loading).toBe(false);
      expect(data).toEqual([
        {
          id: "ID",
        },
      ]);
      done();
    });

    hook.rerender({
      get,
      id,
      params,
    });
  });
});
