import React from "react";
import { render } from "@testing-library/react";
import { BillingAddressBook } from "./";

describe("BillingAddressBook", () => {
  const renderComponent = () => render(<BillingAddressBook />);

  test("Renders <BillingAddressBook/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});