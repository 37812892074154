import { format, parseISO } from "date-fns";
import { fromUnixTime } from "date-fns";

export const toDisplayDate = (date) => {
  if (typeof date === "string") {
    return format(parseDate(date), "M-d-y");
  } else {
    return format(fromUnixTime(date), "M-d-y");
  }
};

const parseDate = (date) => (typeof date === "string" ? parseISO(date) : date);

export const MONTHS = [
  { value: 1, label: "1 - January" },
  { value: 2, label: "2 - February" },
  { value: 3, label: "3 - March" },
  { value: 4, label: "4 - April" },
  { value: 5, label: "5 - May" },
  { value: 6, label: "6 - June" },
  { value: 7, label: "7 - July" },
  { value: 8, label: "8 - August" },
  { value: 9, label: "9 - September" },
  { value: 10, label: "10 - October" },
  { value: 11, label: "11 - November" },
  { value: 12, label: "12 - December" },
];

const generateArrayOfYears = () => {
  const min = new Date().getFullYear();
  const max = min + 10;
  const years = [];

  for (let i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
};

export const YEARS = generateArrayOfYears().map((year) => {
  return { value: year, label: year.toString() };
});
