import { values } from "ramda";
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";

export const usePutApi = ({ put, id, body, params, shouldExecute }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(shouldExecute);

  const deps = [id, body, ...values(params)];

  const putData = useCallback(async () => {
    const data = await put(id, body, params);

    setData(data);
    setLoading(false);
  }, [...deps]);

  useEffect(() => {
    if (shouldExecute) {
      putData();
    }
  }, [...deps, shouldExecute]);

  return [data, loading];
};
