const initial = {
  clinicLocationId: 1,
  day: 1,
  dayName: "Monday",
  openHour: 8,
  openMinute: 0,
  closeHour: 15,
  closeMinute: 0,
};

export const example = (overrides) => ({
  ...initial,
  ...overrides,
});
