import React from "react";
import { render } from "@testing-library/react";
import UnpaidSalesPayment from ".";

const defaultProps = {
  ownerId: 1,
};

jest.mock("@/shared/OwnerInfo", () => {
  return {
    OwnerInfo: () => <div></div>,
  };
});

describe("UnpaidSalesPayment", () => {
  const renderComponent = () =>
    render(<UnpaidSalesPayment ownerId={defaultProps} />);

  test("Renders <UnpaidSalesPayment/>", () => {
    expect(renderComponent).toBeTruthy();
  });
});
