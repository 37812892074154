import React from 'react'
import { bool, func, string } from 'prop-types'
import { api } from "../../../../api";
import { Loader } from "../../../../shared/Loader";
import { Select } from "../../../../shared/Select";
import { useGetApi } from "../../../../shared/useGetApi";

export const RigCode = ({ disabled, value, setFieldValue }) => {
  const [rigCodes, loading] = useGetApi({
    get: api.rabiesLot.rigCodes
  });

  const handleRigCodeChange = (e, setFieldValue) => {
    setFieldValue('rigCodeId', e.target.value);
    setFieldValue('lotNumber', "")
    setFieldValue('lotExpirationDate', "")
  }
  const rigCodeOptions = (rigCodes || []).map(rigCode => ({ label: rigCode.name, value: rigCode.id }))

  if (loading) {
    return <Loader />;
  }
  return (
    <Select
      defaultLabel="Please Select"
      defaultValue={value}
      disabled={disabled}
      onChange={(e) => handleRigCodeChange(e, setFieldValue)}
      options={rigCodeOptions}
      selectedValue={value}
    />
  )
}

RigCode.propTypes = {
  disabled: bool.isRequired,
  setFieldValue: func.isRequired,
  value: string.isRequired
};
