import React from "react";
import { render } from "@testing-library/react";
import ViewDetails from "./";

describe("ViewDetails", () => {
  const prescription = {
    clinic: {
      clinicLocation: {
        facilityInternalWithExternalId: "Windsor HQ",
        id: "354",
        links: undefined,
        meta: undefined,
      },
      clinicOn: "08-28-2020",
      id: "237",
      links: undefined,
      meta: undefined,
    },
    createdAt: "08-28-2020",
    deliveryType: "PRINT",
    displayRefills: 0,
    doses: 0,
    id: "1",
    instructions: "Take da meds",
    links: undefined,
    meta: {
      currentUser: {},
      recordCount: 35,
      page: "1",
    },
    ownerFirstName: "Deon",
    ownerLastName: "Gerlach",
    petName: "Elnora",
    prescribedOn: "08-28-2020",
    productName: "Blah",
    status: "Printed In Clinic",
    statusObject: {
      alertClass: "info",
      labelClass: "success",
      rowClass: "inactive",
    },
    updatedAt: "09-03-2020 12:12am PDT",
    vaccertNumber: "abcd",
    vetFirstName: "System Account",
    vetLastName: "VIP Pet Records",
  };
  const closeModal = jest.fn();

  const defaultProps = {
    prescription,
    closeModal,
  };

  const renderComponent = (props) =>
    render(<ViewDetails {...defaultProps} {...props} />);

  test("shows the details", () => {
    const { getByText } = renderComponent();

    expect(getByText(`Ad-Hoc Prescription for ${prescription.petName} (${prescription.ownerFirstName} ${prescription.ownerLastName})`)).toBeTruthy();
  });
});
