import React from "react";
import { func, string } from "prop-types";

export const Textbox = ({ onChange, type, value, ...rest }) => (
  <input
    onChange={(e) => onChange(e.target.value)}
    type={type}
    value={value}
    {...rest}
  />
);

Textbox.defaultProps = {
  type: "text",
  value: "",
};

Textbox.propTypes = {
  onChange: func.isRequired,
  type: string,
  value: string,
};
