import React from "react";
import * as api from "@/shared/useGetApi";
import { render } from "@testing-library/react";
import { UnpaidSales } from ".";

const defaultProps = {
  ownerId: 1,
  setSalesTotal: () => {},
  setSaleUuids: () => {},
};

const apiResponseExample = [
  {
    saleId: 5591317,
    saleUuids: ["884480e1-e2oa-4033-9956-ccc84c66ff9a"],
    clinicId: "889580e1-e2ea-4062-9956-eee84c66ff9a",
    clinicDate: 1627801200,
    clinicLocation: "Pet Food Express (SJC) 1018, San Jose, CA",
    vaccert: "",
    amountDue: 120,
    include: false,
  },
];

describe("UnpaidSales", () => {
  const renderComponent = () =>
    render(
      <UnpaidSales
        ownerId={defaultProps.ownerid}
        setSalesTotal={defaultProps.setSalesTotal}
        setSaleUuids={defaultProps.setSaleUuids}
      />
    );

  test("Renders <UnpaidSales />", () => {
    expect(renderComponent).toBeTruthy();
  });

  describe("When Loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("Shows Loading Message", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("Fetch API", () => {
    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [apiResponseExample, false]);
    });

    test("Renders with API data", () => {
      const { getByText } = renderComponent();

      expect(getByText("Sale Id")).toBeTruthy();
    });
  });
});
