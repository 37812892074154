import React, { useEffect, useState } from "react";
import cx from "classnames";
import { array, number } from "prop-types";
import { pluck, pipe, replace, sum } from "ramda";
import { capitalizeAll } from "ramda-extension";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { useTabTitle } from "../../../shared/useTabTitle";
import { toDisplayDate } from "../../../utils/dateFormatter";
import { toMoney } from "../../../utils/numberFormatter";

const snakeCaseToSpaceCase = pipe(replace(/_/g, " "), capitalizeAll);

const Collections = ({ ownerId, tabState }) => {
  const [tabClass, setTabClass] = useState(null);
  const [data, loading] = useGetApi({
    get: api.owners.collections,
    id: ownerId,
  });

  const total = pipe(pluck("balanceDue"), sum);

  useEffect(() => {
    if (!loading) setTabClass(total(data) > 0 ? "error" : null);
  }, [data, loading]);

  useTabTitle("Collections", tabClass, tabState);

  useTabCount({ collection: data }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Table id={"viewcollections"}>
        <tbody>
          <RenderIf isTrue={data.length === 0}>
            <tr>
              <td>
                <p>No collections for this owner.</p>
              </td>
            </tr>
          </RenderIf>
          <RenderIf isTrue={data.length > 0}>
            <tr>
              <th className="aligncenter">Collection #</th>
              <th className="aligncenter">Status</th>
              <th className="aligncenter">Creation Date</th>
              <th className="aligncenter">Total Due</th>
              <th className="aligncenter">Paid</th>
              <th className="aligncenter">Balance Due</th>
            </tr>
            {data.map((collection, index) => (
              <tr key={`collection-${index}`}>
                <td className="aligncenter">
                  <a href={collection.links.show}>{collection.id}</a>
                </td>
                <td className="aligncenter">
                  {snakeCaseToSpaceCase(collection.status)}
                </td>
                <td className="aligncenter">
                  {toDisplayDate(collection.createdAt)}
                </td>
                <td className="aligncenter">{toMoney(collection.totalDue)}</td>
                <td className="aligncenter">
                  {toMoney(collection.totalPayments)}
                </td>
                <td
                  className={cx("aligncenter", {
                    error: collection.balanceDue < 0,
                  })}
                >
                  {toMoney(collection.balanceDue)}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="aligncenter">
                <strong>Total:</strong>
              </td>
              <td className="aligncenter">
                <strong>{toMoney(total(data))}</strong>
              </td>
            </tr>
          </RenderIf>
        </tbody>
      </Table>
    </div>
  );
};

Collections.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default Collections;
