import { get, makeUrl, post, put } from "../";
import { normalizeArray } from "../../api/utils/transforms";

const rabiesLotBaseUrl = "/rabies_lots";
const rigCodeBaseUrl = "/rig_codes";

export const api = {
  officeRabiesLots: {
    get: (rigCodeId, params) =>
      get(
        makeUrl([rigCodeBaseUrl, "/", rigCodeId, "/office_rabies_lots"]),
        {
          params: params,
          transformResponse: normalizeArray
        }
      )
  },
  rabiesLot: {
    create: (body) =>
      post(
        rabiesLotBaseUrl,
        { rabies_lot: body },
        {}
      ),
    products: () =>
      get(
        makeUrl([rabiesLotBaseUrl, "/products"]),
        { transformResponse: normalizeArray }
    ),
    update: (rabiesLotId, body) =>
      put(
        makeUrl([rabiesLotBaseUrl, "/", rabiesLotId]),
        { rabies_lot: body },
        {}
      ),
    rigCodes: () =>
      get(
        makeUrl([rabiesLotBaseUrl, "/rig_codes"]),
        { transformResponse: normalizeArray }
      )
  },
};
