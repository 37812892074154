/* eslint-disable react/prop-types */
import React from "react";
import { func, number } from "prop-types";
import styled from "styled-components";
import { api } from "@/api";
import { Loader } from "@/shared/Loader";
import { useGetApi } from "@/shared/useGetApi";

const Wrapper = styled.div`
  display: flex;

  div {
    padding-left: 10px;
  }
`;

export const BillingAddressBook = ({ handleChange, ownerId }) => {
  const [data, loading] = useGetApi({
    get: api.owners.get,
    id: ownerId,
  });

  if (loading) {
    return <Loader />;
  }

  const { address, city, state, zip } = data.data[0].attributes;
  const fullAddress = { address, city, state, zip };

  return (
    <div>
      <h4>Billing Address Book</h4>
      <Wrapper>
        <input type="radio" onClick={() => handleChange(fullAddress)} />
        <div>
          {`  ${address} `}
          <br />
          {`${city}, ${state} ${zip}`}
        </div>
      </Wrapper>
    </div>
  );
};

BillingAddressBook.proptypes = {
  handleChange: func.isRequired,
  ownerId: number.isRequired
};
