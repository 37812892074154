import React, { useEffect, useState } from 'react';

export const useRecordCount = (data) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const [firstRecord] = data;
      if (typeof firstRecord?.meta?.recordCount === 'undefined') {
        throw new Error("Missing recordCount on meta data attribute");
      }
      const recordCount = data.length === 0 ? 0 : firstRecord.meta.recordCount;
      setCount(recordCount);
    }
    else if (data && data.length == 0) {
      setCount(0)
    }
  }, [data]);

  return count;
}
