import React from "react";
import * as api from "@/shared/useGetApi";
import { render } from "@testing-library/react";
import CollectionStatus from ".";

const defaultProps = {
  ownerId: 1,
  saleUuid: "884480e1-e2oa-4033-9956-ccc84c66ff9a",
};

const apiResponseExample = [
  {
    saleId: 5591317,
    uniqueId: ["884480e1-e2oa-4033-9956-ccc84c66ff9a"],
    clinicId: "889580e1-e2ea-4062-9956-eee84c66ff9a",
    clinicDate: 1627801200,
    clinicLocation: "Pet Food Express (SJC) 1018, San Jose, CA",
    vaccert: "",
    amountDue: 120,
    include: false,
  },
];

describe("CollectionStatus", () => {
  const renderComponent = () =>
    render(
      <CollectionStatus
        ownerId={defaultProps.ownerId}
        saleUuid={defaultProps.saleUuid}
      />
    );

  test("Renders <CollectionStatus />", () => {
    expect(renderComponent).toBeTruthy();
  });

  describe("When Loading", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
    });

    test("Renders Loading", () => {
      const { getByText } = renderComponent();

      expect(getByText("Loading...")).toBeTruthy();
    });
  });

  describe("When Unpaid Sales", () => {
    beforeEach(() => {
      jest
        .spyOn(api, "useGetApi")
        .mockImplementation(() => [apiResponseExample, false]);
    });

    test("Renders Unpaid Tag", () => {
      const { getByText } = renderComponent();

      expect(getByText("Unpaid")).toBeTruthy();
    });
  });

  describe("When No Unpaid Sales", () => {
    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[], false]);
    });

    test("Does Not Render Unpaid tag", () => {
      const { queryByText } = renderComponent();

      expect(queryByText("Unpaid")).toBeNull();
    });
  });
});
