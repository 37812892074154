import React from "react";
import { func, string } from "prop-types";
import { curryN } from "ramda";
import { Textbox } from "../Textbox";

export const DateTextbox = ({ field, onChange, ...rest }) => {

  const onDateChange = curryN(2, onChange)(field)

  return (
    <Textbox
      type="date"
      onChange={onDateChange}
      {...rest}
    />
  )
};

DateTextbox.defaultProps = {
  value: ""
};

DateTextbox.propTypes = {
  field: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  value: string,
};
