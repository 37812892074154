import React from "react";
import cx from "classnames";
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { RenderIf } from "../RenderIf";

export const Select = ({
  className,
  defaultLabel,
  disabled,
  id,
  hasDefaultOption,
  name,
  onChange,
  selectedValue,
  options,
}) => (
  <select
    className={cx("select", className)}
    disabled={disabled}
    id={id}
    name={name}
    value={selectedValue}
    onChange={onChange}
  >
    <RenderIf isTrue={hasDefaultOption}>
      <option value={""}>{defaultLabel}</option>
    </RenderIf>
    {options.map(({ label, value }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ))}
  </select>
);

Select.defaultProps = {
  className: undefined,
  defaultLabel: "Select...",
  disabled: false,
  id: undefined,
  hasDefaultOption: true,
  name: undefined,
  selectedValue: "",
};

Select.propTypes = {
  className: string,
  defaultLabel: string,
  disabled: bool,
  id: string,
  hasDefaultOption: bool,
  name: string,
  onChange: func.isRequired,
  selectedValue: oneOfType([string, number]),
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    })
  ).isRequired,
};
