import React from "react";
import { render, screen } from "@testing-library/react";
import * as api from "../../../shared/useGetApi";
import { ClinicLocationHours } from "../types";
import Hours from "./";

describe("Hours", () => {
  const clinicLocationId = 1;

  const defaultProps = {
    clinicLocationId,
  };

  beforeEach(() => {
    jest.spyOn(api, "useGetApi").mockImplementation(() => [null, true]);
  });

  const renderComponent = (props) =>
    render(<Hours {...defaultProps} {...props} />);

  test("shows loading message", () => {
    renderComponent();

    expect(screen.getByText("Loading...")).toBeTruthy();
  });

  describe("when not loading", () => {
    const dayName = "Monday";
    const closeHour = 16;
    const closeMinute = 0;
    const openHour = 8;
    const openMinute = 0;
    const example = ClinicLocationHours.example({
      dayName,
      closeMinute,
      closeHour,
      openMinute,
      openHour,
    });

    beforeEach(() => {
      jest.spyOn(api, "useGetApi").mockImplementation(() => [[example], false]);
    });

    test("shows the day name", () => {
      renderComponent();

      expect(screen.getByText(dayName)).toBeTruthy();
    });

    test("shows the hours", () => {
      renderComponent();

      expect(screen.getByText("8:00 AM")).toBeTruthy();
      expect(screen.getByText("4:00 PM")).toBeTruthy();
    });

    test("shows the actions", () => {
      renderComponent();

      expect(screen.getByText("Edit")).toBeTruthy();
      expect(screen.getByText("Delete")).toBeTruthy();
    });
  });
});
