import { toDisplayDate } from "./dateFormatter";

describe("toDisplayDate", () => {
  test("formats date as expected", () => {
    expect(toDisplayDate("2020-08-20")).toBe("8-20-2020");
  });

  test("format epoch timestamp (in seconds) as expected", () => {
    expect(toDisplayDate(1643218370)).toBe("1-26-2022");
  });
});
