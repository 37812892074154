import React from "react";
import { render } from "@testing-library/react";
import { Table } from "./";

describe("Table", () => {
  const defaultProps = {};

  const renderComponent = (props) =>
    render(<Table {...defaultProps} {...props} />);

  const containsClass = (className, element) =>
    element.className.split(" ").includes(className);

  test("isBordered", () => {
    const { container } = renderComponent({
      isBordered: true,
    });

    expect(containsClass("table-bordered", container.firstChild)).toBe(true);
  });

  test("isCondensed", () => {
    const { container } = renderComponent({
      isCondensed: true,
    });

    expect(containsClass("table-condensed", container.firstChild)).toBe(true);
  });

  test("isNested", () => {
    const { container } = renderComponent({
      isNested: true,
    });

    expect(containsClass("nested", container.firstChild)).toBe(true);
  });

  test("isStriped", () => {
    const { container } = renderComponent({
      isStriped: true,
    });

    expect(containsClass("table-striped", container.firstChild)).toBe(true);
  });
});
