import { get, makeUrl, post, put, remove } from "../";
import { normalizeArray } from "../../api/utils/transforms";

const baseUrl = "/clinic_locations";

export const api = {
  clinicLocation: {
    hours: {
      get: (clinicLocationId) =>
        get(
          makeUrl([baseUrl, "/", clinicLocationId, "/clinic_location_hours"]),
          {
            transformResponse: normalizeArray,
          }
        ),
      create: (clinicLocationId, body, params) =>
        post(
          makeUrl([baseUrl, "/", clinicLocationId, "/clinic_location_hours"]),
          { clinic_location_hour: body },
          {
            params,
          }
        ),
      update: (clinicLocationId, clinicLocationHourId, body, params) =>
        put(
          makeUrl([
            baseUrl,
            "/",
            clinicLocationId,
            "/clinic_location_hours/",
            clinicLocationHourId,
          ]),
          { clinic_location_hour: body },
          {
            params,
          }
        ),
      delete: (clinicLocationId, id) =>
        remove(
          makeUrl([
            baseUrl,
            "/",
            clinicLocationId,
            "/clinic_location_hours",
            "/",
            id,
          ])
        ),
    },
  },
};
