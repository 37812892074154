// eslint-disable-next-line no-unused-vars
import React from "react";
import cx from "classnames";
import styled from "styled-components";
import { useAlert } from "@/contexts/alertContext";

const Wrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  button {
    outline: none;
    border: none;
    background: transparent;
  }
`;

export const AlertContexts = {
  DEFAULT: "alert-warning",
  SUCCESS: "alert-success",
  DANGER: "alert-danger",
  INFO: "alert-info",
};

export const Alert = () => {
  const {alert, setAlert} = useAlert();

  if (!alert) return null;

  return (
    <Wrapper className={cx("alert", alert?.context)}>
      {alert?.children}
      <button onClick={() => setAlert(null)}>X</button>
    </Wrapper>
  );
};
