import React from "react";
import styled from "styled-components";
import { string } from "prop-types";

const ErrorWrapper = styled.div`
  color: red;
`;

export const ErrorMessage = ({ error }) => (
  <ErrorWrapper className="control-label-error fontbold">
    <span>{error}</span>
  </ErrorWrapper>
);

ErrorMessage.defaultProps = {
  error: "Something went wrong",
};

ErrorMessage.propTypes = {
  error: string,
};
