// eslint-disable-next-line no-unused-vars
import React from "react";
import cx from "classnames";
import { bool, node, string } from "prop-types";

export const Flex = ({ children, className, isRow, isWrap }) => (
  <div
    className={cx(className, "d-flex", "align-items-center", {
      "flex-row": isRow,
      "flex-column": !isRow,
      "flex-wrap": isWrap,
      "flex-nowrap": !isWrap,
    })}
  >
    {children}
  </div>
);

Flex.defaultProps = {
  className: "",
  isRow: true,
  isWrap: false,
};

Flex.propTypes = {
  className: string,
  children: node.isRequired,
  isRow: bool,
  isWrap: bool,
};
