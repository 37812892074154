import React from "react";
import { func, object, string } from "prop-types";
import { ErrorMessage } from "@/shared/ErrorMessage";
import { Select } from "@/shared/Select";
import { STATES } from "@/utils/states";

export const BillingAddress = ({
  billingAddress,
  billingAddress2,
  billingCity,
  billingCountry,
  billingState,
  billingZip,
  errors,
  handleBlur,
  handleChange,
  touched,
}) => {
  return (
    <div id="credit-card-container">
      <hr className="divider" />
      <h2 className="credit_card_component">
        Billing Address
        <br />
        <small>(enter exactly as it appears on payment card statement)</small>
      </h2>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingAddress">
          Address 1
        </label>

        <div className="controls">
          <input
            id="billingAddress"
            name="billingAddress"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={billingAddress}
          />
          {errors.billingAddress && touched.billingAddress ? (
            <ErrorMessage error={errors.billingAddress} />
          ) : null}
        </div>
      </div>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingAddress2">
          Address 2
        </label>
        <div className="controls">
          <input
            id="billingAddress2"
            name="billingAddress2"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={billingAddress2}
          />
        </div>
      </div>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingCity">
          City
        </label>

        <div className="controls">
          <input
            id="billingCity"
            name="billingCity"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={billingCity}
          />
          {errors.billingCity && touched.billingCity ? (
            <ErrorMessage error={errors.billingCity} />
          ) : null}
        </div>
      </div>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingState">
          State
        </label>

        <div className="controls">
          <Select
            className="input-medium"
            defaultLabel="Select State"
            id="billingState"
            name="billingState"
            onBlur={handleBlur}
            onChange={handleChange}
            options={STATES}
            selectedValue={billingState}
          />
          {errors.billingState && touched.billingState ? (
            <ErrorMessage error={errors.billingState} />
          ) : null}
        </div>
      </div>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingZip">
          Zip
        </label>

        <div className="controls">
          <input
            className="input-small"
            id="billingZip"
            name="billingZip"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={billingZip}
          />
          {errors.billingZip && touched.billingZip ? (
            <ErrorMessage error={errors.billingZip} />
          ) : null}
        </div>
      </div>
      <div className="credit_card_component control-group">
        <label className="control-label fontbold" htmlFor="billingCountry">
          Country
        </label>

        <div className="controls">
          <select
            className="sel1col"
            id="billingCountry"
            name="billingCountry"
            onBlur={handleBlur}
            onChange={handleChange}
            value={billingCountry}
          >
            <option value="US">United States</option>
          </select>
          {errors.billingCountry && touched.billingCountry ? (
            <ErrorMessage error={errors.billingCountry} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

BillingAddress.defaultProps = {
  billingAddress: "",
  billingAddress2: "",
  billingCity: "",
  billingCountry: "",
  billingState: "",
  billingZip: "",
};

BillingAddress.propTypes = {
  billingAddress: string,
  billingAddress2: string,
  billingCity: string,
  billingCountry: string,
  billingState: string,
  billingZip: string,
  errors: object.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  touched: object.isRequired,
};
