import React from "react";
import { render } from "@testing-library/react";
import { Select } from "./";

describe("Select", () => {
  const defaultProps = {
    onChange: jest.fn().mockName("onChange"),
    options: [
      { label: "ITEM 1", value: "ITEM 1" },
      { label: "ITEM 2", value: "ITEM 2" },
    ],
  };

  const renderComponent = (props) =>
    render(<Select {...defaultProps} {...props} />);

  test("it renders the options", async () => {
    const { getByText } = renderComponent();

    expect(await getByText("ITEM 1")).toBeTruthy();
    expect(await getByText("ITEM 2")).toBeTruthy();
  });

  test("it renders the selected option", async () => {
    const { getByText } = renderComponent({
      selectedValue: "ITEM 2",
    });

    const selectedItem = await getByText("ITEM 2");

    expect(selectedItem.selected).toBe(true);
  });

  test("it shows the default label", async () => {
    const { getByText } = renderComponent({
      defaultLabel: "DEFAULT VALUE",
    });

    const selectedItem = await getByText("DEFAULT VALUE");

    expect(selectedItem.selected).toBe(true);
  });
});
