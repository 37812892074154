import { get, makeUrl, post, put } from "../";
import {
  normalizeArray,
  normalizeProps,
  snakeKeys,
} from "../../api/utils/transforms";

const ownerBaseUrl = "/owners";
const ownerNotificationBaseUrl = "/owner_notifications";

export const api = {
  owners: {
    attach: (ownerId, body, params) =>
      put(makeUrl([ownerBaseUrl, "/", ownerId, "/attach"]), body, {
        params,
      }),
    callbacks: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/callbacks"]), {
        params: params,
        transformResponse: normalizeArray,
      }),
    cancelNotification: (notificationId, body, params) =>
      post(
        makeUrl([
          ownerNotificationBaseUrl,
          "/",
          notificationId,
          "/cancel_notification",
        ]),
        body,
        {
          params,
        }
      ),
    collections: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/collections"]), {
        params,
        transformResponse: normalizeArray,
      }),

    adHocPrescriptions: (ownerId) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/ad_hoc_prescriptions"]), {
        transformResponse: normalizeArray,
      }),

    prescriptions: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/prescriptions"]), {
        params: params,
        transformResponse: normalizeArray,
      }),

    currentPendingTreatments: (ownerId) =>
      get(
        makeUrl([ownerBaseUrl, "/", ownerId, "/current_pending_treatments"]),
        {
          transformResponse: normalizeArray,
        }
      ),

    history: (ownerId) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/detached_pets"]), {
        transformResponse: normalizeArray,
      }),
    notes: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/notes"]), {
        params,
        transformResponse: normalizeArray,
      }),
    notifications: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/notifications"]), {
        params,
        transformResponse: normalizeArray,
      }),
    orders: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/orders"]), {
        params,
        transformResponse: normalizeArray,
      }),
    permissions: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/permissions"]), {
        params,
        transformResponse: normalizeProps,
      }),
    profileTestResults: (ownerId) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/profile_test_results"]), {
        transformResponse: normalizeArray,
      }),
    reactions: (ownerId) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/reactions"]), {
        transformResponse: normalizeArray,
      }),
    sales: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/sales"]), {
        params,
        transformResponse: normalizeArray,
      }),
    visits: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/visits"]), {
        params,
        transformResponse: normalizeArray,
      }),
    get: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId]), {
        params,
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
    associatedPets: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/owners_pets"]), {
        params,
      }),
    unpaidSales: (ownerId) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/unpaid_sales_payment.json"]), {
        transformResponse: normalizeArray,
      }),
    payment: (ownerId, params) =>
      post(makeUrl([ownerBaseUrl, "/", ownerId, "/payment"]), {
        transformResponse: normalizeArray,
        payment: { ...snakeKeys(params) },
      }),
    internalNotes: (ownerId, params) =>
      get(makeUrl([ownerBaseUrl, "/", ownerId, "/internal_notes"]), {
        params,
        transformResponse: normalizeArray
      })
  },
};
