import axios from "axios";
import { api } from "./";

jest.mock("axios", () => ({
  defaults: {
    headers: {
      common: {},
    },
  },
  get: jest.fn().mockName("get").mockResolvedValue({
    data: [],
  }),
  delete: jest.fn().mockName("delete").mockResolvedValue({}),
  post: jest.fn().mockName("post").mockResolvedValue({
    data: {},
  }),
  put: jest.fn().mockName("put").mockResolvedValue({
    data: {},
  }),
}));

describe("Clinic Location API", () => {
  const clinicLocationId = "CLINIC_LOCATION_ID";

  describe("hours", () => {
    test("it can get data", async () => {
      await api.clinicLocation.hours.get(clinicLocationId, {});

      expect(axios.get).toBeCalledWith(
        `/clinic_locations/${clinicLocationId}/clinic_location_hours`,
        expect.any(Object)
      );
    });

    test("it can post data", async () => {
      await api.clinicLocation.hours.create(
        clinicLocationId,
        {
          test: "value",
        },
        {}
      );

      expect(axios.post).toBeCalledWith(
        `/clinic_locations/${clinicLocationId}/clinic_location_hours`,
        { clinic_location_hour: { test: "value" } },
        expect.any(Object)
      );
    });

    test("it can put data", async () => {
      const clinicLocationHourId = "ID";

      await api.clinicLocation.hours.update(
        clinicLocationId,
        clinicLocationHourId,
        {
          test: "value",
        },
        {}
      );

      expect(axios.put).toBeCalledWith(
        `/clinic_locations/${clinicLocationId}/clinic_location_hours/${clinicLocationHourId}`,
        {
          clinic_location_hour: {
            test: "value",
          },
        },
        expect.any(Object)
      );
    });

    test("it can delete data", async () => {
      const id = "ID";

      await api.clinicLocation.hours.delete(clinicLocationId, id);

      expect(axios.delete).toBeCalledWith(
        `/clinic_locations/${clinicLocationId}/clinic_location_hours/${id}`
      );
    });
  });
});
