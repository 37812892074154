import React from "react";
import Select from "react-select";
import { defaultStyles } from "./styles.js";
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";

export const SearchSelect = ({
  className,
  disabled,
  name,
  onChange,
  options,
  value,
  ...props
}) => {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };
  return (
    <Select
      className={className}
      disabled={disabled}
      name={name}
      value={defaultValue(options, value)}
      onChange={(option) => {
        onChange(option);
      }}
      options={options}
      styles={defaultStyles}
      {...props}
    />
  );
};

SearchSelect.defaultProps = {
  className: undefined,
  disabled: false,
  name: undefined,
  value: "",
};

SearchSelect.propTypes = {
  className: string,
  disabled: bool,
  name: string,
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    })
  ).isRequired,
};
