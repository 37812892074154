import { api } from "../../../../../api";
import { bool, func, object } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonContexts } from "../../../../../shared/Button";
import Modal from "../../../../../shared/Modal";
import { RenderIf } from "../../../../../shared/RenderIf";
import { Textbox } from "../../../../../shared/Textbox";
import { usePostApi } from "../../../../../shared/usePostApi";

const EnterFulfillment = ({ prescription, closeModal, setExecuteReload, executeReload }) => {
  const [fulfillmentNote, setFulfillmentNote] = useState("");
  const [executePost, setExecutePost] = useState(false);
  const canEnterFulfillment = Boolean(
    prescription.meta.permissions.canFulfill &&
      prescription.fulfilledAt == null &&
      (prescription.currentPrescription || prescription.printed)
  );

  const [fulfillmentResponse, loading] = usePostApi({
    post: api.prescriptions.fulfill,
    id: prescription.id,
    body: {
      prescription: {
        fulfillment_note: fulfillmentNote,
      }
    },
    shouldExecute: executePost,
  });

  useEffect(() => {
    if (executePost) {
      setExecutePost(false);
    }
  }, [executePost]);

  useEffect(() => {
    if (!loading && fulfillmentResponse) {
      window.flash(fulfillmentResponse.message, fulfillmentResponse.type);
      setFulfillmentNote("");
      closeModal();
      setExecuteReload(!executeReload);
    }
  }, [fulfillmentResponse, loading]);

  return (
    <Fragment>
      <Modal.Header closeModal={closeModal}>
        <h3>Enter Prescription Fulfillment Information</h3>
      </Modal.Header>
      <Modal.Body>
        <RenderIf isTrue={canEnterFulfillment}>
          <form className="form-horizontal">
            <strong className="control-label">Fulfillment note&nbsp;</strong>
            <Textbox
              onChange={setFulfillmentNote}
              value={fulfillmentNote}
            />
          </form>
        </RenderIf>
      </Modal.Body>

      <Modal.Footer closeModal={closeModal}>
        <Button
          context={ButtonContexts.PRIMARY}
          onClick={() => setExecutePost(true)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Fragment>
  );
};

EnterFulfillment.propTypes = {
  prescription: object.isRequired,
  closeModal: func.isRequired,
  setExecuteReload: func.isRequired,
  executeReload: bool.isRequired,
};

export default EnterFulfillment;
