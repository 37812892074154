import React from "react";
import { array, number } from "prop-types";
import { byPetName } from "./utils";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { Table } from "../../../shared/Table";

const Reactions = ({ ownerId, tabState }) => {
  const [reactions, loading] = useGetApi({
    get: api.owners.reactions,
    id: ownerId,
  });

  useTabCount({ collection: reactions }, tabState);

  if (loading) {
    return <Loader />;
  }

  const groupedReactions = byPetName(reactions);

  return (
    <Table id="viewreactions" isBordered={false} isStriped={false}>
      <thead>
        <tr>
          <th className="span2">Pet Name</th>
          <th>Reactions</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(groupedReactions).map((reactionGroup, index) => (
          <tr key={`pet-${index}`}>
            <td>
              <a href={reactionGroup[1][0].pet.links.show}>
                {reactionGroup[0]}
              </a>
            </td>
            <td>
              <Table isStriped={false}>
                <tbody>
                  {reactionGroup[1].map((reaction, index) => (
                    <tr key={`reaction-${index}`}>
                      <td className="span2">
                        <a href={reaction.links.show}>
                          Reaction: {reaction.id}
                        </a>
                      </td>
                      <td className="span2">{reaction.status}</td>
                      <td>{reaction.date}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

Reactions.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default Reactions;
