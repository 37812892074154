import React, { useState } from "react";
import { array, number } from "prop-types";
import { api } from "../../../api";
import { Loader } from "../../../shared/Loader";
import { Pagination } from "../../../shared/Pagination";
import { RenderIf } from "../../../shared/RenderIf";
import { Table } from "../../../shared/Table";
import { useGetApi } from "../../../shared/useGetApi";
import { useTabCount } from "../../../shared/useTabCount";
import { titleize } from "../../../utils/string";
import { join, map, pipe } from "ramda";
import { useRecordCount } from "../../../shared/useRecordCount";

const getEmptyMessage = () => "No orders for this owner.";

const getStatus = pipe(
  map(titleize),
  join(" / ")
);

const Orders = ({ ownerId, tabState }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [orders, loading] = useGetApi({
    get: api.owners.orders,
    id: ownerId,
    params: {
      order: pageNumber,
    },
  });
  
  const ordersCount = useRecordCount(orders);
  useTabCount({ count: ordersCount }, tabState);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Pagination
        startPage={pageNumber}
        label={"Orders"}
        onPageChange={setPageNumber} 
        recordCount={ordersCount}
      />
      <Table id={"vieworders"}>
        <tbody>
          <RenderIf isTrue={ordersCount == 0}>
            <tr>
              <td>
                <p>{getEmptyMessage()}</p>
              </td>
            </tr>
          </RenderIf>
          <tr>
            <th>Order #</th>
            <th>Type</th>
            <th>Status</th>
            <th>Total</th>
            <th>Updated At</th>
          </tr>

          {orders.map((order, index) => (
            <tr key={`order-${index}`}>
              <td>
                <a href={ order.links.show }>{ order.id }</a>
              </td>
              <td>{ order.orderSource }</td>
              <td>{ getStatus([order.status, order.paymentStatus, order.shippingStatus]) }</td>
              <td>{ order.totalCost }</td>
              <td>{ order.updatedAt }</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

Orders.propTypes = {
  ownerId: number.isRequired,
  tabState: array.isRequired,
};

export default Orders;